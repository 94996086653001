import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Select, InputNumber, Button, Checkbox } from "antd";
import { QuestionOutlined, LoadingOutlined } from "@ant-design/icons";
import oddsCoverter from "odds-converter";
import { useTheme } from "../../../hooks";
import { setCurrentlyVisible } from "../../../state/ducks/modals";
import { fetchMarketTypes } from "../../../state/ducks/marketTypes";
import LowHoldPlay from "./components/LowHoldPlay.component";
import bookSelectOptions from "../../components/BookSelectOptions/BookSelectOptions.component";
import styles from "./LowHolds.module.less";
import { getLowHoldPlays, getEvents } from "../../../api";
import config from "../../../config";
import getToken from "../../../utils/getToken";

const { Option } = Select;

const SPORTS = config.sports;
const SHOW_EVENT_SELECT = false;
const SHOW_MAIN_ONLY_FILTER = false;

const calculateStakesAndProfit = ({ lowHoldPlay, stake, book }) => {
  const [outcome0, outcome1] = lowHoldPlay.outcomes;
  let profitDollars, bet0, bet1;
  if (stake && !book) {
    const dogOutcome = outcome0.odds <= outcome1.odds ? outcome1 : outcome0;
    const favOutcome = outcome0.odds < outcome1.odds ? outcome0 : outcome1;
    const dogArbPercent = (1 / dogOutcome.odds) * 100;
    const favArbPercent = (1 / favOutcome.odds) * 100;
    const totalArbPercent = dogArbPercent + favArbPercent;
    const dogPercentStake =
      lowHoldPlay.profit !== "0.00"
        ? (100 * dogArbPercent) / lowHoldPlay.profit
        : 100 * dogArbPercent;
    const favPercentStake =
      lowHoldPlay.profit !== "0.00"
        ? (100 * favArbPercent) / lowHoldPlay.profit
        : 100 * favArbPercent;
    const dogStake = stake * (dogPercentStake / favPercentStake);
    const isBet1Underdog =
      dogOutcome.side === "Home" ||
      dogOutcome.side === "Under" ||
      dogOutcome.side === "No";
    bet0 = isBet1Underdog ? dogStake : stake;
    bet1 = isBet1Underdog ? stake : dogStake;
    const totalStake = stake + dogStake;
    profitDollars = (totalStake / (totalArbPercent / 100) - totalStake).toFixed(
      2
    );
  } else if (stake && book) {
    let selectedBookOutcome, otherOutcome;
    if (outcome0.book === book) {
      selectedBookOutcome = outcome0;
      otherOutcome = outcome1;
    } else {
      selectedBookOutcome = outcome1;
      otherOutcome = outcome0;
    }
    const selectedBookArbPercent = (1 / selectedBookOutcome.odds) * 100;
    const otherbookArbPercent = (1 / otherOutcome.odds) * 100;
    const totalArbPercent = selectedBookArbPercent + otherbookArbPercent;
    const selectedBookPercentStake =
      lowHoldPlay.profit !== "0.00"
        ? (100 * selectedBookArbPercent) / lowHoldPlay.profit
        : 100 * selectedBookArbPercent;
    const otherBookPercentStake =
      lowHoldPlay.profit !== "0.00"
        ? (100 * otherbookArbPercent) / lowHoldPlay.profit
        : 100 * otherbookArbPercent;
    const otherBookStake =
      stake * (otherBookPercentStake / selectedBookPercentStake);
    const isBet1SelectedBook =
      selectedBookOutcome.side === "Home" ||
      selectedBookOutcome.side === "Under" ||
      selectedBookOutcome.side === "No";
    bet0 = isBet1SelectedBook ? stake : otherBookStake;
    bet1 = isBet1SelectedBook ? otherBookStake : stake;
    const totalStake = stake + otherBookStake;
    profitDollars = (totalStake / (totalArbPercent / 100) - totalStake).toFixed(
      2
    );
  }
  return { profitDollars, outcomeStakes: [bet0, bet1] };
};

const LowHolds = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const themeTokens = useTheme();
  const user = useSelector((state) => state.user.user);
  const marketTypes = useSelector((state) => state.marketTypes);
  const [lowHoldPlaysStatus, setLowHoldPlaysStatus] = useState("idle");
  const [lowHoldPlays, setLowHoldPlays] = useState([]);
  const [book, setBook] = useState();
  const [stake, setStake] = useState(100);
  const [isMoreVisible, setIsMoreVisible] = useState(false);
  const [showJustMiddles, setShowJustMiddles] = useState(false);
  const [mainOnly, setMainOnly] = useState(false);
  const [sportValue, setSport] = useState("all");
  const [leagueValue, setLeague] = useState("all");
  const [marketTypesValue, setMarketTypes] = useState([]);
  const [eventId, setEventId] = useState("all");
  const [events, setEvents] = useState([]);
  const [eventsStatus, setEventsStatus] = useState("idle");
  const [minAmericanOdds, setMinAmericanOdds] = useState();
  const [maxAmericanOdds, setMaxAmericanOdds] = useState();

  const fetchLowHoldPlays = useCallback(async () => {
    setLowHoldPlaysStatus("loading");
    try {
      const token = await getToken({ auth0 });
      const { lowHoldPlays } = await getLowHoldPlays({
        token,
        books: user.books,
        sport: sportValue !== "all" ? sportValue : undefined,
        league: leagueValue !== "all" ? leagueValue : undefined,
        marketTypes: marketTypesValue === [] ? undefined : marketTypesValue,
        lowHoldBook: book,
        minimumOdds: minAmericanOdds
          ? oddsCoverter.american.toDecimal(minAmericanOdds)
          : undefined,
        maximumOdds: maxAmericanOdds
          ? oddsCoverter.american.toDecimal(maxAmericanOdds)
          : undefined,
        mainOnly: mainOnly ? "true" : undefined,
      });
      setLowHoldPlays(lowHoldPlays);
      setLowHoldPlaysStatus("succeeded");
    } catch (err) {
      console.error(err);
      setLowHoldPlaysStatus("failed");
    }
  }, [
    user.books,
    sportValue,
    leagueValue,
    book,
    minAmericanOdds,
    maxAmericanOdds,
    marketTypesValue,
    mainOnly,
    auth0,
  ]);

  useEffect(() => {
    dispatch(fetchMarketTypes());
  }, [dispatch]);

  useEffect(() => {
    const fetchEvents = async () => {
      setEventsStatus("loading");
      try {
        const token = await getToken({ auth0 });
        const fetchedEvents = await getEvents({ token, league: leagueValue });
        setEvents(fetchedEvents);
        setEventsStatus("succeeded");
      } catch (err) {
        console.error(err);
        setEventsStatus("failed");
      }
    };
    if (leagueValue === "all") return;
    fetchEvents();
  }, [leagueValue, auth0, book]);

  lowHoldPlays.forEach((lowHoldPlay) => {
    let { profitDollars, outcomeStakes } = calculateStakesAndProfit({
      lowHoldPlay,
      stake,
      book,
    });
    lowHoldPlay.profitDollars = profitDollars;
    lowHoldPlay.outcomes[0].stake = outcomeStakes[0];
    lowHoldPlay.outcomes[1].stake = outcomeStakes[1];
  });

  let filteredLowHoldPlays = lowHoldPlays;
  filteredLowHoldPlays.sort((a, b) => b.profitDollars - a.profitDollars);
  if (book) {
    filteredLowHoldPlays = lowHoldPlays.filter(
      (lowHoldPlay) =>
        lowHoldPlay.outcomes[0].book === book ||
        lowHoldPlay.outcomes[1].book === book
    );
  }

  if (showJustMiddles) {
    filteredLowHoldPlays = lowHoldPlays.filter((lhp) => lhp.middle);
  }

  let list;
  if (lowHoldPlaysStatus === "succeeded") {
    if (filteredLowHoldPlays.length === 0) {
      list = <div className={styles.nothingMessage}>NOTHING RIGHT NOW</div>;
    } else {
      list = filteredLowHoldPlays
        .slice(0, 100)
        .map((lowHoldPlay, i) => (
          <LowHoldPlay
            lowHoldPlay={lowHoldPlay}
            book={book}
            key={i}
            stake={stake}
          ></LowHoldPlay>
        ));
    }
  } else if (lowHoldPlaysStatus === "loading") {
    list = (
      <div className={styles.nothingMessage}>
        <LoadingOutlined style={{ fontSize: 40 }} />
      </div>
    );
  } else if (lowHoldPlaysStatus === "idle") {
    list = (
      <div className={styles.nothingMessage}>ENTER FILTERS, CLICK SEARCH</div>
    );
  }

  const leagueSelectOptions = [{ value: "all", display: "All" }];
  if (sportValue !== "all") {
    SPORTS[sportValue].leagues.forEach((league) =>
      leagueSelectOptions.push(league)
    );
  }

  const marketTypeSelectOptions = useMemo(() => {
    let marketTypeSelectOptions = [];
    if (sportValue !== "all" && marketTypes.status === "succeeded") {
      let marketOptions = [];
      Object.values(marketTypes.data).forEach((marketType) => {
        if (marketType.sports && marketType.sports.includes(sportValue)) {
          let display = marketType.friendlyName;
          if (display.includes("%PLAYER%"))
            display = display.replace("%PLAYER%", "Player");
          marketOptions.push({
            value: marketType.id,
            display,
          });
        }
      });
      marketOptions = marketOptions.sort((a, z) =>
        a.display.localeCompare(z.display)
      );
      marketTypeSelectOptions = [...marketTypeSelectOptions, ...marketOptions];
    }
    return marketTypeSelectOptions;
  }, [sportValue, marketTypes]);

  const eventSelectOptions = useMemo(() => {
    const eventSelectOptions = [{ value: "all", display: "All" }];
    events.forEach((event) => {
      eventSelectOptions.push({
        value: `${event.id}${event.startTime}`,
        display: `${event.participants[0]} @ ${event.participants[1]}`,
      });
    });
    return eventSelectOptions;
  }, [events]);

  return (
    <div className={styles.container}>
      <div
        className={styles.inputContainer}
        style={{ color: themeTokens.colorText }}
      >
        <div className={styles.sportsBookSelectContainer}>
          <div
            style={{
              fontSize: "0.6rem",
              fontWeight: 600,
              marginBottom: 2,
            }}
          >
            Sportsbook
          </div>
          <Select onChange={setBook} className={styles.sportsBookSelect}>
            {bookSelectOptions(user.books)}
          </Select>
        </div>
        <div>
          <div style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}>
            Stake
          </div>
          <InputNumber
            min={0.5}
            placeholder="Stake"
            formatter={(value) =>
              `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            value={stake}
            onChange={(value) => setStake(value)}
            inputMode="numeric"
            pattern="[0-9]*"
            style={{ width: 80 }}
          />
        </div>
        <div>
          <Button
            onClick={() => setIsMoreVisible(!isMoreVisible)}
            className={styles.moreButton}
          >
            {isMoreVisible ? "Less" : "More"}
          </Button>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            icon={<QuestionOutlined />}
            shape="circle"
            onClick={() => dispatch(setCurrentlyVisible("LowHoldsHelp"))}
          />
        </div>
      </div>
      {isMoreVisible && (
        <div style={{ color: themeTokens.colorText }}>
          <div style={{ marginBottom: "1rem", display: "flex" }}>
            <div className={styles.sportsBookSelectContainer}>
              <div
                style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}
              >
                Sport
              </div>
              <div>
                <Select
                  value={sportValue}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setLeague("all");
                    setSport(value);
                  }}
                >
                  <Option value="all">All</Option>
                  <Option value="basketball">Basketball</Option>
                  <Option value="baseball">Baseball</Option>
                  <Option value="football">Football</Option>
                  <Option value="hockey">Hockey</Option>
                  <Option value="soccer">Soccer</Option>
                </Select>
              </div>
            </div>
            <div className={styles.sportsBookSelectContainer}>
              <div
                style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}
              >
                League
              </div>
              <div>
                <Select
                  value={leagueValue}
                  style={{ width: 120 }}
                  onChange={setLeague}
                  disabled={sportValue === "all"}
                >
                  {leagueSelectOptions.map((leagueSelectOption) => (
                    <Option
                      value={leagueSelectOption.value}
                      key={leagueSelectOption.value}
                    >
                      {leagueSelectOption.display}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "1rem", display: "flex" }}>
            <div className={styles.sportsBookSelectContainer}>
              <div
                style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}
              >
                Market Types
              </div>
              <div>
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  value={marketTypesValue}
                  style={{ width: 200 }}
                  onChange={(v) => setMarketTypes(v)}
                  disabled={sportValue === "all"}
                  placeholder="All"
                  maxTagCount="responsive"
                >
                  {marketTypeSelectOptions.map((marketTypeSelectOption) => (
                    <Option
                      value={marketTypeSelectOption.value}
                      key={marketTypeSelectOption.value}
                    >
                      {marketTypeSelectOption.display}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "1rem", display: "flex" }}>
            <div className={styles.sportsBookSelectContainer}>
              <div
                style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}
              >
                Min Odds
              </div>
              <div>
                <InputNumber
                  placeholder="None"
                  controls={false}
                  size="small"
                  value={minAmericanOdds}
                  onChange={setMinAmericanOdds}
                  disabled={!book}
                />
              </div>
            </div>
            <div className={styles.sportsBookSelectContainer}>
              <div
                style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}
              >
                Max Odds
              </div>
              <div>
                <InputNumber
                  placeholder="None"
                  controls={false}
                  size="small"
                  value={maxAmericanOdds}
                  onChange={setMaxAmericanOdds}
                  disabled={!book}
                />
              </div>
            </div>
          </div>
          {leagueValue !== "all" && SHOW_EVENT_SELECT && (
            <div style={{ marginBottom: "1rem", display: "flex" }}>
              <div
                className={styles.sportsBookSelectContainer}
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    fontSize: "0.6rem",
                    fontWeight: 600,
                    marginBottom: 2,
                  }}
                >
                  Event
                </div>
                <div>
                  <Select
                    value={eventId}
                    style={{ width: "100%" }}
                    onChange={setEventId}
                    disabled={
                      leagueValue === "all" || eventsStatus === "loading"
                    }
                    loading={eventsStatus === "loading"}
                  >
                    {eventSelectOptions.map((eventSelectOption) => (
                      <Option
                        value={eventSelectOption.value}
                        key={eventSelectOption.value}
                      >
                        {eventSelectOption.display}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          )}
          <div className={`${styles.inputContainer} ${styles.smallSportInput}`}>
            <div>
              <div
                style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}
              >
                <span style={{ marginRight: "0.5rem" }}>Just Middles</span>
                <span>
                  <Checkbox
                    checked={showJustMiddles}
                    onChange={() => setShowJustMiddles(!showJustMiddles)}
                  />
                </span>
              </div>
            </div>
            {SHOW_MAIN_ONLY_FILTER && (
              <div>
                <div
                  style={{
                    fontSize: "0.6rem",
                    fontWeight: 600,
                    marginBottom: 2,
                    marginLeft: "1rem",
                  }}
                >
                  <span style={{ marginRight: "0.5rem" }}>Just Main Lines</span>
                  <span>
                    <Checkbox
                      checked={mainOnly}
                      onChange={() => setMainOnly(!mainOnly)}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        <Button
          size="large"
          type="primary"
          onClick={fetchLowHoldPlays}
          loading={lowHoldPlaysStatus === "loading"}
          block
          style={{ maxWidth: 350 }}
        >
          Search
        </Button>
      </div>
      <div>{list}</div>
    </div>
  );
};

export default LowHolds;
