import React from "react";
import { Select } from "antd";
import fanduelLogo from "../../../assets/fanduel.svg";
import draftkingsLogo from "../../../assets/draftkings.svg";
import pointsbetLogo from "../../../assets/pointsbet.svg";
import kambiLogo from "../../../assets/kambi.svg";
import barstoolLogo from "../../../assets/espnbet.svg";
import fanaticsLogo from "../../../assets/fanatics.svg";
import hardrockLogo from "../../../assets/hardrock.svg";
import betfredLogo from "../../../assets/betfred.svg";
import williamhillLogo from "../../../assets/caesars.svg";
import pinnacleLogo from "../../../assets/pinnacle.svg";
import betmgmLogo from "../../../assets/betmgm.svg";
import circaLogo from "../../../assets/circa.svg";
import fliffLogo from "../../../assets/fliff.svg";
const { Option } = Select;

const LARGE_NAMES = {
  kambi: "Kambi",
  fanduel: "FanDuel",
  pointsbet: "PointsBet",
  draftkings: "D. Kings",
  williamhill: "Caesars",
  pinnacle: "Pinnacle",
  betmgm: "MGM",
  barstool: "ESPNBet",
  fanatics: "Fanatics",
  betfred: "Betfred",
  circa: "Circa",
  fliff: "Fliff",
  hardrock: "Hard Rock",
};

const SMALL_NAMES = {
  kambi: "Kambi",
  fanduel: "FD",
  pointsbet: "PB",
  draftkings: "DK",
  williamhill: "CZR",
  pinnacle: "PIN",
  betmgm: "MGM",
  barstool: "ESPN",
  fanatics: "FAN",
  betfred: "BF",
  circa: "Circa",
  fliff: "Fliff",
  hardrock: "HR",
};

const buildOptions = ({ imageWidth, names }) => {
  return {
    barstool: {
      v: "barstool",
      logo: barstoolLogo,
      width: imageWidth,
      name: names.barstool,
    },
    kambi: {
      v: "kambi",
      logo: kambiLogo,
      width: imageWidth,
      name: names.kambi,
    },
    fanduel: {
      v: "fanduel",
      logo: fanduelLogo,
      width: imageWidth,
      name: names.fanduel,
    },
    pointsbet: {
      v: "pointsbet",
      logo: pointsbetLogo,
      width: imageWidth,
      name: names.pointsbet,
    },
    fanatics: {
      v: "fanatics",
      logo: fanaticsLogo,
      width: imageWidth,
      name: names.fanatics,
    },
    draftkings: {
      v: "draftkings",
      logo: draftkingsLogo,
      width: imageWidth,
      name: names.draftkings,
    },
    williamhill: {
      v: "williamhill",
      logo: williamhillLogo,
      width: imageWidth,
      name: names.williamhill,
    },
    betmgm: {
      v: "betmgm",
      logo: betmgmLogo,
      width: imageWidth,
      name: names.betmgm,
    },
    pinnacle: {
      v: "pinnacle",
      logo: pinnacleLogo,
      width: imageWidth,
      name: names.pinnacle,
    },
    betfred: {
      v: "betfred",
      logo: betfredLogo,
      width: imageWidth,
      name: names.betfred,
    },
    circa: {
      v: "circa",
      logo: circaLogo,
      width: imageWidth,
      name: names.circa,
    },
    fliff: {
      v: "fliff",
      logo: fliffLogo,
      width: imageWidth,
      name: names.fliff,
    },
    hardrock: {
      v: "hardrock",
      logo: hardrockLogo,
      width: imageWidth,
      name: names.hardrock,
    },
  };
};

const buildOption = (o) => {
  return (
    <Option value={o.v} key={o.v}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={o.logo}
          alt={`${o.name} logo`}
          width={o.width}
          height={o.width}
          style={{ margin: "0 auto 0 0" }}
        />
        <div style={{ margin: "0 auto 0 5px" }}>{o.name}</div>
      </div>
    </Option>
  );
};

const bookSelectOptions = (books, size = "medium") => {
  const imageWidth = size === "small" ? 16 : 18;
  const names =
    window.innerWidth <= 576 || size === "small" ? SMALL_NAMES : LARGE_NAMES;

  const options = buildOptions({ imageWidth, names });

  if (!books) {
    return (
      <>
        {buildOption(options.kambi)}
        {buildOption(options.barstool)}
        {buildOption(options.fanatics)}
        {buildOption(options.fanduel)}
        {buildOption(options.pointsbet)}
        {buildOption(options.draftkings)}
        {buildOption(options.williamhill)}
        {buildOption(options.betmgm)}
        {buildOption(options.hardrock)}
        {buildOption(options.pinnacle)}
        {buildOption(options.betfred)}
      </>
    );
  }
  return books.map((book) => {
    if (book === "kambi") {
      return buildOption(options.kambi);
    } else if (book === "barstool") {
      return buildOption(options.barstool);
    } else if (book === "fanduel") {
      return buildOption(options.fanduel);
    } else if (book === "fanatics") {
      return buildOption(options.fanatics);
    } else if (book === "williamhill") {
      return buildOption(options.williamhill);
    } else if (book === "draftkings") {
      return buildOption(options.draftkings);
    } else if (book === "pinnacle") {
      return buildOption(options.pinnacle);
    } else if (book === "betmgm") {
      return buildOption(options.betmgm);
    } else if (book === "betfred") {
      return buildOption(options.betfred);
    } else if (book === "circa") {
      return buildOption(options.circa);
    } else if (book === "fliff") {
      return buildOption(options.fliff);
    } else if (book === "hardrock") {
      return buildOption(options.hardrock);
    } else {
      return buildOption(options.pointsbet);
    }
  });
};

export default bookSelectOptions;
