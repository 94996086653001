import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Input, Button, Divider, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../../state/ducks/modals";
import { createLiveAlert } from "../../../api";
import getToken from "../../../utils/getToken";
import Market from "../../components/Market/Market";
import styles from "./LiveAlert.module.less";

const { TextArea } = Input;

const LiveAlert = () => {
  const dispatch = useDispatch();
  const liveAlertData = useSelector((state) => state.liveAlert.data);
  const auth0 = useAuth0();
  const [wrongBook, setWrongBook] = useState();
  const [message, setMessage] = useState();
  const [isSending, setIsSending] = useState(false);

  return (
    <div>
      <div className={styles.marketContainer}>
        <Market {...liveAlertData} live onWhoseWrongChange={setWrongBook} />
      </div>
      <div className={styles.inputContainer}>
        <TextArea
          value={message}
          showCount
          allowClear
          autoSize
          placeholder="Anything more to say?"
          maxLength={140}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <Divider />
      <Button
        type="primary"
        block
        loading={isSending}
        onClick={async () => {
          try {
            const token = await getToken({ auth0 });
            const liveAlertDataCopy = JSON.parse(JSON.stringify(liveAlertData));
            liveAlertDataCopy.outcomes[0].stake = liveAlertData.bet0.toFixed(2);
            liveAlertDataCopy.outcomes[1].stake = liveAlertData.bet1.toFixed(2);
            setIsSending(true);
            await createLiveAlert({
              token,
              data: { ...liveAlertDataCopy, wrongBook, message },
            });
          } catch (err) {
            console.error(err);
            notification.error({
              message: "Issue while sending live alert",
              placement: "topRight",
            });
          } finally {
            setIsSending(false);
            dispatch(close());
          }
        }}
      >
        Send
      </Button>
    </div>
  );
};

export default LiveAlert;
