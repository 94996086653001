import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const TermAndConditions = () => {
  return (
    <div style={{ paddingTop: "1rem" }}>
      <Title level={4}>Terms And Conditions</Title>
      <Title level={5}>1. Acceptance of Subscription</Title>
      <Paragraph>
        All subscriptions are subject to acceptance by a duly authorized
        employee of LONG HORIZONS LLC, a Michigan limited liability company
        ("LH") and are not binding upon LH unless and until acknowledged in
        writing by LH. Any terms and conditions specified on Purchaser's order
        which are in conflict with, inconsistent with, or in addition to the
        terms and conditions specified herein, shall not be binding upon LH
        unless expressly accepted in writing by LH. In the absence of such
        express acceptance, these terms and conditions shall constitute the
        entire agreement between Purchaser and LH.
      </Paragraph>
      <Title level={5}>2. Terms of Payment</Title>
      <Paragraph>
        All payments are due immediately upon presentation before Purchaser is
        permitted to access any of LH's content. Any other terms of payment must
        be approved, in writing, by LH. Purchaser authorizes LH to make
        recurring charges on any payment method provided by Purchaser on the
        renewal of the Term (defined below). Payment shall be in United States'
        funds. In the event of any litigation between Purchaser and LH, if LH is
        the prevailing party then Purchaser shall reimburse LH for all of its
        costs incurred in the litigation, including court costs and actual
        attorney fees.
      </Paragraph>
      <Title level={5}>3. Term</Title>
      <Paragraph>
        The term of this agreement shall start on the date listed on the
        contract as signed or agreed by Purchaser and continue for one (1) month
        from the date thereof. This agreement shall automatically renew
        thereafter for additional terms of one (1) month each unless either
        party gives the other party written notice of termination, via certified
        mail return receipt requested, at least seven (7) days prior to the
        termination of the then existing term. Failure of Purchaser to provide a
        valid payment method upon the renewal of any Term shall result in LH
        immediately having the option to terminate Purchaser's access to all LH
        content without recourse.
      </Paragraph>
      <Title level={5}>4. Status of Purchaser</Title>
      <Paragraph>
        Purchaser represents and warrants that they are eighteen years of age,
        or of the age of majority for the location they reside, they reside in a
        location where researching online gambling services is legal under local
        and national law, and they are not using any services of LH for any
        illegal purposes. Purchaser has the responsibility and liability to
        secure compliance with relevant federal, state, and local laws and
        regulations for the use of any goods and/or services provided by LH.{" "}
        <u>
          The National Council on Problem Gambling operates the National Problem
          Gambling Helpline Network (1-800-522-4700). The network is a single
          national access point to local resources for those seeking help for a
          gambling problem.
        </u>
      </Paragraph>
      <Title level={5}>5. Warranty</Title>
      <Paragraph>
        <b>
          LH DISCLAIMS ALL WARRANTIES IN CONNECTION WITH SERVICES OR TO THE
          EXTENT ANY OFFERING FROM LH IS CONSIDERED A GOOD, THEN GOODS, EXPRESS
          OR IMPLIED, AS TO ANY MATTER WHATSOEVER, INCLUDING BUT WITHOUT
          LIMITATION, DESCRIPTION, QUALITY, DESIGN, PERFORMANCE, SPECIFICATIONS,
          CONDITION, MERCHANTABILITY, AND FITNESS FOR ANY PARTICULAR PURPOSE. LH
          HAS MADE NO AFFIRMATION OF FACT AND HAS MADE NO PROMISE RELATING TO
          THE SERVICES BEING PROVIDED THAT HAS BECOME PART OF THE BASIS OF THIS
          CONTRACT. FURTHER, LH HAS MADE NO AFFIRMATION OF FACT OR PROMISE
          RELATING TO THE SERVICES BEING PROVIDED THAT HAS CREATED OR AMOUNTED
          TO AN EXPRESS WARRANTY THAT THE SERVICES WOULD CONFORM TO ANY
          AFFIRMATION OR PROMISE. ANY DESCRIPTION OF THE SERVICES AND/OR GOODS
          HAS BEEN MADE FOR THE SOLE PURPOSE OF IDENTIFYING THEM. NO DESCRIPTION
          OF THE SERVICES BEING SOLD HAS BEEN MADE PART OF THIS CONTRACT OR HAS
          CREATED OR AMOUNTED TO AN EXPRESS WARRANTY THAT THE SERVICES WOULD
          CONFORM TO ANY DESCRIPTION. LH MAKES NO REPRESENTATIONS AS TO WHETHER
          THE SERVICES BEING SOLD ARE FREE OF RIGHTFUL CLAIM OF ANY THIRD PERSON
          BY WAY OF INFRINGEMENT FROM PATENT, TRADEMARK, OR OTHER PROPERTY
          RIGHTS IN THE SERVICES AND/OR GOODS AND DISCLAIMS ANY WARRANTY AGAINST
          INFRINGEMENT WITH RESPECT TO THE SERVICES. PURCHASER ACKNOWLEDGES THAT
          IT IS NOT RELYING ON LH'S SKILL OR JUDGMENT TO SELECT OR FURNISH
          SERVICES AND/OR GOODS SUITABLE FOR ANY PARTICULAR PURPOSE AND THAT
          THERE ARE NO WARRANTIES CONTAINED IN THIS CONTRACT. THE SERVICES
          AND/OR GOODS COVERED BY THIS CONTRACT HAVE BEEN PURCHASED BY PURCHASER
          "AS IS" AND "WITH ALL FAULTS", AND PURCHASER ACKNOWLEDGES THAT NO
          WARRANTIES OF MERCHANTABILITY OR FITNESS FOR PARTICULAR PURPOSE ARE TO
          BE IMPLIED IN THIS TRANSACTION.
        </b>
      </Paragraph>
      <Title level={5}>6. Assignment</Title>
      <Paragraph>
        Purchaser may not assign any of its rights under this contract without
        the prior written consent of LH. LH only offers its services to
        Purchaser under this contract. Any sharing of services by Purchaser to
        any third party shall result in LH having the immediate option to
        terminate all services. LH may assign its right to receive from
        purchaser the payment(s) required under this contract, at any time, on
        reasonable notification to Purchaser as to the identity of the assignee.
      </Paragraph>
      <Title level={5}>7. Limitation of Damages</Title>
      <Paragraph>
        Under no circumstances shall LH be liable to Purchaser or any
        third-party for any claims based on intellectual property claims of
        services or information supplied hereunder. If LH breaches this
        contract, Purchaser's sole remedy shall be recovery from LH of the
        payment made for services over the prior month. Further, under no
        circumstances shall the total liability of LH and its agents, servants,
        representatives and employees exceed in the aggregate Five Hundred
        Dollars ($500.00). In no event shall LH be liable for indirect, special,
        punitive, exemplary, or consequential damages. Specifically, but not as
        a limitation, LH shall not be liable for any loss or damage sustained by
        Purchaser for any reliance upon any services and/or goods provided by
        LH. Due to the inherent variability of information posted by
        unaffiliated third parties, LH cannot guarantee the current ability to
        effectuate any course of action provided by any services and/or goods of
        LH, and Purchaser shall not rely upon the same as any guaranty or basis
        for loss or damage.
      </Paragraph>
      <Title level={5}>8. Indemnification</Title>
      <Paragraph>
        Purchaser agrees to indemnify and hold LH harmless from and against all
        actions, suits, damages, judgments, costs, claims, charges, expenses,
        attorney fees, and consequence of any liabilities, of any nature, which
        are asserted having any nexus to the goods or work provided.
      </Paragraph>
      <Title level={5}>9. Contractual Statute of Limitations</Title>
      <Paragraph>
        Any claim or suit by Purchaser relating to this contract or the services
        supplied or work provided by LH must be commenced within one (1) year
        after provision of services to Purchaser from which any claim shall
        arise. If the cause of action is deemed to accrue upon discovery of an
        alleged defect or injury, suit must be commenced within thirty (30) days
        of such discovery.
      </Paragraph>
      <Title level={5}>10. Cancellation by Purchaser</Title>
      <Paragraph>
        If Purchaser cancels this contract, it shall not be entitled to any
        refund for amounts paid to date and all paid-in fees shall be forfeited
        to LH.
      </Paragraph>
      <Title level={5}>11. Collection Costs</Title>
      <Paragraph>
        In the event LH incurs any costs in conjunction with enforcing any of
        the terms of this agreement, LH shall be entitled to full reimbursement
        of the costs from Purchaser. Those costs include without limitation LH’s
        actual attorney fees, filing fees and court costs.
      </Paragraph>
      <Title level={5}>12. Taxes</Title>
      <Paragraph>
        In addition to any price specified herein, Purchaser shall pay the gross
        amount of any present or future sales, use, excise, value added, or
        other similar tax applicable to the price, sale or delivery of any
        products or services furnished hereunder or to their use by LH or
        Purchaser, or Purchaser shall furnish LH with a tax-exemption
        certificate acceptable to the taxing authorities.
      </Paragraph>
      <Title level={5}>13. Sales Through Agent</Title>
      <Paragraph>
        Unless specifically otherwise authorized in writing by LH an agent shall
        not have authority to solicit or take orders for the sale of products
        covered by this contract except at LH's established prices and
        discounts, and in accordance with LH’s standard terms and conditions of
        sale as set from time to time in LH's published handbooks, price sheets,
        quotations or printed forms. The Agent shall have no authority to bind
        LH to any contract whatever.
      </Paragraph>
      <Title level={5}>14. Venue</Title>
      <Paragraph>
        Purchaser and LH agree that this contract shall be governed by and
        construed in accordance with the laws of the State of Michigan.
        Jurisdiction and venue for any matter in connection with or arising out
        of this contract shall be in Oakland County in a court of competent
        jurisdiction, or the U.S. District Court for the Eastern District of
        Michigan for federal matters, without regard to any conflict of law
        provisions and notwithstanding that any party is or may later become
        domiciled in a different state or jurisdiction. The parties also agree
        that a final judgment or in any such action shall be conclusive and may
        be enforced in other jurisdictions by suit on the judgment or in any
        other manner provided by law.
      </Paragraph>
      <Title level={5}>15. Notices</Title>
      <Paragraph>
        Any notice that the Purchaser or LH may give or is required to give
        under this contract shall be in writing and, if mailed, be effective
        three (3) days after being sent by certified or registered mail, postage
        prepaid, addressed to the other party at the other party's address set
        forth in this contract or at any other address that the other party
        provides in writing.
      </Paragraph>
      <Title level={5}>16. Severability</Title>
      <Paragraph>
        If any provision in this contract is held to be invalid or
        unenforceable, it shall be ineffective only to the extent of the
        invalidity, without affecting or impairing the validity and
        enforceability of the remainder of the provision of the remaining
        provisions of this contract. Any notice made by commercial overnight
        courier shall be effective upon written verification of receipt.
      </Paragraph>
      <Title level={5}>17. Force Majeure</Title>
      <Paragraph>
        LH’s failure to perform its obligations under this agreement, if caused
        by Force Majeure, shall not constitute a breach. “Force Majeure” means
        any circumstance beyond the reasonable control of LH, including, but not
        limited to, any act of God or a public enemy, accident, explosion, fire,
        storm, earthquake, other natural disaster, strikes, labor trouble,
        equipment shortage, riot or war, local, national, or global pandemic or
        health crisis, or mechanical or technological malfunction. If Purchaser
        believes that LH has not performed in accordance with the agreement
        herein, Purchaser shall bring this to LH’s attention in writing, via
        certified mail, after which LH shall have a reasonable time within which
        to cure and/or respond.
      </Paragraph>
      <Title level={5}>18. Waiver</Title>
      <Paragraph>
        No claim or right arising out of a breach of this contract can be
        discharged, in whole or in part, by a waiver or renunciation of the
        claim or right unless the waiver or renunciation is supported by
        consideration, is in writing, and signed by the aggrieved party.
      </Paragraph>
      <Title level={5}>19. Binding Effect</Title>
      <Paragraph>
        The terms and provisions of this contract are binding on and shall inure
        to the benefit of Purchaser and LH and their respective heirs,
        representatives, successors, and permitted assigns.
      </Paragraph>
      <Title level={5}>20. Integration</Title>
      <Paragraph>
        All understandings, representations, and contracts heretofore made
        between Purchaser and LH are superseded by and merged into this
        contract, which alone fully and completely expresses the contract
        between the parties. This contract is entered into with no party relying
        upon any statement or representation made by any party which is not
        embodied in this contract. Any modification of this contract may be made
        only by an instrument in writing signed by or on behalf of the party to
        be bound by such modification.
      </Paragraph>
      <Title level={5}>21. Authority</Title>
      <Paragraph>
        Purchaser’s signatory represents that he/she has all requisite authority
        to execute the contract on behalf of Purchaser.
      </Paragraph>
      <Title level={5}>22. Modifications and Waiver</Title>
      <Paragraph>
        Any document submitted by Purchaser to LH confirming its intention to
        purchase services described in the contract will be deemed to constitute
        a confirmation and acceptance of the terms and conditions herein, even
        if the document states terms in addition to or different from those in
        this document. All contracts between LH and Purchaser will be solely
        under the terms and conditions herein, and LH objects to any and all
        additional or different terms contained in any document submitted to LH
        by Purchaser. Any execution by LH of any other document submitted by
        Purchaser in connection with the purchase of the services does not
        constitute acceptance of or contract to any terms and conditions in
        addition to or different from the terms and conditions contained herein,
        but will constitute only acknowledgment of receipt of the document. In
        addition, notwithstanding any terms contained in any documents submitted
        by Purchaser in connection with the purchase of the services described
        herein, the acceptance of delivery by Purchaser of the services will
        constitute a course of conduct constituting Purchaser’s contract to the
        terms and conditions herein, to the exclusion of any additional or
        different terms and conditions.
      </Paragraph>
      <Title level={5}>23. Intellectual Property</Title>
      <Paragraph>
        Any information submitted by one party to the other, prior or subsequent
        to Purchaser becoming a subscriber, shall remain the property of the
        submitting party ("Confidential Information"). Unless expressly stated
        in the agreement, LH and its suppliers maintain all intellectual
        property rights to all information provided. Confidential Information
        received by one party shall not, without the consent of the other party,
        be used for any other purpose than that for which they were provided.
        They may not, without the consent of the submitting party, otherwise be
        used or copied, reproduced, transmitted, or communicated to a third
        party. In the event Purchaser breaches the covenant not to disclose
        Confidential Information contained herein, LH shall be entitled to
        obtain, in addition to any other remedies to which LH may be entitled in
        law or in equity, injunctive relief (including an ex parte restraining
        order and a temporary and preliminary injunction) against Purchaser
        enjoining and restraining them from continuing to breach the provisions
        of the agreement. Nothing herein contained shall be construed as
        prohibiting LH from pursuing any other or additional remedies available
        at law or in equity for such breach. No such remedies shall be
        considered exclusive of any other remedy, and the same shall be
        cumulative, and each and every remedy may be exercised respectively from
        time to time, and as often as occasion may arise, or as may be deemed
        expedient. No delay or omission to exercise any of its respective powers
        or rights shall impair any such respective powers or rights, nor shall
        it be construed to be a waiver of any such powers or rights, or any
        acquiescence in any breach of the terms hereof. Neither of the parties
        may use or communicate to third parties know-how or Confidential
        Information that has not entered the public domain or trade secrets that
        a party may have learned in any way through any activity or interaction
        in connection with the agreement, its negotiation, or its execution. LH
        may collect data about its operation. If data is transmitted to LH for
        internal purposes at LH's discretion and responsibility, data received
        shall be handled as Confidential Information. Suspension or termination
        of the data transmission may be done without notice and without the
        consent of the other party at the discretion of LH.
      </Paragraph>
    </div>
  );
};

export default TermAndConditions;
