// A modal for selecting middle filters
import React, { useState, useEffect } from "react";
import { Modal, Button, Select, InputNumber, Form, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import config from "../../../config";
import styles from "./ToolOptions.module.less";

// middleFilters type: { id: string; sportId: string; minMiddle: number; minHold: number; }

// a single line function that generate a random string of a given lenght
const shortId = (length) => {
  return Array.from({ length }, () =>
    Math.floor(Math.random() * 36).toString(36)
  ).join("");
};

const MiddleFiltersModal = ({ open, onCancel, onSave, middleFilters }) => {
  const [localFilters, setLocalFilters] = useState(middleFilters);

  useEffect(() => {
    if (middleFilters) {
      setLocalFilters(middleFilters);
    } else {
      setLocalFilters(undefined);
    }
  }, [middleFilters]);

  let filtersContent = "NO FILTERS YET. ADD ONE!";
  if (localFilters) {
    const parsedFilters = JSON.parse(atob(localFilters));
    console.log(parsedFilters);
    const filterList = parsedFilters.map((filter, i) => {
      return (
        <div key={filter.id} className={styles.filterForm}>
          <Form layout="inline">
            <Form.Item
              label={<b>Sport</b>}
              labelCol={{ span: 24 }}
              style={{ width: 105 }}
            >
              <Select
                value={filter.sportId}
                size="small"
                onChange={(sportId) => {
                  setLocalFilters((previousFilters) => {
                    let previousParsedFilters = [];
                    if (previousFilters) {
                      previousParsedFilters = JSON.parse(atob(previousFilters));
                    }
                    previousParsedFilters[i].sportId = sportId;
                    return btoa(JSON.stringify(previousParsedFilters));
                  });
                }}
              >
                <Select.Option key="any" value="any">
                  Any
                </Select.Option>
                {Object.keys(config.sports).map((sport) => (
                  <Select.Option key={sport} value={sport}>
                    {sport.charAt(0).toUpperCase() + sport.slice(1)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <Tooltip
                  placement="topRight"
                  title="The minimum size of the middle"
                >
                  <b>
                    Min Middle
                    <sup style={{ fontSize: 8 }}>
                      {" "}
                      <InfoCircleOutlined />
                    </sup>
                  </b>
                </Tooltip>
              }
              labelCol={{ span: 24 }}
              style={{ width: 90 }}
            >
              <InputNumber
                size="small"
                value={filter.minMiddle}
                onChange={(minMiddle) => {
                  setLocalFilters((previousFilters) => {
                    let previousParsedFilters = [];
                    if (previousFilters) {
                      previousParsedFilters = JSON.parse(atob(previousFilters));
                    }
                    previousParsedFilters[i].minMiddle = minMiddle;
                    return btoa(JSON.stringify(previousParsedFilters));
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <Tooltip
                  placement="topRight"
                  title="The minimum arb % of the middle. You likely want to use negative numbers here."
                >
                  <b>
                    Min Arb %
                    <sup style={{ fontSize: 8 }}>
                      {" "}
                      <InfoCircleOutlined />
                    </sup>
                  </b>
                </Tooltip>
              }
              labelCol={{ span: 24 }}
              style={{ width: 90 }}
            >
              <InputNumber
                size="small"
                addonAfter="%"
                value={filter.minHold}
                onChange={(minHold) => {
                  setLocalFilters((previousFilters) => {
                    let previousParsedFilters = [];
                    if (previousFilters) {
                      previousParsedFilters = JSON.parse(atob(previousFilters));
                    }
                    previousParsedFilters[i].minHold = minHold;
                    return btoa(JSON.stringify(previousParsedFilters));
                  });
                }}
              />
            </Form.Item>
          </Form>
        </div>
      );
    });
    filtersContent = <div>{filterList}</div>;
  }

  return (
    <Modal title="Middle Filters" open={open} footer={null} onCancel={onCancel}>
      <div>
        <p style={{ opacity: 0.8, marginBlock: 0 }}>
          Any middles meeting any of these criteria will be shown.
        </p>
      </div>
      <div style={{ margin: "0.5rem 0" }}>{filtersContent}</div>
      <div style={{ margin: "0.5rem 0" }}>
        <Button
          onClick={() => {
            setLocalFilters((previousFilters) => {
              let previousParsedFilters = [];
              if (previousFilters) {
                previousParsedFilters = JSON.parse(atob(previousFilters));
              }
              return btoa(
                JSON.stringify([
                  ...previousParsedFilters,
                  { id: shortId(4), sportId: "any", minMiddle: 1, minHold: 0 },
                ])
              );
            });
          }}
        >
          Add Filter
        </Button>
      </div>
      <div style={{ margin: "0.5rem 0" }}>
        <Button
          type="primary"
          disabled={!localFilters || localFilters === middleFilters}
          onClick={() => {
            let parsedFilters;
            if (localFilters) {
              parsedFilters = JSON.parse(atob(localFilters));
              const filtersMap = {};
              parsedFilters.forEach((filter) => {
                const dedupeKey = `${filter.sportId}-${filter.minMiddle}-${filter.minHold}`;
                filtersMap[dedupeKey] = filter;
              });
              parsedFilters = Object.values(filtersMap);
            }

            if (parsedFilters) {
              const newFitlers = btoa(JSON.stringify(parsedFilters));
              onSave(newFitlers);
            } else {
              onSave(undefined);
            }
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default MiddleFiltersModal;
