import React, { useEffect, useState } from "react";
import { Space, Spin, Button, Divider } from "antd";
import PushNotificationSetting from "./PushNotificationSetting.component";

const PushNotificationSettingList = ({
  state,
  pushNotificationSettings,
  onUpdate,
  type,
}) => {
  const [
    inProgressPushNotificationSettings,
    setInProgressPushNotificationSettings,
  ] = useState();

  useEffect(() => {
    setInProgressPushNotificationSettings(pushNotificationSettings);
  }, [pushNotificationSettings]);

  if (state !== "success") {
    return <Spin />;
  }

  return (
    <div style={{ marginBottom: "1rem" }}>
      <Space style={{ display: "flex", flexWrap: "wrap" }} size="middle">
        {inProgressPushNotificationSettings.map((pns) => {
          let isNew = false;
          if (pns.isNew) {
            delete pns.isNew;
            isNew = true;
          }
          return (
            <div key={pns.id}>
              <PushNotificationSetting
                pushNotificationSetting={pns}
                type={type}
                isNew={isNew}
                onUpdate={onUpdate}
                onDelete={(id) => {
                  setInProgressPushNotificationSettings((previous) => {
                    return previous.filter((p) => p.id !== id);
                  });
                }}
              />
              <Divider style={{ marginBottom: 0 }} />
            </div>
          );
        })}
      </Space>
      <div style={{ marginTop: "1rem" }}>
        <Button
          type="primary"
          disabled={inProgressPushNotificationSettings.length >= 3}
          onClick={() => {
            setInProgressPushNotificationSettings((previous) => {
              return [...previous, { id: new Date().getTime(), isNew: true }];
            });
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default PushNotificationSettingList;
