import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUser,
  updateUserEmailSubscription,
  updateUserBooks,
  updateUserExperienceData,
  updateUserSportsbooksAccounts,
  postUserDiscordNotificationSettings,
  putUserDiscordAlertChannel,
  deleteUserDiscordAlertChannel,
  downgradeSubscription,
  cancelDowngradeSubscription,
  updateUserState,
  updateUserGlobalToolSettings,
} from "../../api";

const initialState = {
  user: undefined,
  status: "idle",
  error: null,
};

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ token }) => {
    const user = await getUser({ token });
    return user;
  }
);

export const putUserEmailSubscription = createAsyncThunk(
  "user/putUserEmailSubscription",
  async ({ token, emailType, value }) => {
    await updateUserEmailSubscription({ token, emailType, value });
    const user = await getUser({ token });
    return user;
  }
);

export const putUserBooks = createAsyncThunk(
  "user/putUserBooks",
  async ({ token, books }) => {
    await updateUserBooks({ token, books });
    const user = await getUser({ token });
    return user;
  }
);

export const putUserExperienceData = createAsyncThunk(
  "user/putUserExperienceData",
  async ({ token, experienceData }) => {
    await updateUserExperienceData({ token, experienceData });
    const user = await getUser({ token });
    return user;
  }
);

export const putUserSportsbookAccounts = createAsyncThunk(
  "user/putUserSportsbookAccounts",
  async ({ token, sportsbooksAccounts }) => {
    await updateUserSportsbooksAccounts({ token, sportsbooksAccounts });
    const user = await getUser({ token });
    return user;
  }
);

export const putUserState = createAsyncThunk(
  "user/putUserState",
  async ({ token, state }) => {
    await updateUserState({ token, state });
    const user = await getUser({ token });
    return user;
  }
);

export const addUserAlertChannel = createAsyncThunk(
  "user/addUserAlertChannel",
  async ({ token, channelId }) => {
    await putUserDiscordAlertChannel({ token, channelId });
    const user = await getUser({ token });
    return { user };
  }
);

export const removeUserAlertChannel = createAsyncThunk(
  "user/removeUserAlertChannel",
  async ({ token, channelId }) => {
    await deleteUserDiscordAlertChannel({ token, channelId });
    const user = await getUser({ token });
    return { user };
  }
);

export const putUserGlobalToolSettings = createAsyncThunk(
  "user/putUserGlobalToolSettings",
  async ({ token, globalToolSettings }) => {
    await updateUserGlobalToolSettings({ token, globalToolSettings });
    const user = await getUser({ token });
    return user;
  }
);

export const updateUserDiscordNotificationSettings = createAsyncThunk(
  "user/updateUserDiscordNotificationSettings",
  async ({ token, discordNotificationSettings }) => {
    let error;
    try {
      await postUserDiscordNotificationSettings({
        token,
        discordNotificationSettings,
      });
    } catch (err) {
      error =
        "We could not completely update your notficiation settings, please try again.";
      console.error(err);
    }
    const user = await getUser({ token });
    return { user, error };
  }
);

export const downgradeUserSubscription = createAsyncThunk(
  "user/downgradeUserSubscription",
  async ({ token }) => {
    let error;
    try {
      await downgradeSubscription({
        token,
      });
    } catch (err) {
      error = "We could not downgrade your subscription, please try again.";
      console.error(err);
    }
    const user = await getUser({ token });
    return { user, error };
  }
);

export const cancelDowngradeUserSubscription = createAsyncThunk(
  "user/cancelDowngradeUserSubscription",
  async ({ token }) => {
    let error;
    try {
      await cancelDowngradeSubscription({ token });
    } catch (err) {
      error = "We could not downgrade your subscription, please try again.";
      console.error(err);
    }
    const user = await getUser({ token });
    return { user, error };
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [putUserEmailSubscription.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [putUserBooks.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [putUserExperienceData.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [putUserSportsbookAccounts.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [putUserState.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [updateUserDiscordNotificationSettings.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.error = action.payload.error;
    },
    [addUserAlertChannel.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.error = action.payload.error;
    },
    [removeUserAlertChannel.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.error = action.payload.error;
    },
    [downgradeUserSubscription.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.error = action.payload.error;
    },
    [cancelDowngradeUserSubscription.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.error = action.payload.error;
    },
  },
});

export default userSlice.reducer;
