import React from "react";
import { Typography } from "antd";

const RefundPolicy = () => {
  return (
    <div style={{ paddingTop: "1rem" }}>
      <Typography.Paragraph>
        If you do not profit at least $100 in the first month (i.e. pay $125 and
        make $225) and you'd like to cancel the service, reach out to{" "}
        <b>support@bookiebeats.com</b> for a full refund.
      </Typography.Paragraph>
    </div>
  );
};

export default RefundPolicy;
