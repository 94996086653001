import React, { useState, useEffect } from "react";
import { Switch, Route, Link, useLocation, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Breadcrumb, ConfigProvider, theme } from "antd";
import { useSelector } from "react-redux";
import { useTheme } from "../hooks";
import styles from "./App.module.less";
import { Header, Drawer, Footer } from "./layout";
import Private from "./Private";
import {
  Contact,
  PrivacyPolicy,
  TermAndConditions,
  RefundPolicy,
  Promos,
  Landing,
  Auction,
  SuperbowlLeaderboard,
  SuperbowlEntry,
  Scoreboard,
  ScoreboardEntry,
} from "./pages";
import { Helmet } from "./layout";

const { Content } = Layout;

const NO_MAX_WIDTH_PATHS = ["/tools/live", "/tools/pregame"];
const NO_BREAD_CRUMB_PATHS = [];
const NO_BACKGROUND_PATHS = ["/"];

function AppContent() {
  const { isAuthenticated, isLoading } = useAuth0();
  const themeTokens = useTheme();

  const isNoMaxWidth = NO_MAX_WIDTH_PATHS.includes(window.location.pathname);
  const isNoBackground = NO_BACKGROUND_PATHS.includes(window.location.pathname);

  return (
    <Content
      className={styles.content}
      style={{
        maxWidth: isNoMaxWidth ? "" : 1000,
        backgroundColor:
          isNoMaxWidth || isNoBackground
            ? "transparent"
            : themeTokens.contentBg,
        padding: isNoMaxWidth ? 0 : undefined,
      }}
    >
      <Helmet />
      <Switch>
        <Route exact path="/terms-and-conditions">
          <TermAndConditions />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/refund-policy">
          <RefundPolicy />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/scoreboard">
          <Scoreboard />
        </Route>
        <Route exact path="/scoreboard-entry">
          <ScoreboardEntry />
        </Route>
        <Route exact path="/calculators/Fair-Value-EV">
          <Redirect to="/calculators/Expected-Value" />
        </Route>
        <Route exact path="/promos">
          <Promos />
        </Route>
        <Route exact path="/auction">
          <Auction />
        </Route>
        <Route path="/super-bowl-prop-bet-leaderboard/:id">
          <SuperbowlLeaderboard />
        </Route>
        <Route path="/super-bowl-prop-bet-entry">
          <SuperbowlEntry />
        </Route>
        {isAuthenticated ? (
          <Route path="/*">
            <Private />
          </Route>
        ) : isLoading ? null : (
          <Route path="/*">
            <Landing />
          </Route>
        )}
      </Switch>
    </Content>
  );
}

function App() {
  const [isDrawerVisable, setIsDrawerVisable] = useState(false);
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const isDarkTheme = useSelector((state) => state.theme.isDark);
  const isAfd = useSelector((state) => state.theme.isAfd);

  useEffect(() => {
    setIsDrawerVisable(false);
  }, [location]);

  const pathSegments = location.pathname.split("/");
  const firstPathSegment = pathSegments[1];

  const isNoMaxWidth = NO_MAX_WIDTH_PATHS.includes(window.location.pathname);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: isAfd ? "#ED474A" : "#1DA57A",
          "geekblue-10": isDarkTheme ? "#030853" : "#030852",
        },
        components: {
          Layout: {
            colorBgHeader: "white",
          },
          Typography: {
            sizeMarginHeadingVerticalStart: 0,
          },
        },
      }}
    >
      <Layout>
        <Header
          isAuthenticated={isAuthenticated}
          onMenuOpen={() => setIsDrawerVisable(true)}
        />
        <Layout>
          <Layout className={styles.pageLayout}>
            <div>
              <div
                className={styles.breadcrumbContainer}
                style={{
                  maxWidth: isNoMaxWidth ? "" : 1000,
                }}
              >
                {!NO_BREAD_CRUMB_PATHS.includes(firstPathSegment) && (
                  <Breadcrumb className={styles.breadcrumb}>
                    {pathSegments.map((pathSegment, i) => {
                      let item = "";
                      if (pathSegment.includes("-")) {
                        const itemWords = pathSegment.split("-");
                        itemWords.forEach((itemWord) => {
                          item +=
                            itemWord.charAt(0).toUpperCase() +
                            itemWord.slice(1) +
                            " ";
                        });
                      } else {
                        item =
                          pathSegment.charAt(0).toUpperCase() +
                          pathSegment.slice(1);
                      }
                      let link = "";
                      for (let j = 0; j <= i; j++) {
                        link = link + pathSegments[j] + "/";
                      }
                      if (item) {
                        return (
                          <Breadcrumb.Item key={item}>
                            <Link to={link}>{item}</Link>
                          </Breadcrumb.Item>
                        );
                      }
                      return <Breadcrumb.Item key="NOTHING" />;
                    })}
                  </Breadcrumb>
                )}
              </div>
              <AppContent />
            </div>
            <Footer />
          </Layout>
          <Drawer
            visible={isDrawerVisable}
            onClose={() => setIsDrawerVisable(false)}
          />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
