import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { Select, Space, InputNumber, Tooltip, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import getToken from "../../../../../utils/getToken";
import {
  putPushNotificationSetting,
  deletePushNotificationSetting,
} from "../../../../../api";
import bookSelectOptions from "../../../../components/BookSelectOptions/BookSelectOptions.component";
import styles from "./PushNotificationSetting.module.less";

const PushNotificationSetting = ({
  pushNotificationSetting,
  onUpdate,
  onDelete,
  type,
  isNew,
}) => {
  const auth0 = useAuth0();
  const user = useSelector((state) => state.user.user);
  const [inProgressPns, setInProgressPns] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setHasChanged(isNew);
  }, [isNew]);

  useEffect(() => {
    const pns = {
      id: pushNotificationSetting.id,
      enabled: pushNotificationSetting.enabled,
    };
    if (pushNotificationSetting.books)
      pns.books = pushNotificationSetting.books;
    else pns.books = user.books;

    if (pushNotificationSetting.minArbPercent)
      pns.minArbPercent = pushNotificationSetting.minArbPercent;
    else pns.minArbPercent = 10;

    if (pushNotificationSetting.ttl) pns.ttl = pushNotificationSetting.ttl;
    else pns.ttl = 15;

    if (pushNotificationSetting.includePlayerProps)
      pns.includePlayerProps = pushNotificationSetting.includePlayerProps;
    else pns.includePlayerProps = "NONE";

    setInProgressPns(pns);
  }, [user, pushNotificationSetting]);

  const onValueChange = (key, value) => {
    setInProgressPns((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });
    setHasChanged(true);
  };

  if (!inProgressPns) return <div />;

  return (
    <div>
      <Space style={{ display: "flex", flexWrap: "wrap" }} size="middle">
        <div>
          <div className={styles.formFieldTitle}>Books</div>
          <div>
            <Select
              value={inProgressPns.books}
              mode="multiple"
              style={{ width: 300 }}
              maxTagCount="responsive"
              onChange={(books) => {
                onValueChange("books", books);
              }}
            >
              {bookSelectOptions(user.books)}
            </Select>
          </div>
        </div>
        <div>
          <div className={styles.formFieldTitle}>
            Min Arb %{" "}
            <Tooltip title="The minumum arbitrage percent at which to start considering an arb eligible for sending an alert">
              <QuestionCircleOutlined style={{ color: "gray" }} />
            </Tooltip>
          </div>
          <div>
            <InputNumber
              value={inProgressPns.minArbPercent}
              min={0}
              addonAfter="%"
              inputMode="numeric"
              pattern="[0-9]*"
              controls={false}
              style={{ width: 90 }}
              onChange={(minArbPercent) => {
                onValueChange("minArbPercent", minArbPercent);
              }}
            />
          </div>
        </div>
        <div>
          <div className={styles.formFieldTitle}>
            TTL{" "}
            <Tooltip title="The time the arb should live above the given percent before alerting">
              <QuestionCircleOutlined style={{ color: "gray" }} />
            </Tooltip>
          </div>
          <div>
            <InputNumber
              value={inProgressPns.ttl}
              min={5}
              max={99}
              addonAfter="seconds"
              inputMode="numeric"
              pattern="[0-9]*"
              controls={false}
              style={{ width: 120 }}
              onChange={(ttl) => {
                onValueChange("ttl", ttl);
              }}
            />
          </div>
        </div>
        <div>
          <div className={styles.formFieldTitle}>
            Player Props{" "}
            <Tooltip
              title={
                <div>
                  NONE - don't include player props
                  <br />
                  INCLUDE - include player props along with non player props
                  <br />
                  ONLY - only consider player props for these thresholds
                </div>
              }
            >
              <QuestionCircleOutlined style={{ color: "gray" }} />
            </Tooltip>
          </div>
          <div>
            <Select
              value={inProgressPns.includePlayerProps}
              style={{ width: 100 }}
              onChange={(value) => {
                onValueChange("includePlayerProps", value);
              }}
            >
              <Select.Option value="NONE">NONE</Select.Option>
              <Select.Option value="INCLUDE">INCLUDE</Select.Option>
              <Select.Option value="ONLY">ONLY</Select.Option>
            </Select>
          </div>
        </div>
      </Space>
      <Space style={{ marginTop: "1rem" }}>
        <Button
          disabled={!hasChanged}
          onClick={async () => {
            const token = await getToken({ auth0 });
            await putPushNotificationSetting({
              token,
              id: inProgressPns.id,
              pushNotificationSetting: { ...inProgressPns, type },
            });
            await onUpdate();
            setHasChanged(false);
          }}
        >
          Save
        </Button>
        <Button
          onClick={async () => {
            const token = await getToken({ auth0 });
            await putPushNotificationSetting({
              token,
              id: inProgressPns.id,
              pushNotificationSetting: {
                ...inProgressPns,
                enabled: !inProgressPns.enabled,
                type,
              },
            });
            await onUpdate();
            setHasChanged(false);
          }}
        >
          {inProgressPns.enabled ? "Mute" : "Unmute"}
        </Button>
        <Button
          danger
          onClick={async () => {
            onDelete(inProgressPns.id);
            const token = await getToken({ auth0 });
            await deletePushNotificationSetting({
              token,
              id: inProgressPns.id,
            });
            await onUpdate();
          }}
        >
          Delete
        </Button>
      </Space>
    </div>
  );
};

export default PushNotificationSetting;
