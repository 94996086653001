import React from "react";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Typography } from "antd";
import logo from "../../../assets/logo_text.svg";
const { Title } = Typography;

const Error = ({ showLogo = false }) => {
  return (
    <div>
      {showLogo && (
        <div style={{ marginTop: "1rem", textAlign: "center" }}>
          <img src={logo} alt="Bookie Beat Logo" width={200} />
        </div>
      )}
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center", maxWidth: 400 }}>
          <ExclamationCircleTwoTone
            twoToneColor="#f5222d"
            style={{ fontSize: 150 }}
          />
          <Title level={3} style={{ marginTop: "1rem" }}>
            Something went wrong on our end, try again or come back later.
          </Title>
        </div>
      </div>
    </div>
  );
};

export default Error;
