import { theme } from "antd";

const light = {
  contentBg: "#fff",
  linearGradientBg: "linear-gradient(60deg, #19916b -0.7%, #28f1b2 89.08%)",
};

const dark = {
  contentBg: "rgba(255, 255, 255, 0.05)",
  linearGradientBg:
    "linear-gradient(60deg, rgb(0, 0, 0) -0.7%, rgb(16 93 69) 89.08%)",
};

const geekBlueHack = "#030853"; // hacking an antd token to determin if custom dark theme

const useTheme = () => {
  const { token } = theme.useToken();

  const customTheme = token["geekblue-10"] === geekBlueHack ? dark : light;

  return {
    ...token,
    ...customTheme,
  };
};

export default useTheme;
