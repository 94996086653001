import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";

const { Paragraph, Title } = Typography;
const LowHoldsHelp = () => {
  return (
    <div>
      <Title level={3}>Low Holds Help</Title>
      <Paragraph>
        This page displays the closest markets which are <b>not</b> arbitrage
        opportunities (aka low holds). This page will help you capitalize on
        promotions where you need to place $X of bets in a certain amount of
        time (aka churning).
      </Paragraph>
      <Paragraph>
        Ultimately, this page works exactly like the arbitrage page, except you
        will be losing money (i.e. paying) with each bet rather than making
        money. If you have any further questions about how to interpret the
        amounts, please view the overview on the{" "}
        <Link to="/tools/arbitrage">Arbitrage page</Link>, as it works the same
        way.
      </Paragraph>
      <Title level={4}>Inputs</Title>
      <Title level={5}>Sportsbook / Stake</Title>
      <Paragraph>
        Enter the book you are trying to bet on and the amount you would like to
        bet. This page will automatically sort by the cheapest hedge
        opportunities available.
      </Paragraph>
    </div>
  );
};

export default LowHoldsHelp;
