import React, { useState } from "react";
import * as oddslib from "oddslib";
import { Button, Divider, Typography, InputNumber, Select } from "antd";
import { useDispatch } from "react-redux";
import { setCurrentlyVisible } from "../../../state/ducks/modals";
const { Title, Text } = Typography;
const { Option } = Select;

function round(num, places) {
  var multiplier = Math.pow(10, places);
  return Math.round(num * multiplier) / multiplier;
}

function devigOddsAdditive(decimalOdds) {
  // Step 1: Convert decimal odds to implied probabilities
  let impliedProbabilities = decimalOdds.map((odds) => 1 / odds);

  // Step 2: Calculate the overround (vig)
  let sumImpliedProbabilities = impliedProbabilities.reduce((a, b) => a + b, 0);
  let vig = sumImpliedProbabilities - 1;

  // Step 3: Remove the vig using the additive method
  let deviggedProbabilities = impliedProbabilities.map(
    (prob) => prob - vig / impliedProbabilities.length
  );

  // Step 4: Convert the devigged probabilities back to decimal odds
  let deviggedOdds = deviggedProbabilities.map((prob) => 1 / prob);

  return deviggedOdds;
}

function devigOddsMultiplicative(decimalOdds) {
  // Step 1: Convert decimal odds to implied probabilities
  let impliedProbabilities = decimalOdds.map((odds) => 1 / odds);

  // Step 2: Calculate the sum of implied probabilities (vigged market)
  let sumImpliedProbabilities = impliedProbabilities.reduce((a, b) => a + b, 0);

  // Step 3: Remove the vig using the multiplicative method
  let deviggedProbabilities = impliedProbabilities.map(
    (prob) => prob / sumImpliedProbabilities
  );

  // Step 4: Convert the devigged probabilities back to decimal odds
  let deviggedOdds = deviggedProbabilities.map((prob) => 1 / prob);

  return deviggedOdds;
}

function devigOddsPower(decimalOdds) {
  // Step 1: Convert decimal odds to implied probabilities
  let impliedProbabilities = decimalOdds.map((odds) => 1 / odds);

  // Step 2: Define a function to calculate the sum of adjusted probabilities for a given k
  function sumAdjustedProbabilities(k) {
    return impliedProbabilities.reduce((sum, r) => sum + Math.pow(r, 1 / k), 0);
  }

  // Step 3: Find the correct k such that sum of adjusted probabilities equals 1
  let k = 1; // Starting value for k
  let tolerance = 0.00001; // Tolerance for convergence
  let maxIterations = 1000; // Limit the number of iterations
  let iteration = 0;

  while (iteration < maxIterations) {
    let sumProbabilities = sumAdjustedProbabilities(k);
    if (Math.abs(sumProbabilities - 1) < tolerance) {
      break; // Converged to the correct k
    }
    k = k / sumProbabilities; // Adjust k based on the sum
    iteration++;
  }

  // Step 4: Apply the power function to each probability
  let deviggedProbabilities = impliedProbabilities.map((r) =>
    Math.pow(r, 1 / k)
  );

  // Step 5: Convert the devigged probabilities back to decimal odds
  let deviggedOdds = deviggedProbabilities.map((prob) => 1 / prob);

  return deviggedOdds;
}

function getShinImpliedProbabilities(z, pi) {
  const normalization = pi.reduce((a, b) => a + b, 0);
  return pi.map(
    (p) =>
      ((z ** 2 + (4 * (1 - z) * p ** 2) / normalization) ** 0.5 - z) /
      (2 - 2 * z)
  );
}

function getShinNormalization(z, pi) {
  const impliedProbabilities = getShinImpliedProbabilities(z, pi);
  return 1 - impliedProbabilities.reduce((a, b) => a + b, 0);
}

function bisect(func, low, high, args, tol = 0.00001, maxIter = 1000) {
  let mid;
  for (let i = 0; i < maxIter; i++) {
    mid = (low + high) / 2;
    const fMid = func(mid, args);
    if (Math.abs(fMid) < tol) {
      return mid;
    } else if (fMid > 0) {
      high = mid;
    } else {
      low = mid;
    }
  }
  return mid;
}

function devigOddsShin(decimalOdds) {
  // Step 1: Convert decimal odds to implied probabilities
  let impliedProbabilities = decimalOdds.map((odds) => 1 / odds);

  // Step 2: Use bisection method to find the optimal value of z
  let zOpt = bisect(
    (z) => getShinNormalization(z, impliedProbabilities),
    0,
    10,
    0.0001
  );

  // Step 3: Calculate the devigged probabilities using the Shin method
  let deviggedProbabilities = getShinImpliedProbabilities(
    zOpt,
    impliedProbabilities
  );

  // Step 4: Convert the devigged probabilities back to decimal odds
  let deviggedOdds = deviggedProbabilities.map((prob) => 1 / prob);

  return deviggedOdds;
}

// Example usage:
let decimalOdds = [2.0, 3.5, 4.0]; // Example odds
let deviggedOdds = devigOddsPower(decimalOdds);
console.log("Devigged Odds (Power Method):", deviggedOdds);

const devigMethods = {
  additive: devigOddsAdditive,
  multiplicative: devigOddsMultiplicative,
  power: devigOddsPower,
  shin: devigOddsShin,
};

const FairValueEVCalculator = () => {
  const dispatch = useDispatch();
  const [legs, setLegs] = useState([
    {
      outcomes: [{}, {}],
    },
  ]);
  const [yourOdds, setYourOdds] = useState();
  const [devigMethod, setDevigMethod] = useState("multiplicative");

  let validForm = true;
  legs.forEach((leg) => {
    let allOddsFilled = true;
    let impliedProbabilities = [];
    let totalImpliedProbability = 0;
    leg.outcomes.forEach((outcome) => {
      if (!(Math.abs(outcome.odds) / 100 >= 1)) allOddsFilled = false;
      if (outcome.odds) {
        let impliedProbability = oddslib
          .from("moneyline", outcome.odds)
          .to("impliedProbability");
        let decimalOdds = oddslib.from("moneyline", outcome.odds).to("decimal");
        totalImpliedProbability += impliedProbability;
        outcome.impliedProbability = impliedProbability;
        outcome.decimalOdds = decimalOdds;
        impliedProbabilities.push(impliedProbability);
      }
    });
    if (allOddsFilled) {
      leg.margin = impliedProbabilities.reduce((a, b) => a + b, 0) - 1;
      leg.totalImpliedProbability = totalImpliedProbability;
      const decimalOdds = leg.outcomes.map((outcome) => outcome.decimalOdds);
      let deviggedOdds = [0, 0];
      if (devigMethod === "worst") {
        const methodOdds = {};
        const firstOutcomeOdds = {};
        Object.keys(devigMethods).forEach((method) => {
          const deviggedOdds = devigMethods[method](decimalOdds);
          methodOdds[method] = deviggedOdds;
          firstOutcomeOdds[method] = deviggedOdds[0];
        });
        // Find highest odds for first outcome
        let maxOdds = 0;
        let maxMethod;
        Object.keys(firstOutcomeOdds).forEach((method) => {
          if (firstOutcomeOdds[method] > maxOdds) {
            maxOdds = firstOutcomeOdds[method];
            maxMethod = method;
          }
        });
        deviggedOdds = methodOdds[maxMethod];
        leg.outcomes[0].deviggedMethod = maxMethod;
      } else {
        deviggedOdds = devigMethods[devigMethod](decimalOdds);
      }
      decimalOdds.forEach((_, i) => {
        leg.outcomes[i].deviggedDecimalOdds = deviggedOdds[i];
        leg.outcomes[i].deviggedImpliedProbability = 1 / deviggedOdds[i];
      });
    } else {
      validForm = false;
      delete leg.margin;
      delete leg.totalImpliedProbability;
    }
  });

  let fairProbability, fairAmericanOdds;
  if (validForm) {
    let tempFairProbability = 1;
    legs.forEach((leg) => {
      let fairImpliedProbability = leg.outcomes[0].deviggedImpliedProbability;
      tempFairProbability = tempFairProbability * fairImpliedProbability;
    });
    fairProbability = tempFairProbability;
    fairAmericanOdds = Math.round(
      oddslib
        .from("impliedProbability", fairProbability)
        .to("moneyline")
        .toFixed(2)
    );
  }

  let yourProbability;
  if (Math.abs(yourOdds) / 100 >= 1) {
    yourProbability = oddslib
      .from("moneyline", yourOdds)
      .to("impliedProbability");
  }

  let expectedValue;
  if (yourProbability && fairProbability) {
    const yourFractionalOdds =
      oddslib.from("impliedProbability", yourProbability).to("decimal") - 1;
    let decmialExpectedValue =
      fairProbability * yourFractionalOdds - (1 - fairProbability);
    expectedValue = (decmialExpectedValue * 100).toFixed(2);
  }

  return (
    <div style={{ paddingTop: "1rem" }}>
      <div style={{ display: "flex" }}>
        <div>
          <Title level={3}>Method</Title>
        </div>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <div style={{ marginRight: "1rem" }}>
            <Button
              type="dashed"
              onClick={() => dispatch(setCurrentlyVisible("EVCalculatorHelp"))}
            >
              Help
            </Button>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button
              type="dashed"
              onClick={() => {
                setLegs([
                  {
                    outcomes: [{}, {}],
                  },
                ]);
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Select
          defaultValue="multiplicative"
          style={{ width: 130 }}
          onChange={(value) => setDevigMethod(value)}
        >
          <Option value="additive">Additive</Option>
          <Option value="multiplicative">Multiplicative</Option>
          <Option value="power">Power</Option>
          <Option value="shin">Shin</Option>
          <Option value="worst">Worst Case</Option>
        </Select>
      </div>
      <Divider />
      <div style={{ display: "flex" }}>
        <div>
          <Title level={3}>Sharp Odds</Title>
        </div>
      </div>
      <div style={{ display: "flex", fontWeight: 500 }}>
        <div style={{ width: 85 }}>
          <Text>Bet Odds</Text>
        </div>
        <div>
          <Text>Other Outcome Odds</Text>
        </div>
      </div>
      {legs.map((leg, l) => {
        return (
          <div key={`leg${l}`}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {leg.outcomes.map((outcome, o) => {
                const fairDecimalOdds = outcome.deviggedDecimalOdds;
                return (
                  <div
                    key={`leg${l}outcome${o}`}
                    style={{ paddingRight: "10px", marginTop: "0.5rem" }}
                  >
                    <div>
                      <InputNumber
                        controls={false}
                        style={{
                          width: 75,
                          borderColor:
                            o === 0
                              ? "rgb(200, 200, 200)"
                              : "rgb(217, 217, 217)",
                        }}
                        value={legs[l].outcomes[o].odds}
                        onChange={(v) => {
                          let newLegs = JSON.parse(JSON.stringify(legs));
                          newLegs[l].outcomes[o].odds = v;
                          setLegs(newLegs);
                        }}
                      />
                    </div>
                    <div style={{ fontSize: 12, color: "gray" }}>
                      Fair:{" "}
                      {isNaN(fairDecimalOdds)
                        ? "--"
                        : Math.round(
                            oddslib
                              .from("decimal", fairDecimalOdds)
                              .to("moneyline")
                          )}
                    </div>
                    {outcome.deviggedMethod && (
                      <div style={{ fontSize: 12, color: "gray" }}>
                        Method:{" "}
                        {outcome.deviggedMethod.charAt(0).toUpperCase() +
                          outcome.deviggedMethod.slice(1)}
                      </div>
                    )}
                  </div>
                );
              })}
              {leg.outcomes.length < 50 && (
                <div style={{ marginTop: "0.5rem" }}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      let newLegs = JSON.parse(JSON.stringify(legs));
                      newLegs[l].outcomes.push({});
                      setLegs(newLegs);
                    }}
                  >
                    + Outcome
                  </Button>
                </div>
              )}
            </div>
            <div style={{ fontSize: 12 }}>
              <Text>
                Margin:{" "}
                {isNaN(leg.margin) ? "--" : `${round(leg.margin * 100, 2)}%`}
              </Text>
            </div>
            <Divider dashed style={{ margin: "0.5rem 0" }} />
          </div>
        );
      })}
      <div>
        <Button
          type="dashed"
          onClick={() => {
            let newLegs = JSON.parse(JSON.stringify(legs));
            newLegs.push({
              outcomes: [{}, {}],
            });
            setLegs(newLegs);
          }}
        >
          + Leg
        </Button>
      </div>
      <Divider />
      <div style={{ display: "flex" }}>
        <div>
          <Title level={3}>Your Odds</Title>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="dashed"
            onClick={() => {
              setLegs([
                {
                  outcomes: [{}, {}],
                },
              ]);
            }}
          >
            Reset
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ paddingRight: "0.75rem", marginBottom: "1rem" }}>
          <div style={{ fontWeight: 500, marginBottom: "0.25rem" }}>
            <Text>Odds</Text>
          </div>
          <div>
            <InputNumber
              controls={false}
              style={{ width: 75 }}
              value={yourOdds}
              onChange={setYourOdds}
            />
          </div>
        </div>
        <div style={{ paddingRight: "0.75rem" }}>
          <div style={{ fontWeight: 500, marginBottom: "0.25rem" }}>
            <Text>Fair Odds</Text>
          </div>
          <div style={{ fontSize: 18, lineHeight: "30px" }}>
            <Text>
              {fairAmericanOdds
                ? fairAmericanOdds > 0
                  ? `+${fairAmericanOdds}`
                  : fairAmericanOdds
                : "--"}
            </Text>
          </div>
        </div>
        <div style={{ paddingRight: "0.75rem" }}>
          <div style={{ fontWeight: 500, marginBottom: "0.25rem" }}>
            <Text>Probability</Text>
          </div>
          <div style={{ fontSize: 18, lineHeight: "30px" }}>
            <Text>
              {fairAmericanOdds
                ? round(
                    100 *
                      oddslib
                        .from("moneyline", fairAmericanOdds)
                        .to("impliedProbability"),
                    2
                  ) + "%"
                : "--"}
            </Text>
          </div>
        </div>
        <div style={{ paddingRight: "0.75rem" }}>
          <div style={{ fontWeight: 500, marginBottom: "0.25rem" }}>
            <Text>Expected Value</Text>
          </div>
          <div style={{ fontSize: 18, lineHeight: "30px", color: "#1da57a" }}>
            <Text>{expectedValue ? `${expectedValue}%` : "--"}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FairValueEVCalculator;
