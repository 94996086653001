import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { Tabs, Modal } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import useSound from "use-sound";
import {
  PushpinOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
// import useCookie from "react-use-cookie";
import { getLivePlays, getPlays } from "../../../api";
import getToken from "../../../utils/getToken";
import { setCurrentlyVisible } from "../../../state/ducks/modals";
import { setData } from "../../../state/ducks/liveAlert";
import { useTheme } from "../../../hooks";
import ToolOptions from "../../components/ToolOptions/ToolOptions.component";
import Market from "../../components/Market/Market";
import styles from "./BetFinder.module.less";

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const BetFinder = ({ live = true } = { live: true }) => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const user = useSelector((state) => state.user.user);
  const optionsElement = useRef(null);
  const [playChime] = useSound("https://mcwebby.s3.amazonaws.com/chime.mp3");
  const [playAlarm] = useSound("https://mcwebby.s3.amazonaws.com/alarm.mp3");
  const [options, setOptions] = useState({ autoRefreshEnabled: false });
  // eslint-disable-next-line
  const [marketFetchStatus, setMarketFetchStatus] = useState("idle");
  const [markets, setMarkets] = useState();
  const [displayedMarkets, setDisplayedMarkets] = useState();
  const [pinnedMarket, setPinnedMarket] = useState();
  const [hiddenMarkets, setHiddenMarkets] = useState([]);
  const [stayingPowerRank, setStayingPowerRank] = useState({});
  // const [dangerAlertVisible, setDangerAlertVisible] = useCookie(
  //   "liveDangerAlertVisible",
  //   "true",
  //   { days: 365 }
  // );
  const themeTokens = useTheme();

  const fetchMarkets = useCallback(async () => {
    setMarketFetchStatus("loading");
    try {
      const token = await getToken({ auth0 });
      const fetchMarketsOptions = {
        token,
        live: live,
        books: options.books,
        expectedValueCalcBooks: options.expectedValueCalcBooks,
        leagues: options.leagues,
        mainOnly: options.mainOnly ? "true" : undefined,
        middlesOnly: options.middlesOnly ? "true" : undefined,
        hidePlayerProps: options.hidePlayerProps ? "true" : undefined,
        onlyPlayerProps: options.onlyPlayerProps ? "true" : undefined,
        playBook:
          options.lowHoldBook !== "any" ? options.lowHoldBook : undefined,
        marketTypes:
          options.marketTypes && options.marketTypes.length
            ? options.marketTypes
            : undefined,
        oddsRanges:
          options.oddsRanges &&
          options.oddsRanges.length &&
          options.oddsRanges[0].book
            ? options.oddsRanges
            : undefined,
        middleFilters: options.middleFilters
          ? options.middleFilters
          : undefined,
        mainLineOnlyBooks:
          options.mainLineOnlyBooks && options.mainLineOnlyBooks.length
            ? options.mainLineOnlyBooks
            : undefined,
        // includeAllOutcomes: "true",
      };
      let fetchFunction;
      if (live) {
        fetchMarketsOptions.type = options.betType;
        if (options.betType === "arbitrage") {
          fetchMarketsOptions.boostBook = options.boostBook;
          fetchMarketsOptions.boostPercent = options.boostPercent;
        }
        fetchFunction = getLivePlays;
      } else {
        fetchMarketsOptions.type = options.betType;
        if (options.betType === "arbitrage") {
          fetchMarketsOptions.boostBook = options.boostBook;
          fetchMarketsOptions.boostPercent = options.boostPercent;
        }
        fetchFunction = getPlays;
      }

      const response = await fetchFunction(fetchMarketsOptions);
      let tmpMarkets = [];

      let plays = response.plays;

      if (
        options.betType === "arbitrage" ||
        options.betType === "lowhold" ||
        options.betType === "highhold"
      ) {
        for (let i = 0; i < plays.length; i++) {
          let market = plays[i];
          const { outcomes } = market;
          const outcome0 = outcomes.find(
            (outcome) =>
              outcome.side === "Away (3 Way)" ||
              outcome.side === "Away or Draw" ||
              outcome.side === "Draw" ||
              outcome.side === "Away" ||
              outcome.side === "Over" ||
              outcome.side === "Yes"
          );
          const outcome1 = outcomes.find(
            (outcome) =>
              outcome.side === "Home (3 Way)" ||
              outcome.side === "Home or Draw" ||
              outcome.side === "Home or Away" ||
              outcome.side === "Home" ||
              outcome.side === "Under" ||
              outcome.side === "No"
          );

          market.profitNum = parseFloat(market.profit);

          let bet0, bet1, isBet1Underdog, profitDollarsNum;
          const dogOutcome =
            outcome0.odds <= outcome1.odds ? outcome1 : outcome0;
          const favOutcome =
            outcome0.odds < outcome1.odds ? outcome0 : outcome1;
          const dogOdds = dogOutcome.boostedOdds
            ? dogOutcome.boostedOdds
            : dogOutcome.odds;
          const dogArbPercent = (1 / dogOdds) * 100;
          const favArbPercent = (1 / favOutcome.odds) * 100;
          const totalArbPercent = dogArbPercent + favArbPercent;
          const dogPercentStake =
            market.profit !== "0.00"
              ? (100 * dogArbPercent) / market.profit
              : 100 * dogArbPercent;
          const favPercentStake =
            market.profit !== "0.00"
              ? (100 * favArbPercent) / market.profit
              : 100 * favArbPercent;

          let dogStake, favStake;
          if (options.stakeType === "underdog") {
            dogStake = options.stake || 100;
            favStake = dogStake * (favPercentStake / dogPercentStake);
          } else if (options.stakeType === "favorite") {
            favStake = options.stake || 100;
            dogStake = favStake * (dogPercentStake / favPercentStake);
          } else {
            if (favOutcome.book === options.stakeBook) {
              favStake = options.stake || 100;
              dogStake = favStake * (dogPercentStake / favPercentStake);
            } else {
              dogStake = options.stake || 100;
              favStake = dogStake * (favPercentStake / dogPercentStake);
            }
          }

          isBet1Underdog =
            dogOutcome.side === "Home (3 Way)" ||
            dogOutcome.side === "Home or Draw" ||
            dogOutcome.side === "Home or Away" ||
            dogOutcome.side === "Home" ||
            dogOutcome.side === "Under" ||
            dogOutcome.side === "No";
          bet0 = isBet1Underdog ? dogStake : favStake;
          bet1 = isBet1Underdog ? favStake : dogStake;
          const totalStake = dogStake + favStake;
          profitDollarsNum = totalStake / (totalArbPercent / 100) - totalStake;

          market.bet0 = bet0;
          market.bet1 = bet1;
          market.profitDollars = profitDollarsNum.toFixed(2);
          market.profitDollarsNum = profitDollarsNum;
          tmpMarkets.push(market);
        }

        if (tmpMarkets.length > 0) {
          if (options.sortOrder === "dollars") {
            tmpMarkets.sort((a, b) => b.profitDollarsNum - a.profitDollarsNum);
          }

          if (options.showExpectedValue && options.sortByExpectedValue) {
            tmpMarkets.sort((a, b) => {
              if (
                !options.expectedValueSortBook ||
                options.expectedValueSortBook === "any"
              ) {
                const aHighEV =
                  a.outcomes[0].expectedValue > a.outcomes[1].expectedValue
                    ? a.outcomes[0].expectedValue
                    : a.outcomes[1].expectedValue;
                const bHighEV =
                  b.outcomes[0].expectedValue > b.outcomes[1].expectedValue
                    ? b.outcomes[0].expectedValue
                    : b.outcomes[1].expectedValue;
                return bHighEV - aHighEV;
              } else {
                const evsb = options.expectedValueSortBook;
                let aSortBookOutcome, bSortBookOutcome;
                if (a.outcomes[0].book === evsb)
                  aSortBookOutcome = a.outcomes[0];
                if (a.outcomes[1].book === evsb)
                  aSortBookOutcome = a.outcomes[1];
                if (b.outcomes[0].book === evsb)
                  bSortBookOutcome = b.outcomes[0];
                if (b.outcomes[1].book === evsb)
                  bSortBookOutcome = b.outcomes[1];
                if (!aSortBookOutcome && !bSortBookOutcome) return 0;
                if (aSortBookOutcome && !bSortBookOutcome) return -1;
                if (!aSortBookOutcome && bSortBookOutcome) return 1;
                return (
                  bSortBookOutcome.expectedValue -
                  aSortBookOutcome.expectedValue
                );
              }
            });
          }
        }
      } else {
        for (let i = 0; i < plays.length; i++) {
          let market = plays[i];
          if (
            options.freeValueBook === "any" ||
            options.freeValueBook === market.book
          ) {
            const { outcomes } = market;
            const freeValueBook =
              options.freeValueBook === "any"
                ? market.book
                : options.freeValueBook;
            const freeValueOutcome = outcomes.find(
              (outcome) => outcome.book === freeValueBook
            );
            const hedgeOutcome = outcomes.find(
              (outcome) => outcome.book !== freeValueBook
            );

            let hedgeValue;
            const freeValueFractionalOdds = freeValueOutcome.odds - 1; // convert to fractional odds
            if (options.freeValueReturnedAsFreeBet) {
              const hedgeFractionalOdds = hedgeOutcome.odds - 1; // convert to fractional odds
              const expectedConversionDeciaml =
                options.expectedFreeBetConversion / 100;
              hedgeValue =
                (options.stake *
                  (-expectedConversionDeciaml + freeValueFractionalOdds + 1)) /
                (hedgeFractionalOdds + 1);
            } else {
              hedgeValue =
                (options.stake * (freeValueOutcome.odds - 1)) /
                hedgeOutcome.odds;
            }

            const profitIfFreeValueWin =
              options.stake * freeValueFractionalOdds - hedgeValue;
            const profitPercent = profitIfFreeValueWin / options.stake;

            market.profitNum = profitPercent * 100;
            market.profit = `${market.profitNum.toFixed(2)}`;
            market.profitDollarsNum = options.stake * profitPercent;
            market.profitDollars = market.profitDollarsNum;

            const isBet1FreeValue =
              freeValueOutcome.side === "Home (3 Way)" ||
              freeValueOutcome.side === "Home or Draw" ||
              freeValueOutcome.side === "Home or Away" ||
              freeValueOutcome.side === "Home" ||
              freeValueOutcome.side === "Under" ||
              freeValueOutcome.side === "No";
            market.bet0 = isBet1FreeValue ? options.stake : hedgeValue;
            market.bet1 = isBet1FreeValue ? hedgeValue : options.stake;

            tmpMarkets.push(market);
          }
        }
      }

      const finalMarkets = [];
      const finalMarketIds = {};
      const belowFloorMarkets = [];
      const betweenMarkets = [];
      const aboveCeilingMarkets = [];
      let stayingPowerFloor = options.stayingPowerFloor;
      if (stayingPowerFloor === undefined)
        stayingPowerFloor = Number.MIN_SAFE_INTEGER;
      let stayingPowerCeiling = options.stayingPowerCeiling;
      if (stayingPowerCeiling === undefined)
        stayingPowerCeiling = Number.MAX_SAFE_INTEGER;

      tmpMarkets.forEach((tmpMarket) => {
        const { participants } = tmpMarket.event;
        let shouldIncludeMarket = true;
        for (let i = 0; i < hiddenMarkets.length; i++) {
          const hiddenMarket = hiddenMarkets[i];
          if (
            hiddenMarket.participants[0] === participants[0] &&
            hiddenMarket.participants[1] === participants[1]
          ) {
            if (hiddenMarket.marketTypeId) {
              if (hiddenMarket.marketTypeId === tmpMarket.type) {
                shouldIncludeMarket = false;
                break;
              }
            } else {
              shouldIncludeMarket = false;
              break;
            }
          }
        }
        if (shouldIncludeMarket) {
          const {
            event: { id: eventId },
            type,
            outcomes,
          } = tmpMarket;
          const id = `${eventId}-${type}-${outcomes[0].book}-${outcomes[1].book}`;
          finalMarketIds[id] = true;
          const finalMarket = { ...tmpMarket, id };
          if (tmpMarket.profitNum < stayingPowerFloor) {
            belowFloorMarkets.push(finalMarket);
          } else if (tmpMarket.profitNum >= stayingPowerCeiling) {
            aboveCeilingMarkets.push(finalMarket);
          } else {
            betweenMarkets.push(finalMarket);
          }

          finalMarkets.push({ ...tmpMarket, id });
        }
      });

      if (options.sortOrder === "stayingPower") {
        const newStayingPowerRank = JSON.parse(
          JSON.stringify(stayingPowerRank)
        );
        const scrapeTime = new Date().getTime();
        const alreadyCoutnedMarketIds = {};
        for (const [marketId] of Object.entries(finalMarketIds)) {
          if (!alreadyCoutnedMarketIds[marketId]) {
            if (newStayingPowerRank[marketId]) {
              alreadyCoutnedMarketIds[marketId] = true;
              let last5 = [...newStayingPowerRank[marketId].last5];
              last5.unshift(true);
              last5.length = Math.min(last5.length, 4);
              const count = last5.filter(Boolean).length;
              newStayingPowerRank[marketId] = {
                last5,
                count,
                lastScrapeTime: scrapeTime,
              };
            } else {
              alreadyCoutnedMarketIds[marketId] = true;
              newStayingPowerRank[marketId] = {
                last5: [true],
                count: 1,
                lastScrapeTime: scrapeTime,
              };
            }
          }
        }

        for (const [marketId, v] of Object.entries(newStayingPowerRank)) {
          if (v.lastScrapeTime !== scrapeTime) {
            let last5 = [...v.last5];
            last5.unshift(false);
            last5.length = Math.min(last5.length, 4);
            const count = last5.filter(Boolean).length;
            newStayingPowerRank[marketId] = {
              last5,
              count,
              lastScrapeTime: scrapeTime,
            };
            if (count === 0) {
              delete newStayingPowerRank[marketId];
            }
          }
        }

        betweenMarkets.sort(
          (a, b) =>
            newStayingPowerRank[b.id].count - newStayingPowerRank[a.id].count
        );
        setStayingPowerRank(newStayingPowerRank);
        setMarkets(tmpMarkets);
        setDisplayedMarkets([
          ...aboveCeilingMarkets,
          ...betweenMarkets,
          ...belowFloorMarkets,
        ]);
      } else {
        setMarkets(tmpMarkets);
        setDisplayedMarkets(finalMarkets);
      }
      setMarketFetchStatus("succeeded");

      let alertAlreadyPlayed = false;
      for (let i = 0; i < finalMarkets.length; i++) {
        let market = finalMarkets[i];
        if (
          live &&
          !alertAlreadyPlayed &&
          options.alert2Enabled &&
          options.alert2Threshold &&
          market.profitNum >= parseFloat(options.alert2Threshold)
        ) {
          alertAlreadyPlayed = true;
          playAlarm();
        }
        if (
          live &&
          !alertAlreadyPlayed &&
          options.alertEnabled &&
          options.alertThreshold &&
          market.profitNum >= parseFloat(options.alertThreshold)
        ) {
          alertAlreadyPlayed = true;
          playChime();
        }
      }
    } catch (err) {
      console.error(err);
      if (!live) {
        if (err.message.includes("timeout")) {
          setMarketFetchStatus("timeout");
        } else {
          setMarketFetchStatus("failed");
        }
      }
    }
  }, [
    options,
    auth0,
    playChime,
    playAlarm,
    hiddenMarkets,
    live,
    stayingPowerRank,
  ]);

  useEffect(() => {
    setMarkets();
    setDisplayedMarkets();
  }, [options.betType]);

  useEffect(() => {
    if (marketFetchStatus === "timeout") {
      Modal.error({
        title: "TIMEOUT",
        content: (
          <span>
            Sometimes one of the books is being slow or there is just too much
            data for us to fetch.
            <br />
            <br />
            Try refining your search (less books, specific sport or league, etc)
            and try again.
          </span>
        ),
      });
    } else if (marketFetchStatus === "failed") {
      console.log(marketFetchStatus);
      Modal.error({
        title: "WHOOPS",
        content: <span>Something is wrong right now, maybe try again?</span>,
      });
    }
  }, [marketFetchStatus]);

  useEffect(() => {
    if (markets) {
      let tmpMarkets = [];

      if (
        options.betType === "arbitrage" ||
        options.betType === "lowhold" ||
        options.betType === "highhold"
      ) {
        for (let i = 0; i < markets.length; i++) {
          let market = markets[i];
          const { outcomes } = market;
          const outcome0 = outcomes.find(
            (outcome) =>
              outcome.side === "Away (3 Way)" ||
              outcome.side === "Away or Draw" ||
              outcome.side === "Draw" ||
              outcome.side === "Away" ||
              outcome.side === "Over" ||
              outcome.side === "Yes"
          );
          const outcome1 = outcomes.find(
            (outcome) =>
              outcome.side === "Home (3 Way)" ||
              outcome.side === "Home or Draw" ||
              outcome.side === "Home or Away" ||
              outcome.side === "Home" ||
              outcome.side === "Under" ||
              outcome.side === "No"
          );

          market.profitNum = parseFloat(market.profit);

          if (!outcome0 || !outcome1) {
            console.log(outcomes);
          }

          let bet0, bet1, isBet1Underdog, profitDollarsNum;
          const dogOutcome =
            outcome0.odds <= outcome1.odds ? outcome1 : outcome0;
          const favOutcome =
            outcome0.odds < outcome1.odds ? outcome0 : outcome1;
          const dogOdds = dogOutcome.boostedOdds
            ? dogOutcome.boostedOdds
            : dogOutcome.odds;
          const dogArbPercent = (1 / dogOdds) * 100;
          const favArbPercent = (1 / favOutcome.odds) * 100;
          const totalArbPercent = dogArbPercent + favArbPercent;
          const dogPercentStake =
            market.profit !== "0.00"
              ? (100 * dogArbPercent) / market.profit
              : 100 * dogArbPercent;
          const favPercentStake =
            market.profit !== "0.00"
              ? (100 * favArbPercent) / market.profit
              : 100 * favArbPercent;

          let dogStake, favStake;
          if (options.stakeType === "underdog") {
            dogStake = options.stake || 100;
            favStake = dogStake * (favPercentStake / dogPercentStake);
          } else if (options.stakeType === "favorite") {
            favStake = options.stake || 100;
            dogStake = favStake * (dogPercentStake / favPercentStake);
          } else {
            if (favOutcome.book === options.stakeBook) {
              favStake = options.stake || 100;
              dogStake = favStake * (dogPercentStake / favPercentStake);
            } else {
              dogStake = options.stake || 100;
              favStake = dogStake * (favPercentStake / dogPercentStake);
            }
          }

          isBet1Underdog =
            dogOutcome.side === "Home (3 Way)" ||
            dogOutcome.side === "Home or Draw" ||
            dogOutcome.side === "Home or Away" ||
            dogOutcome.side === "Home" ||
            dogOutcome.side === "Under" ||
            dogOutcome.side === "No";
          bet0 = isBet1Underdog ? dogStake : favStake;
          bet1 = isBet1Underdog ? favStake : dogStake;
          const totalStake = dogStake + favStake;
          profitDollarsNum = totalStake / (totalArbPercent / 100) - totalStake;

          market.bet0 = bet0;
          market.bet1 = bet1;
          market.profitDollarsNum = profitDollarsNum;
          market.profitDollars = profitDollarsNum.toFixed(2);
          tmpMarkets.push(market);
        }

        if (tmpMarkets.length > 0) {
          if (options.sortOrder === "dollars") {
            tmpMarkets.sort((a, b) => b.profitDollarsNum - a.profitDollars);
          } else if (options.sortOrder === "percent") {
            tmpMarkets.sort((a, b) => b.profitNum - a.profitNum);
          }

          if (options.showExpectedValue && options.sortByExpectedValue) {
            tmpMarkets.sort((a, b) => {
              if (
                !options.expectedValueSortBook ||
                options.expectedValueSortBook === "any"
              ) {
                const aHighEV =
                  a.outcomes[0].expectedValue > a.outcomes[1].expectedValue
                    ? a.outcomes[0].expectedValue
                    : a.outcomes[1].expectedValue;
                const bHighEV =
                  b.outcomes[0].expectedValue > b.outcomes[1].expectedValue
                    ? b.outcomes[0].expectedValue
                    : b.outcomes[1].expectedValue;
                return bHighEV - aHighEV;
              } else {
                const evsb = options.expectedValueSortBook;
                let aSortBookOutcome, bSortBookOutcome;
                if (a.outcomes[0].book === evsb)
                  aSortBookOutcome = a.outcomes[0];
                if (a.outcomes[1].book === evsb)
                  aSortBookOutcome = a.outcomes[1];
                if (b.outcomes[0].book === evsb)
                  bSortBookOutcome = b.outcomes[0];
                if (b.outcomes[1].book === evsb)
                  bSortBookOutcome = b.outcomes[1];
                if (!aSortBookOutcome && !bSortBookOutcome) return 0;
                if (aSortBookOutcome && !bSortBookOutcome) return -1;
                if (!aSortBookOutcome && bSortBookOutcome) return 1;
                return (
                  bSortBookOutcome.expectedValue -
                  aSortBookOutcome.expectedValue
                );
              }
            });
          }
        }
      } else {
        for (let i = 0; i < markets.length; i++) {
          let market = markets[i];
          if (
            options.freeValueBook === "any" ||
            options.freeValueBook === market.book
          ) {
            const { outcomes } = market;
            const freeValueBook =
              options.freeValueBook === "any"
                ? market.book
                : options.freeValueBook;
            const freeValueOutcome = outcomes.find(
              (outcome) => outcome.book === freeValueBook
            );
            const hedgeOutcome = outcomes.find(
              (outcome) => outcome.book !== freeValueBook
            );

            let hedgeValue;
            const freeValueFractionalOdds = freeValueOutcome.odds - 1; // convert to fractional odds
            if (options.freeValueReturnedAsFreeBet) {
              const hedgeFractionalOdds = hedgeOutcome.odds - 1; // convert to fractional odds
              const expectedConversionDeciaml =
                options.expectedFreeBetConversion / 100;
              hedgeValue =
                (options.stake *
                  (-expectedConversionDeciaml + freeValueFractionalOdds + 1)) /
                (hedgeFractionalOdds + 1);
            } else {
              hedgeValue =
                (options.stake * (freeValueOutcome.odds - 1)) /
                hedgeOutcome.odds;
            }

            const profitIfFreeValueWin =
              options.stake * freeValueFractionalOdds - hedgeValue;
            const profitPercent = profitIfFreeValueWin / options.stake;

            market.profitNum = profitPercent * 100;
            market.profit = `${market.profitNum.toFixed(2)}`;
            market.profitDollarsNum = options.stake * profitPercent;
            market.profitDollars = market.profitDollarsNum;

            const isBet1FreeValue =
              freeValueOutcome.side === "Home (3 Way)" ||
              freeValueOutcome.side === "Home or Draw" ||
              freeValueOutcome.side === "Home or Away" ||
              freeValueOutcome.side === "Home" ||
              freeValueOutcome.side === "Under" ||
              freeValueOutcome.side === "No";
            market.bet0 = isBet1FreeValue ? options.stake : hedgeValue;
            market.bet1 = isBet1FreeValue ? hedgeValue : options.stake;

            tmpMarkets.push(market);
          }
        }
      }

      const finalMarkets = [];
      tmpMarkets.forEach((tmpMarket) => {
        const { participants } = tmpMarket.event;
        let shouldIncludeMarket = true;
        for (let i = 0; i < hiddenMarkets.length; i++) {
          const hiddenMarket = hiddenMarkets[i];
          if (
            hiddenMarket.participants[0] === participants[0] &&
            hiddenMarket.participants[1] === participants[1]
          ) {
            if (hiddenMarket.marketTypeId) {
              if (hiddenMarket.marketTypeId === tmpMarket.type) {
                shouldIncludeMarket = false;
                break;
              }
            } else {
              shouldIncludeMarket = false;
              break;
            }
          }
        }
        if (shouldIncludeMarket) {
          finalMarkets.push(tmpMarket);
        }
      });

      setMarkets(tmpMarkets);
      setDisplayedMarkets(finalMarkets);
    }

    // eslint-disable-next-line
  }, [
    options.stake,
    options.stakeType,
    options.stakeBook,
    options.freeValueReturnedAsFreeBet,
    options.expectedFreeBetConversion,
    options.showExpectedValue,
    options.sortByExpectedValue,
    options.sortOrder,
    options.expectedValueSortBook,
    hiddenMarkets,
  ]);

  const list = useMemo(() => {
    let tempList;
    if (!displayedMarkets) {
      tempList = (
        <div className={styles.nothingMessage}>
          {live
            ? "HIT THE REFRESH BUTTON TO GET STARTED"
            : "HIT THE SEARCH BUTTON TO GET STARTED"}
        </div>
      );
    } else if (displayedMarkets.length === 0) {
      tempList = <div className={styles.nothingMessage}>NOTHING RIGHT NOW</div>;
    } else {
      const alert1Available = options.alertEnabled && options.alertThreshold;
      const alert2Available = options.alert2Enabled && options.alert2Threshold;
      tempList = displayedMarkets.map((market, i) => {
        const formattedDollars = market.profitDollars
          ? usdFormatter.format(market.profitDollars)
          : "$-.--";
        const formattedPercent = `${market.profit}%`;
        const largeNumner =
          options.betType === "arbitrage" ? formattedPercent : formattedDollars;
        const smallNumber =
          options.betType === "arbitrage" ? formattedDollars : formattedPercent;

        let onPin, onHide, onAlert;
        if (live) {
          onPin = (participants, marketTypeId, friendlyName) => {
            setPinnedMarket({
              participants,
              marketTypeId,
              friendlyName,
            });
          };
          onHide = (participants, marketTypeId, friendlyName) => {
            setHiddenMarkets((oldHiddenMarkets) => [
              ...oldHiddenMarkets,
              {
                participants,
                marketTypeId,
                friendlyName,
              },
            ]);
          };
          onAlert = (liveAlertData) => {
            dispatch(setData(liveAlertData));
            dispatch(setCurrentlyVisible("LiveAlert"));
          };
        }

        let highlightColor;
        if (
          live &&
          alert2Available &&
          parseFloat(market.profit) >= parseFloat(options.alert2Threshold)
        ) {
          highlightColor = "#1da57a66";
        } else if (
          live &&
          alert1Available &&
          parseFloat(market.profit) >= parseFloat(options.alertThreshold)
        ) {
          highlightColor = "#1da57a22";
        }

        return (
          <Market
            live={live}
            {...market}
            largeNumber={largeNumner}
            smallNumber={smallNumber}
            highlightColor={highlightColor}
            key={`${market.profit}-${market.event.participants[0]}-${market.event.participants[1]}-${i}`}
            betType={options.betType}
            onAlert={onAlert}
            onHide={onHide}
            onPin={onPin}
            showExpectedValue={options.showExpectedValue}
            showOtherOdds={options.showOtherOdds}
          />
        );
      });
    }

    return tempList;
    // eslint-disable-next-line
  }, [displayedMarkets, live]);

  const pinnedList = useMemo(() => {
    if (!pinnedMarket) {
      return (
        <div>
          Click the <PushpinOutlined /> on the market to pin a game or market.
        </div>
      );
    }

    let filteredMarkets = [];
    if (markets) {
      markets.forEach((market) => {
        const marketParticipants = market.event.participants;
        const pinnedParticipants = pinnedMarket.participants;
        if (
          marketParticipants[0] === pinnedParticipants[0] &&
          marketParticipants[1] === pinnedParticipants[1]
        ) {
          if (
            pinnedMarket.marketTypeId &&
            pinnedMarket.marketTypeId === market.typeData.id
          ) {
            filteredMarkets.push(market);
          }
          if (!pinnedMarket.marketTypeId) {
            filteredMarkets.push(market);
          }
        }
      });
    }

    let elementList = filteredMarkets.map((filteredMarket, i) => {
      const formattedDollars = filteredMarket.profitDollars
        ? usdFormatter.format(filteredMarket.profitDollars)
        : "$-.--";
      const formattedPercent = `${filteredMarket.profit}%`;
      const largeNumner =
        options.betType === "arbitrage" ? formattedPercent : formattedDollars;
      const smallNumber =
        options.betType === "arbitrage" ? formattedDollars : formattedPercent;
      return (
        <Market
          {...filteredMarket}
          largeNumber={largeNumner}
          smallNumber={smallNumber}
          key={`pinned-${i}`}
          showExpectedValue={options.showExpectedValue}
          showOtherOdds="none"
        />
      );
    });

    return (
      <div>
        <div className={styles.pinnedTitle}>
          {pinnedMarket.participants[0]} @ {pinnedMarket.participants[1]}
        </div>
        {pinnedMarket.marketTypeId && (
          <div className={styles.pinnedMarket}>{pinnedMarket.friendlyName}</div>
        )}
        <div className={styles.pinnedListContainer}>{elementList}</div>
      </div>
    );
  }, [markets, pinnedMarket, options.betType, options.showExpectedValue]);

  const hiddenList = useMemo(() => {
    if (!hiddenMarkets || hiddenMarkets.length === 0) {
      return (
        <div>
          Click the <EyeInvisibleOutlined /> on the market to hide a game or
          market.
        </div>
      );
    }

    let elementList = hiddenMarkets.map((hiddenMarket, i) => {
      return (
        <div
          key={`${hiddenMarket.participants[0]}-${hiddenMarket.participants[1]}-${hiddenMarket.marketTypeId}`}
          className={styles.hiddenListItem}
        >
          <div>
            <div className={styles.pinnedTitle}>
              {hiddenMarket.participants[0]} @ {hiddenMarket.participants[1]}
            </div>
            {hiddenMarket.marketTypeId && (
              <div className={styles.pinnedMarket}>
                {hiddenMarket.friendlyName}
              </div>
            )}
          </div>
          <div className={styles.hiddenListItemShowIconContainer}>
            <EyeOutlined
              onClick={() => {
                setHiddenMarkets((oldHiddenMarkets) => [
                  ...oldHiddenMarkets.slice(0, i),
                  ...oldHiddenMarkets.slice(i + 1),
                ]);
              }}
            />
          </div>
        </div>
      );
    });

    return <div>{elementList}</div>;
  }, [hiddenMarkets]);

  const secondaryLists = [
    {
      label: (
        <div className={styles.tabIconContainer}>
          <PushpinOutlined />
        </div>
      ),
      key: "pins",
      children: pinnedList,
    },
    {
      label: (
        <div className={styles.tabIconContainer}>
          <EyeInvisibleOutlined />
        </div>
      ),
      key: "hidden",
      children: hiddenList,
    },
  ];

  return (
    <>
      <div
        className={styles.container}
        style={{
          background: themeTokens.colorBgContainer,
          color: themeTokens.colorText,
        }}
      >
        <div className={styles.primaryList}>
          <ToolOptions
            onChange={setOptions}
            onRefresh={fetchMarkets}
            ref={optionsElement}
            showAutoRefreshOptions={live}
            initialOptions={{ books: user.books, freeValueBook: "any" }}
            live={live}
          />
          {list}
        </div>
        {live && (
          <>
            <div
              className={styles.divider}
              style={{ background: themeTokens.colorBgLayout }}
            />
            <div className={styles.secondaryList}>
              <Tabs size="small" type="card" items={secondaryLists} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BetFinder;
