import { configureStore } from "@reduxjs/toolkit";
import modals from "./ducks/modals";
import user from "./ducks/user";
import marketTypes from "./ducks/marketTypes";
import liveAlert from "./ducks/liveAlert";
import theme from "./ducks/theme";
import oneSignal from "./ducks/oneSignal";

export default configureStore({
  reducer: { modals, user, marketTypes, liveAlert, theme, oneSignal },
});
