import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Spin } from "antd";
import {
  PauseOutlined,
  CaretRightOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useKeyPress, useTheme } from "../../../hooks";
import styles from "./TimerButtonControls.module.less";
import rImage from "./r.png";
import pImage from "./p.png";
import rDark from "./r_dark.png";
import pDark from "./p_dark.png";

const TimerButtonControls = (
  {
    resetKey = 345877834,
    onComplete = () => {},
    duration = 10,
    isRefreshing = false,
    isPlaying = false,
    onRefreshClick = () => {},
    onPlayChange = () => {},
    autoRefreshEnabled = false,
  } = {
    resetKey: 345877834,
    onComplete: () => {},
    duration: 10,
    isRefreshing: false,
    isPlaying: false,
    onRefreshClick: () => {},
    onPlayChange: () => {},
    autoRefreshEnabled: false,
  }
) => {
  const [key, setKey] = useState(resetKey);
  const pPress = useKeyPress("p");
  const rPress = useKeyPress("r");
  const isDarkTheme = useSelector((state) => state.theme.isDark);
  const themeTokens = useTheme();

  useEffect(() => {
    setKey(resetKey);
  }, [resetKey]);

  useEffect(() => {
    if (pPress) onPlayChange();
  }, [pPress, onPlayChange]);

  useEffect(() => {
    if (!isRefreshing && rPress) {
      setKey(Math.floor(Math.random() * 10000));
      onRefreshClick();
    }
  }, [rPress, isRefreshing, onRefreshClick]);

  return (
    <div className={styles.container}>
      {autoRefreshEnabled && (
        <>
          <div>
            <CountdownCircleTimer
              key={key}
              isPlaying={isPlaying}
              duration={duration}
              size={32}
              strokeWidth={2}
              trailStrokeWidth={5}
              trailColor={themeTokens.colorBgContainerDisabled}
              colors={[themeTokens.colorPrimary]}
              onComplete={() => {
                onComplete();
                return { shouldRepeat: false };
              }}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </div>
          <div className={styles.playPauseButton}>
            <Button
              icon={
                !isRefreshing && isPlaying ? (
                  <PauseOutlined
                    style={{ fontSize: "1rem", paddingTop: "0.225rem" }}
                  />
                ) : (
                  <CaretRightOutlined
                    style={{ fontSize: "1rem", paddingTop: "0.225rem" }}
                  />
                )
              }
              disabled={isRefreshing}
              onClick={onPlayChange}
              style={{
                backgroundImage: `url("${isDarkTheme ? pDark : pImage}")`,
                backgroundSize: "contain",
              }}
            />
          </div>
        </>
      )}
      <div className={styles.playPauseButton}>
        <Button
          icon={
            <Spin
              size="medium"
              indicator={
                <RedoOutlined
                  spin={isRefreshing}
                  style={{ color: themeTokens.colorText }}
                />
              }
            />
          }
          disabled={isRefreshing}
          onClick={() => {
            setKey(Math.floor(Math.random() * 10000));
            onRefreshClick();
          }}
          style={{
            backgroundImage: `url("${isDarkTheme ? rDark : rImage}")`,
            backgroundSize: "contain",
            paddingTop: "0.3rem",
          }}
        />
      </div>
    </div>
  );
};

export default TimerButtonControls;
