import React, { useState, useEffect, useMemo } from "react";
import { Spin, Typography, Divider } from "antd";
import { useSearchParams } from "react-router-dom-v5-compat";
import Market from "../../components/Market/Market";
import { getLiveNotificationPlays } from "../../../api";

const { Text } = Typography;

const NOTHING_MESSAGES = ["YOU MISSED IT", "TOO SLOW", "SO CLOSE"];

const LiveAlertsPage = () => {
  const [searchParams] = useSearchParams();
  const [liveNotificationPlays, setLiveNotificationPlays] = useState();

  useEffect(() => {
    async function fetchData() {
      const { data } = await getLiveNotificationPlays();
      setLiveNotificationPlays(data.plays);
    }
    fetchData();
  }, []);

  const filterSettings = useMemo(() => {
    const settingsString = searchParams.get("settings");
    const decodedSettings = atob(settingsString);
    return JSON.parse(decodedSettings);
  }, [searchParams]);

  const list = useMemo(() => {
    if (!liveNotificationPlays) {
      return (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      );
    } else {
      const aboveTheLinePlays = [];
      const belowTheLinePlays = [];
      const now = new Date();
      const currentMillis = now.getTime();
      liveNotificationPlays.forEach((liveNotificationPlay) => {
        const { profitNumber, firstApperanceTime, outcomes } =
          liveNotificationPlay;
        const maxFirstApperanceTime = currentMillis - filterSettings.ttl * 1000;
        const isOldEnough = firstApperanceTime < maxFirstApperanceTime;
        const isPofitableEnough = profitNumber >= filterSettings.minArbPercent;
        const isWithSettingBooks =
          filterSettings.books.includes(outcomes[0].book) &&
          filterSettings.books.includes(outcomes[1].book);
        if (isOldEnough && isWithSettingBooks) {
          if (isPofitableEnough) {
            aboveTheLinePlays.push(liveNotificationPlay);
          } else {
            belowTheLinePlays.push(liveNotificationPlay);
          }
        }
      });
      if (aboveTheLinePlays.length === 0 && belowTheLinePlays.length === 0) {
        return (
          <div style={{ textAlign: "center" }}>
            <Text type="secondary">
              {
                NOTHING_MESSAGES[
                  Math.floor(Math.random() * NOTHING_MESSAGES.length)
                ]
              }
            </Text>
          </div>
        );
      } else {
        const aboveTheLineList = aboveTheLinePlays.map((market, i) => {
          // const formattedDollars = market.profitDollars
          //   ? usdFormatter.format(market.profitDollars)
          //   : "$-.--";
          const formattedPercent = `${market.profit}%`;
          const largeNumner = formattedPercent;

          return (
            <Market
              {...market}
              largeNumber={largeNumner}
              key={`${market.profit}-${market.event.participants[0]}-${market.event.participants[1]}-${i}`}
              betType={"arbitrage"}
            />
          );
        });
        const belowTheLineList = belowTheLinePlays.map((market, i) => {
          // const formattedDollars = market.profitDollars
          //   ? usdFormatter.format(market.profitDollars)
          //   : "$-.--";
          const formattedPercent = `${market.profit}%`;
          const largeNumner = formattedPercent;

          return (
            <Market
              {...market}
              largeNumber={largeNumner}
              key={`${market.profit}-${market.event.participants[0]}-${market.event.participants[1]}-${i}`}
              betType={"arbitrage"}
            />
          );
        });

        return (
          <>
            {aboveTheLineList}
            <div style={{ opacity: 0.75 }}>
              <Divider style={{ opacity: 0.75 }} cd orientation="left" plain>
                Below the Line
              </Divider>
              {belowTheLineList}
            </div>
          </>
        );
      }
    }
  }, [liveNotificationPlays, filterSettings]);

  return <div style={{ padding: "1rem 0" }}>{list}</div>;
};

export default LiveAlertsPage;
