import React, { useEffect, useState, useCallback } from "react";
import { getPromos } from "../../../api";

const Promos = () => {
  const [promos, setPromos] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");

  console.log(promos, loadingState);

  const fetchPromos = useCallback(async () => {
    setLoadingState("loading");
    try {
      const promos = await getPromos();
      setPromos(promos);
      setLoadingState("succeeded");
    } catch (err) {
      setLoadingState("failed");
    }
  }, []);

  useEffect(() => {
    fetchPromos();
  }, [fetchPromos]);

  return <div>PROMOS</div>;
};

export default Promos;
