import { getImageDimensions } from "@sanity/asset-utils";
import { Image } from "antd";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../../../services/sanity.js";
import React from "react";

// builder is used to create the URL for a given block image from a post
const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}
const ImageComponent = ({ value, isInline }) => {
  const { width, height } = getImageDimensions(value);
  return (
    <Image
      src={urlFor(value)}
      alt={value.alt || " "}
      loading="lazy"
      style={{
        // Display alongside text if image appears inside a block text span
        display: isInline ? "inline-block" : "block",

        // Avoid jumping around with aspect-ratio CSS property
        aspectRatio: width / height,
      }}
    />
  );
};
export default ImageComponent;
