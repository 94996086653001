import React, { useState, useEffect } from "react";
import { Button, Spin, Input, Alert, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Countdown from "react-countdown";
import { getAuction, getWaitlistDate, updateBid } from "../../../api";

const Auction = () => {
  const [auction, setAuction] = useState({});
  const [email, setEmail] = useState();
  const [auctionState, setAuctionState] = useState("INITIAL");
  const [bidder, setBidder] = useState();
  const [bidderState, setBidderState] = useState({ state: "INITIAL" });
  const [hasAuctionEnded, setHasAuctionEnded] = useState();
  const [newBidAmount, setNewBidAmount] = useState();
  const [updateBidState, setUpdateBidState] = useState({ state: "INITIAL" });

  useEffect(() => {
    const fetchAuction = async () => {
      try {
        setAuctionState("LOADING");
        let fetchedAuction = await getAuction();
        setAuction(fetchedAuction);
        setHasAuctionEnded(Date.parse(fetchedAuction.closeTime) < new Date());
        setAuctionState("SUCCESS");
      } catch (err) {
        setAuctionState("FAILURE");
      }
    };
    fetchAuction();
  }, []);

  if (["INITIAL", "LOADING"].includes(auctionState)) {
    return (
      <div style={{ textAlign: "center", paddingTop: "5rem" }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </div>
    );
  }

  if (auctionState === "FAILURE") {
    return (
      <div style={{ textAlign: "center", paddingTop: "2rem" }}>
        <Alert
          message="Ooops, something went wrong. Our team is looking into it. You can try again or come back later."
          type="error"
        />
      </div>
    );
  }

  const onFirstSubmit = async () => {
    setBidderState({ state: "LOADING" });
    try {
      setBidder(await getWaitlistDate(email));
      setBidderState({ state: "SUCCESS" });
    } catch (err) {
      console.error(err.toJSON());
      if (err.message === "Request failed with status code 404") {
        setBidderState({
          state: "FAILURE",
          message:
            "The email you entered was not found. Please reach out to us to make sure you are on the wait list.",
        });
      } else {
        setBidderState({
          state: "FAILURE",
          message: "An unkown error occured, please try again later.",
        });
      }
    }
  };

  let title;
  if (auction.isOpen) {
    if (hasAuctionEnded) {
      title = "Auction Has Ended";
    } else {
      title = "Auction Is Open";
    }
  } else {
    title = "No Current Auction";
  }

  const onPlaceBid = async (amount) => {
    setUpdateBidState({ state: "LOADING" });
    try {
      await updateBid({ email, amount });
      setBidder(await getWaitlistDate(email));
    } catch (err) {
      console.error(err);
      setUpdateBidState({ state: "FAILURE" });
    }
    setUpdateBidState({ state: "SUCCESS" });
  };

  return (
    <div style={{ paddingTop: "1rem", textAlign: "center" }}>
      <div
        style={{
          fontSize: 20,
          fontWeight: 500,
          paddingBottom: "0.5rem",
          color: "#4e4e4e",
        }}
      >
        {title}
      </div>
      {auction.isOpen && !hasAuctionEnded && (
        <div style={{ paddingBottom: "1rem" }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#4e4e4e",
            }}
          >
            Auction ends in
          </div>
          <Countdown
            date={Date.parse(auction.closeTime)}
            onComplete={() => setHasAuctionEnded(true)}
            renderer={({ days, hours, minutes, seconds }) => {
              const daysPlaural = days > 1 || days === 0;
              const hoursPlural = hours > 1 || hours === 0;
              if (days > 0)
                return `${days} day${daysPlaural ? "s" : ""} ${hours} hour${
                  hoursPlural ? "s" : ""
                } ${minutes} minutes ${seconds} seconds`;
              if (hours > 0)
                return `${hours} hour${
                  hoursPlural ? "s" : ""
                } ${minutes} minutes ${seconds} seconds`;
              if (minutes > 0) return `${minutes} minutes ${seconds} seconds`;
              return `${seconds} seconds`;
            }}
          />
        </div>
      )}
      {bidderState.state !== "SUCCESS" && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: 500,
              paddingBottom: "0.5rem",
              color: "#4e4e4e",
            }}
          >
            {auction.isOpen ? "Enter Auction" : "Check Guaranteed Date"}
          </div>
          <Input
            style={{ width: 250 }}
            placeholder="Email"
            onChange={({ target }) => setEmail(target.value)}
            onPressEnter={onFirstSubmit}
          />
          {bidderState.state === "FAILURE" && (
            <div
              style={{
                padding: "0.25rem",
                color: "red",
                textAlign: "center",
              }}
            >
              <div style={{ width: 250, margin: "auto" }}>
                {bidderState.message}
              </div>
            </div>
          )}
          <div style={{ paddingTop: "0.5rem" }}>
            <Button
              type="primary"
              onClick={onFirstSubmit}
              loading={bidderState.state === "LOADING"}
            >
              {auction.isOpen ? "Enter Auction" : "Check Date"}
            </Button>
          </div>
        </div>
      )}
      {bidderState.state === "SUCCESS" && auction.isOpen === false && (
        <div>
          <div
            style={{
              fontSize: 16,
              fontWeight: 500,
              paddingTop: "1.5rem",
              color: "#4e4e4e",
            }}
          >
            Your Guaranteed Date
          </div>
          <div
            style={{
              fontSize: 30,
              fontWeight: 500,
              color: "#1da57a",
            }}
          >
            {bidder.date}
          </div>
        </div>
      )}
      {bidderState.state === "SUCCESS" && auction.isOpen === true && (
        <div
          style={{
            border: "1px solid #aaa",
            borderRadius: 5,
            maxWidth: 500,
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div style={{ padding: "1rem" }}>
              <div
                style={{
                  fontSize: 16,
                  color: "#4e4e4e",
                }}
              >
                Your Guaranteed Date
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1da57a",
                }}
              >
                {bidder.date}
              </div>
            </div>
            <div style={{ padding: "1rem" }}>
              <div
                style={{
                  fontSize: 16,
                  color: "#4e4e4e",
                }}
              >
                Eligible Bidders
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {bidder.sizeOfWaitlist}
              </div>
            </div>
            <div style={{ padding: "1rem" }}>
              <div
                style={{
                  fontSize: 16,
                  color: "#4e4e4e",
                }}
              >
                Your {hasAuctionEnded ? "Final" : "Current"} Bid
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1da57a",
                }}
              >
                ${bidder.currentBidAmount || 0}
              </div>
            </div>
          </div>
          {!hasAuctionEnded && (
            <div>
              <div>
                <InputNumber
                  style={{ width: 140 }}
                  addonBefore={"$"}
                  min={0}
                  placeholder="New Bid"
                  value={newBidAmount}
                  stringMode
                  onChange={(value) => {
                    const number = parseFloat(value);
                    setNewBidAmount(Math.floor(number));
                  }}
                  onPressEnter={() => {
                    onPlaceBid(newBidAmount);
                  }}
                />
                <div style={{ margin: "0.5rem 0" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      onPlaceBid(newBidAmount);
                    }}
                    loading={updateBidState.state === "LOADING"}
                  >
                    Update Bid
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Auction;
