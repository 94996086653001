import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: undefined,
};

const liveAlertSlice = createSlice({
  name: "liveAlert",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
  },
});

const { actions, reducer } = liveAlertSlice;
export const { setData } = actions;
export default reducer;
