import React, { useState, useEffect } from "react";
import { Spin, Typography } from "antd";
import { getCZRBaseballScam } from "../../../api";

const { Paragraph } = Typography;

const Explanation = () => {
  return (
    <div>
      <Paragraph>
        Caesar's grades MLB player markets if a player sees the field at any
        point (pinch hits, defensive sub, pinch run, etc). Other books do the
        same but only offer "over" markets. Caesar's has made the midtake of
        also offering "under" markets on players.
      </Paragraph>
      <Paragraph>
        Below is a list of players for which under markets are offered that{" "}
        <a href="https://www.rotowire.com/baseball/daily-lineups.php">
          Rotowire
        </a>{" "}
        is either projecting to not start or has confirmed is not starting. The
        idea is to bet the unders on these players and hope they pinch hit. The
        bets are not sure things but you can see how the unders bets become very
        EV+ if a guy gets 1 or even 0 at bats.
      </Paragraph>
      <Paragraph>
        Rotowire's projections are a little hit or miss so tread carefully on
        the projections but their confirmed lineups have been pretty spot on.
        Also maybe double check because this tool could be mistaken if Rotowire
        has a different spelling for a player's name than Caesar's causing this
        tool to be incorrect.
      </Paragraph>
    </div>
  );
};

const CaesarsBaseballUnders = () => {
  const [players, setPlayers] = useState();

  useEffect(() => {
    const fetchPlayers = async () => {
      const fetchedPlayers = await getCZRBaseballScam();
      setPlayers(fetchedPlayers);
    };
    fetchPlayers();
  }, []);

  if (!players) {
    return (
      <div style={{ paddingTop: "1rem" }}>
        <Explanation />
        <div style={{ paddingTop: "1rem" }}>
          <Spin />
        </div>
      </div>
    );
  }

  if (players.length === 0) {
    return (
      <div style={{ paddingTop: "1rem" }}>
        <Explanation />
        <div style={{ paddingTop: "1rem" }}>NONE RIGHT NOW</div>
      </div>
    );
  }
  return (
    <div style={{ paddingTop: "1rem" }}>
      <Explanation />
      <div style={{ paddingTop: "1rem" }}>
        {players.map((player) => {
          return (
            <div
              key={player.player}
              style={{ border: "1px solid gray", padding: "0.5rem" }}
            >
              <div>
                <span style={{ fontWeight: "700" }}>{player.player}</span>
                <span style={{ color: "gray" }}>
                  {" - "}
                  {player.status}
                </span>
              </div>
              <div
                style={{ color: "gray" }}
              >{`${player.event.participants[0]} @ ${player.event.participants[1]}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CaesarsBaseballUnders;
