import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Button, Modal, Checkbox } from "antd";
import config from "../../../config";
import discordLogo from "./discord_logo_wordmark_color.svg";
import getToken from "../../../utils/getToken";

const Discord = () => {
  const auth0 = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [inviteURL, setInviteURL] = useState();
  const [understandsThirtySeconds, setUnderstandsThirtySeconds] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = useSelector((state) => state.user.user);

  const onClickJoinServer = async () => {
    try {
      setInviteURL(undefined);
      setError(undefined);
      setIsLoading(true);
      const token = await getToken({ auth0 });
      const response = await axios.get(
        `${config.apiBaseDomain}/discordInvite`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      window.open(response.data.inviteURL, "_blank");
      setIsLoading(false);
      setInviteURL(response.data.inviteURL);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.waitUntil) {
        const dif =
          (new Date(err.response.data.waitUntil).getTime() -
            new Date().getTime()) /
          1000;
        setError(`Wait ${dif} seconds to request another invite`);
      } else {
        setError(
          "There was an issue logining requesting your invite. Please try again later or reach out to support."
        );
      }
      setIsLoading(false);
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "1rem" }}>
      <div style={{ padding: "1rem" }}>
        <img src={discordLogo} alt="Discord Logo" width={250} />
        {!user.discordUserId ||
          (user.discordUserId === "NONE" && (
            <div>
              <Typography.Paragraph>
                Join our private Discord server to reveive alerts on the biggest
                arbs and chat with us and other members about all things Bookie
                Beats.{" "}
                <b>
                  Due to technical contraints you will only have 30 seconds to
                  accept the invite. Be sure that you have created a Discord
                  account and have logged in before requesting an invite. You
                  will need to come back to this page and attempt to join again
                  if you take too long.
                </b>
              </Typography.Paragraph>
              <div style={{ marginBottom: "1rem" }}>
                <Checkbox
                  onChange={(e) => {
                    setUnderstandsThirtySeconds(e.target.checked);
                  }}
                  checked={understandsThirtySeconds}
                >
                  I understand I need to have created a Discord account, be
                  logged in, and have 30 seconds to accept the invite
                </Checkbox>
              </div>
              <Button
                style={{
                  borderColor: understandsThirtySeconds
                    ? "#7289DA"
                    : "lightgray",
                  backgroundColor: understandsThirtySeconds
                    ? "#7289DA"
                    : "lightgray",
                  color: "white",
                  fontWeight: 500,
                }}
                loading={isLoading}
                onClick={onClickJoinServer}
                disabled={!understandsThirtySeconds}
              >
                Request Invite
              </Button>
              {inviteURL && (
                <div style={{ marginTop: "0.5rem" }}>
                  If a Discord window not not automatically open visit open this
                  link manually{" "}
                  <a href={inviteURL} target="_blank" rel="noopener noreferrer">
                    {inviteURL}
                  </a>
                </div>
              )}
              {error && (
                <div style={{ marginTop: "0.5rem", color: "red" }}>{error}</div>
              )}
              <div style={{ marginTop: "1.5rem" }}>Already Joined?</div>
              <Button
                onClick={() => {
                  window.open(
                    "https://discord.com/channels/837763969182597130",
                    "_blank"
                  );
                }}
              >
                Open Server
              </Button>
            </div>
          ))}
        {user.discordUserId && user.discordUserId !== "NONE" && (
          <div>
            <Typography.Paragraph>
              We have a private Discord server where you can get alerts for the
              biggest arbs and chat other members about all things Bookie Beats.
              Looks like you have already joined.
            </Typography.Paragraph>
            <Button
              style={{
                borderColor: "#7289DA",
                backgroundColor: "#7289DA",
                color: "white",
                fontWeight: 500,
                marginBottom: "1.5rem",
              }}
              onClick={() => {
                window.open(
                  "https://discord.com/channels/837763969182597130",
                  "_blank"
                );
              }}
            >
              Open Server
            </Button>
            <Typography.Paragraph style={{ color: "gray", fontSize: "0.5rem" }}>
              If you left the server and want to be readded you will need to
              reach out to us at support@bookiebeats.com.
            </Typography.Paragraph>
          </div>
        )}
      </div>
      <Modal
        title="Sorry, gotta wait a bit"
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => setIsModalVisible(false)}
          >
            OK
          </Button>,
        ]}
      >
        Due to even more technical contraints we can only have one invite
        created every minute (there might be another user currently joining the
        server). Wait a minute and try again.
      </Modal>
    </div>
  );
};

export default Discord;
