import { Collapse } from "antd";
import React from "react";

import { PortableText } from "@portabletext/react";

import YouTube from "react-youtube";
import getYouTubeId from "get-youtube-id";
import { Typography } from "antd";

import table from "./Table.component";
import collapseComponent from "./Collapse.component";
import ImageComponent from "./Image.component";
const { Title, Text } = Typography;

const { Panel } = Collapse;
// function called when rendering the article body via PortableText
// add on new types as needed such as tables, collapsable, etc.
const serializers = {
  types: {
    image: ImageComponent,
    youtube: ({ value }) => {
      const { url } = value;
      const id = getYouTubeId(url);

      return <YouTube videoId={id} />;
    },
    table: table,
    collapse: collapseComponent,
  },

  marks: {
    //renders internal links as  urls in the webpage
    internalLink: ({ value, children }) => {
      console.log(children);
      const { slug = {} } = value;
      console.log(slug);
      const href = `/learn/${slug.current}`;
      return <a href={href}>{children}</a>;
    },
  },
  block: {
    h1: ({ value }) => {
      return <Title>{value.children[0].text} </Title>;
    },
    h2: ({ value }) => {
      return <Title level={2}>{value.children[0].text} </Title>;
    },
    h3: ({ value }) => {
      return <Title level={3}>{value.children[0].text} </Title>;
    },
    h4: ({ value }) => {
      return <Title level={4}>{value.children[0].text} </Title>;
    },
    h5: ({ value }) => {
      return <Title level={5}>{value.children[0].text} </Title>;
    },
    blockquote: ({ value }) => {
      return (
        <Text code italic>
          {value.children[0].text}{" "}
        </Text>
      );
    },
  },
};

const CollapseComponent = ({ value }) => {
  return (
    <Collapse ghost>
      {value.collapseDetails.map((details) => {
        return (
          <Panel header={<b>{details.header}</b>} key={details.header}>
            <PortableText value={details.body} components={serializers} />
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default CollapseComponent;
