import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentlyVisible: undefined,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setCurrentlyVisible(state, action) {
      state.currentlyVisible = action.payload;
    },
    close(state) {
      state.currentlyVisible = undefined;
    },
  },
});

const { actions, reducer } = modalsSlice;
export const { close, setCurrentlyVisible } = actions;
export default reducer;
