import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styles from "./FreeDingers.module.less";
import { Button, Modal, Typography, Input, Select } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { getLowHoldPlays } from "../../../api";
import Market from "../../components/Market/Market";
import config from "../../../config";

const { Title, Paragraph } = Typography;

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const IS_LIVE = true;

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const SignUpModal = ({
  isMoreModalOpen,
  setIsMoreModalOpen,
  inputEl,
  signUpMessage,
  signUpError,
  setSignUpError,
  setIsSignUpLoading,
  setSignUpMessage,
  isSignUpLoading,
}) => (
  <Modal
    open={isMoreModalOpen}
    onCancel={() => setIsMoreModalOpen(false)}
    footer={null}
  >
    <div className={styles.subscriptionContainer}>
      <Title level={4}>
        <span style={{ color: "#1da57a" }}>Seven Day Free Trial</span>
      </Title>
      <div className={styles.priceContainer}>
        then
        <span className={styles.price}>$125</span>
        <span className={styles.pricePeriod}>/month</span>
      </div>
      <div className={styles.featuresContainer}>
        <div className={styles.feature}>
          <CheckCircleOutlined /> Arbitrage, low hold, and free value
          conversions for all of our supported books
        </div>
        <div className={styles.feature}>
          <CheckCircleOutlined /> Exclusive content to help you make the most
          money from promotions
        </div>
        <div className={styles.feature}>
          <CheckCircleOutlined /> Access to our private Discord server with
          alerts for the best arbs
        </div>
      </div>
      <div style={{ paddingTop: "1rem" }}>
        <div>Enter email to get started</div>
        <div style={{ padding: "0.5rem" }}>
          <Input
            size="large"
            placeholder="Email"
            style={{ maxWidth: 400 }}
            ref={inputEl}
            type="email"
            disabled={!!signUpMessage}
          />
        </div>
        {signUpError && (
          <div style={{ color: "red", paddingBottom: "0.5rem" }}>
            {signUpError}
          </div>
        )}
        {signUpMessage && (
          <div style={{ color: "#1da57a", paddingBottom: "0.5rem" }}>
            {signUpMessage}
          </div>
        )}
        {!signUpMessage && (
          <Button
            type="primary"
            onClick={async () => {
              setSignUpError(null);
              const email = inputEl.current.state.value;
              if (!email) {
                setSignUpError("Please enter your email");
              } else {
                if (validateEmail(email)) {
                  try {
                    setIsSignUpLoading(true);
                    await axios.post(
                      `${config.apiBaseDomain}/customer-invites`,
                      { email },
                      { headers: { Authorization: `Bearer DTPROMO` } }
                    );
                    setSignUpMessage(
                      "Check your inbox to continue account setup. Check your spam folder if you don't see it."
                    );
                  } catch (err) {
                    setSignUpError(
                      "An issue occured. Try again or come back later."
                    );
                  }
                  setIsSignUpLoading(false);
                } else {
                  setSignUpError("Please enter a valid email");
                }
              }
            }}
            loading={isSignUpLoading}
          >
            Start Free Trial
          </Button>
        )}
      </div>
    </div>
  </Modal>
);

const FreeDingers = () => {
  const [playsStatus, setPlaysStatus] = useState("idle");
  const [plays, setPlays] = useState([]);
  const [games, setGames] = useState({});
  const [selectedGame, setSelectedGame] = useState();
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [signUpMessage, setSignUpMessage] = useState(null);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const inputEl = useRef(null);

  const signUpProps = {
    isMoreModalOpen,
    setIsMoreModalOpen,
    inputEl,
    signUpMessage,
    signUpError,
    setSignUpError,
    setIsSignUpLoading,
    setSignUpMessage,
    isSignUpLoading,
  };

  const dogStake = 25;

  const fetchPlays = async () => {
    setPlaysStatus("loading");
    try {
      const { lowHoldPlays } = await getLowHoldPlays({
        marketType: "player_total_homeruns",
      });
      setPlays(lowHoldPlays);
      setPlaysStatus("succeeded");
    } catch (err) {
      setPlaysStatus("failed");
    }
  };

  useEffect(() => {
    if (IS_LIVE) fetchPlays();
  }, []);

  useEffect(() => {
    let tempGames = {};
    plays.forEach((play) => {
      const homeParticipantSplit = play.event.homeParticipant.split(" ");
      const awayParticipantSplit = play.event.participants[0].split(" ");
      if (
        play.outcomes[0].book !== "williamhill" &&
        play.outcomes[1].book !== "williamhill"
      ) {
        tempGames[play.event.homeParticipant] = `${
          awayParticipantSplit[awayParticipantSplit.length - 1]
        } @ ${homeParticipantSplit[homeParticipantSplit.length - 1]}`;
      }
    });
    setGames(tempGames);
  }, [plays]);

  let filteredPlays = plays.filter((play) => {
    return (
      play.outcomes[0].book !== "williamhill" &&
      play.outcomes[1].book !== "williamhill"
    );
  });

  let list;
  if (playsStatus === "succeeded") {
    if (filteredPlays.length === 0) {
      list = <div>NOTHING RIGHT NOW</div>;
    } else {
      filteredPlays.forEach((play, i) => {
        const { outcomes } = play;
        const outcome0 = outcomes.find(
          (outcome) =>
            outcome.side === "Away" ||
            outcome.side === "Over" ||
            outcome.side === "Yes"
        );
        const outcome1 = outcomes.find(
          (outcome) =>
            outcome.side === "Home" ||
            outcome.side === "Under" ||
            outcome.side === "No"
        );

        let bet0, bet1, isBet1Underdog, profitDollars;
        if (dogStake) {
          const dogOutcome =
            outcome0.odds <= outcome1.odds ? outcome1 : outcome0;
          const favOutcome =
            outcome0.odds < outcome1.odds ? outcome0 : outcome1;
          const dogOdds = dogOutcome.boostedOdds
            ? dogOutcome.boostedOdds
            : dogOutcome.odds;
          const dogArbPercent = (1 / dogOdds) * 100;
          const favArbPercent = (1 / favOutcome.odds) * 100;
          const totalArbPercent = dogArbPercent + favArbPercent;
          const dogPercentStake =
            play.profit !== "0.00"
              ? (100 * dogArbPercent) / play.profit
              : 100 * dogArbPercent;
          const favPercentStake =
            play.profit !== "0.00"
              ? (100 * favArbPercent) / play.profit
              : 100 * favArbPercent;
          const favStake = dogStake * (favPercentStake / dogPercentStake);
          isBet1Underdog =
            dogOutcome.side === "Home" || dogOutcome.side === "Under";
          bet0 = isBet1Underdog ? dogStake : favStake;
          bet1 = isBet1Underdog ? favStake : dogStake;
          const totalStake = dogStake + favStake;
          profitDollars = (
            totalStake / (totalArbPercent / 100) -
            totalStake
          ).toFixed(2);
        }
        filteredPlays[i].bet0 = bet0;
        filteredPlays[i].bet1 = bet1;
        filteredPlays[i].profitDollars = parseFloat(profitDollars);
        filteredPlays[i].isBet1Underdog = isBet1Underdog;
        filteredPlays[i].dogStake = dogStake;
      });

      filteredPlays.sort((a, b) => b.profitDollars - a.profitDollars);

      list = filteredPlays.map((play, i) => {
        if (
          selectedGame &&
          selectedGame !== "all" &&
          play.event.homeParticipant !== selectedGame
        )
          return null;
        return (
          <Market
            {...play}
            largeNumber={
              play.profitDollars
                ? usdFormatter.format(play.profitDollars)
                : "$-.--"
            }
            smallNumber={`${play.profit}%`}
            key={i}
          />
        );
      });
    }
  } else if (playsStatus === "loading") {
    list = (
      <div style={{ textAlign: "center", paddingTop: "3rem" }}>
        <LoadingOutlined style={{ fontSize: 40 }} />
      </div>
    );
  }

  if (IS_LIVE) {
    return (
      <div style={{ paddingTop: "1rem" }}>
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <Button
            type="primary"
            size="large"
            onClick={() => setIsMoreModalOpen(true)}
          >
            Want more?
          </Button>
        </div>
        <Select
          value={selectedGame}
          style={{ width: 240 }}
          onChange={setSelectedGame}
          placeholder="Filter by Game"
        >
          <Select.Option key="all" value="all">
            All Games
          </Select.Option>
          {Object.entries(games).map(([value, display]) => {
            return (
              <Select.Option key={value} value={value}>
                {display}
              </Select.Option>
            );
          })}
        </Select>
        <div style={{ paddingTop: "1rem" }}>{list}</div>
        <SignUpModal {...signUpProps} />
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: "center", paddingTop: "1rem" }}>
        <Title level={2}>Dinger Tuesday is Over :(</Title>
        <Paragraph>
          Come back next week if the promo is still running. In the meantime, if
          you want more click the button below.
        </Paragraph>
        <Button
          type="primary"
          size="large"
          onClick={() => setIsMoreModalOpen(true)}
        >
          Want more?
        </Button>
        <SignUpModal {...signUpProps} />
      </div>
    );
  }
};

export default FreeDingers;
