import React from "react";
import { Typography } from "antd";

const { Paragraph, Title } = Typography;
const BaseballWarning = () => {
  return (
    <div>
      <Title level={5}>Baseball Market Quirks</Title>
      <Paragraph>
        Books treat some baseball markets differently based on if certain
        players start. The most common examples are
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            <b>Starting pitchers:</b> You'll notice when you are betting on PB
            most bets will include the pitchers' names. This means if either
            pitcher doesn't start the bet is voided (and your stake is
            returned). However, on other books this is not the default setting,
            other books will treat the bet as an "Action" bet meaning they will
            keep the bet but may change the odds. <b>Best practice:</b> Bet
            listed pitchers when possible. This is available for all ML bets.
          </li>
          <li>
            <b>Hitters:</b> If you bet on a hitter and they do not start,
            DK/BS/BR will void the bet. If the player pinch hits, FD will honor
            the bet and score it as a loss or win. PB's terms and conditions
            state the player must start for the bet to be counted as action, but
            they commonly make mistakes in scoring these types of bets so you
            may need to contact them. Best practice: We recommend checking that
            a player is in the starting lineup before placing a bet.{" "}
            <a
              href="https://www.mlb.com/starting-lineups"
              target="_blank"
              rel="noopener noreferrer"
            >
              MLB Lineups
            </a>
          </li>
        </ul>
        There are too many books and too many possible scenarios for us to lay
        all of them out (and the books themselves don't do a great job of laying
        them out) so, as always, place the bets at your own risk and contact
        each book’s support team if you have a question.
      </Paragraph>
    </div>
  );
};

export default BaseballWarning;
