import axios from "axios";
import config from "../config";

const API_BASE_DOMAIN = config.apiBaseDomain;
const ODDS_API_BASE_DOMAIN = "https://api.bookiebeats.com/pv1";

const GET_PLAYS_TIMEOUT = 29500;

const base64Object = (o) => {
  const str = JSON.stringify(o);
  return window.btoa(str);
};

const normalizeArray = (arr, key) => {
  let obj = {};
  arr.forEach((e) => {
    if (e[key]) obj[e[key]] = e;
  });
  return obj;
};

const getFreeValuePlays = async ({ books, sport }) => {
  const params = new URLSearchParams({ books, sport });
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v === "undefined") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });
  let url = `${API_BASE_DOMAIN}/freeValuePlays`;
  url += params.toString() ? `?${params.toString()}` : "";
  const response = await axios.get(url);
  return response.data;
};

// middleFilters type: { sportId: string; minMiddle: number; minHold: number; }

const getFreeValuePlaysV2 = async ({
  token,
  marketTypes,
  books,
  sport,
  live,
  league,
  minimumOdds,
  maximumOdds,
  middlesOnly,
  hidePlayerProps,
  onlyPlayerProps,
  includeAllOutcomes,
  oddsRanges,
  mainLineOnlyBooks,
} = {}) => {
  const params = new URLSearchParams({
    marketTypes,
    books,
    sport,
    live,
    league,
    minimumOdds,
    maximumOdds,
    middlesOnly,
    hidePlayerProps,
    onlyPlayerProps,
    includeAllOutcomes,
    oddsRanges: oddsRanges
      ? base64Object(normalizeArray(oddsRanges, "book"))
      : undefined,
    mainLineOnlyBooks,
  });
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v === "undefined") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });
  let url = `${API_BASE_DOMAIN}/free-value-plays${!live ? "-pregame" : ""}`;
  url += params.toString() ? `?${params.toString()}` : "";
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    timeout: GET_PLAYS_TIMEOUT,
  });
  return response.data;
};

const getAlertPlays = async ({ token }) => {
  const url = `${ODDS_API_BASE_DOMAIN}/alert-plays`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const getArbitragePlays = async ({
  boostPercent,
  boostBook,
  live,
  books,
  token,
  sport,
  league,
  middlesOnly,
  hidePlayerProps,
  onlyPlayerProps,
  includeAllOutcomes,
  marketTypes,
  oddsRanges,
  mainLineOnlyBooks,
  useStored,
}) => {
  const params = new URLSearchParams({
    boostPercent,
    boostBook,
    live,
    books,
    sport,
    league,
    middlesOnly,
    hidePlayerProps,
    onlyPlayerProps,
    includeAllOutcomes,
    marketTypes,
    oddsRanges: oddsRanges
      ? base64Object(normalizeArray(oddsRanges, "book"))
      : undefined,
    mainLineOnlyBooks,
    useStored,
  });
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v === "undefined" || v === "0") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });
  let url = `${API_BASE_DOMAIN}/arbitrage-plays${!live ? "-pregame" : ""}`;
  url += params.toString() ? `?${params.toString()}` : "";
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    timeout: GET_PLAYS_TIMEOUT,
  });
  return response.data;
};

const getLowHoldPlays = async ({
  token,
  marketTypes,
  books,
  sport,
  live,
  league,
  lowHoldBook,
  minimumOdds,
  maximumOdds,
  middlesOnly,
  hidePlayerProps,
  onlyPlayerProps,
  includeAllOutcomes,
  oddsRanges,
  mainLineOnlyBooks,
} = {}) => {
  const params = new URLSearchParams({
    marketTypes,
    books,
    sport,
    live,
    league,
    lowHoldBook,
    minimumOdds,
    maximumOdds,
    middlesOnly,
    hidePlayerProps,
    onlyPlayerProps,
    includeAllOutcomes,
    oddsRanges: oddsRanges
      ? base64Object(normalizeArray(oddsRanges, "book"))
      : undefined,
    mainLineOnlyBooks,
  });
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v === "undefined") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });
  let url = `${API_BASE_DOMAIN}/low-hold-plays${!live ? "-pregame" : ""}`;
  url += params.toString() ? `?${params.toString()}` : "";
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    timeout: GET_PLAYS_TIMEOUT,
  });
  return response.data;
};

const getHighHoldPlays = async ({
  token,
  marketTypes,
  books,
  sport,
  live,
  league,
  lowHoldBook,
  minimumOdds,
  maximumOdds,
  middlesOnly,
  hidePlayerProps,
  onlyPlayerProps,
  includeAllOutcomes,
  oddsRanges,
  mainLineOnlyBooks,
} = {}) => {
  const params = new URLSearchParams({
    marketTypes,
    books,
    sport,
    live,
    league,
    lowHoldBook,
    minimumOdds,
    maximumOdds,
    middlesOnly,
    hidePlayerProps,
    onlyPlayerProps,
    includeAllOutcomes,
    oddsRanges: oddsRanges
      ? base64Object(normalizeArray(oddsRanges, "book"))
      : undefined,
    mainLineOnlyBooks,
  });
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v === "undefined") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });
  let url = `${API_BASE_DOMAIN}/high-hold-plays${!live ? "-pregame" : ""}`;
  url += params.toString() ? `?${params.toString()}` : "";
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    timeout: GET_PLAYS_TIMEOUT,
  });
  return response.data;
};

const createCheckoutSession = async ({ token }) => {
  const response = await axios.post(
    `${API_BASE_DOMAIN}/checkout-sessions`,
    null,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const createCustomerPortalSession = async ({ token }) => {
  const response = await axios.post(
    `${API_BASE_DOMAIN}/customer-portal-sessions`,
    null,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getUser = async ({ token }) => {
  const response = await axios.get(`${API_BASE_DOMAIN}/user`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(response.data);
  if (response.data.referralCode === "0SdSsn923") {
    // this is the tiral account
    response.data.isTrialAccount = true;
    response.data.state = "NJ";
  }
  return response.data;
};

const getCZRBaseballScam = async () => {
  const response = await axios.get(`${API_BASE_DOMAIN}/czr-baseball-scam`);
  return response.data;
};

const updateUserEmailSubscription = async ({ token, emailType, value }) => {
  await axios.put(
    `${API_BASE_DOMAIN}/user/emailSubscriptions/${emailType}`,
    { value },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const updateUserBooks = async ({ token, books }) => {
  await axios.put(`${API_BASE_DOMAIN}/user/books`, books, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const updateUserExperienceData = async ({ token, experienceData }) => {
  await axios.put(`${API_BASE_DOMAIN}/user/experience-data`, experienceData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const updateUserSportsbooksAccounts = async ({
  token,
  sportsbooksAccounts,
}) => {
  await axios.put(
    `${API_BASE_DOMAIN}/user/sportsbooksAccounts`,
    sportsbooksAccounts,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const postUserDiscordNotificationSettings = async ({
  token,
  discordNotificationSettings,
}) => {
  await axios.post(
    `${API_BASE_DOMAIN}/user/discordNotificationSettings`,
    discordNotificationSettings,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const updateUserState = async ({ token, state }) => {
  await axios.put(
    `${API_BASE_DOMAIN}/user/state`,
    { state },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const getPromos = async () => {
  await axios.get(`${API_BASE_DOMAIN}/promos`).data;
};

const createTipsCheckoutSession = async ({ token, amount, mode }) => {
  const response = await axios.post(
    `${API_BASE_DOMAIN}/tip-checkout-sessions`,
    { amount, mode },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getEvents = async ({ token, league }) => {
  const response = await axios.get(
    `${API_BASE_DOMAIN}/events?league=${league}`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: { league },
    }
  );
  return response.data;
};

const getAuction = async () => {
  const response = await axios.get(`${API_BASE_DOMAIN}/auction`);
  return response.data;
};

const getWaitlistDate = async (email) => {
  const response = await axios.get(`${API_BASE_DOMAIN}/waitlist-date`, {
    params: { email },
  });
  return response.data;
};

const updateBid = async ({ email, amount }) => {
  await axios.post(`${API_BASE_DOMAIN}/auction/bids`, {
    email,
    amount,
  });
};

const signUpWithReferralCode = async ({ email, referralCode }) => {
  const response = await axios.post(`${API_BASE_DOMAIN}/signup-referral`, {
    email,
    referralCode,
  });
  return response.data;
};

const sendWaitlistEmail = async ({ email }) => {
  await axios.post(`${API_BASE_DOMAIN}/waitlist-emails`, {
    email,
  });
};

const putUserDiscordAlertChannel = async ({ token, channelId }) => {
  await axios.put(
    `${API_BASE_DOMAIN}/user/discordChannels/${channelId}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const deleteUserDiscordAlertChannel = async ({ token, channelId }) => {
  await axios.delete(`${API_BASE_DOMAIN}/user/discordChannels/${channelId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getUpgradeInvoice = async ({ token }) => {
  const response = await axios.get(`${API_BASE_DOMAIN}/upgrade-invoice`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const upgradeSubscription = async ({ token, prorationDate }) => {
  await axios.post(
    `${API_BASE_DOMAIN}/user/subscription`,
    { level: "PREMIUM", prorationDate },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const downgradeSubscription = async ({ token }) => {
  await axios.post(
    `${API_BASE_DOMAIN}/user/subscription`,
    { level: "BASIC" },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const cancelDowngradeSubscription = async ({ token }) => {
  await axios.post(
    `${API_BASE_DOMAIN}/user/subscription/cancelDowngrade`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const addFreePremiumDayPass = async ({ token, userId }) => {
  await axios.post(
    `${API_BASE_DOMAIN}/users/${userId}/free-premium-day-pass`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const deleteFreePremiumDayPass = async ({ token, userId }) => {
  await axios.delete(
    `${API_BASE_DOMAIN}/users/${userId}/free-premium-day-pass`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const purchaseDayPass = async ({ token, useFree }) => {
  await axios.post(
    `${API_BASE_DOMAIN}/purchaseDayPass`,
    { useFree },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const getDayPassInvoice = async ({ token }) => {
  const response = await axios.get(`${API_BASE_DOMAIN}/day-pass-invoice`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const createLiveAlert = async ({ token, data }) => {
  await axios.post(`${API_BASE_DOMAIN}/live-alert`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const createFilterSetting = async ({ token, data }) => {
  const response = await axios.post(`${API_BASE_DOMAIN}/filterSettings`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updateFilterSetting = async ({ token, id, data }) => {
  const response = await axios.post(
    `${API_BASE_DOMAIN}/filterSettings/${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const listFilterSettings = async ({ token }) => {
  const response = await axios.get(`${API_BASE_DOMAIN}/filterSettings`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const deleteFilterSetting = async ({ token, id }) => {
  await axios.delete(`${API_BASE_DOMAIN}/filterSettings/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const deletePushNotificationSetting = async ({ token, id }) => {
  await axios.delete(
    `${API_BASE_DOMAIN}/user/push-notification-settings/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const updateUserGlobalToolSettings = async ({ token, globalToolSettings }) => {
  await axios.put(
    `${API_BASE_DOMAIN}/user/global-tool-settings`,
    globalToolSettings,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const putPushNotificationSetting = async ({
  token,
  id,
  pushNotificationSetting,
}) => {
  await axios.put(
    `${API_BASE_DOMAIN}/user/push-notification-settings/${id}`,
    pushNotificationSetting,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const createPushNotificationSetting = async ({
  token,
  pushNotificationSetting,
}) => {
  await axios.post(
    `${API_BASE_DOMAIN}/user/push-notification-settings`,
    pushNotificationSetting,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const listPushNotificationSettings = async ({ token }) => {
  return await axios.get(`${API_BASE_DOMAIN}/user/push-notification-settings`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const ntfyTestNotification = async ({ token }) => {
  return await axios.get(
    `https://api.bookiebeats.com/pv1/ntfy/test-notification`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const getPlays = async ({
  token,
  boostPercent,
  boostBook,
  live,
  books,
  expectedValueCalcBooks,
  sports,
  leagues,
  middlesOnly,
  hidePlayerProps,
  onlyPlayerProps,
  includeAllOutcomes,
  marketTypes,
  playBook,
  oddsRanges,
  mainLineOnlyBooks,
  middleFilters,
  type,
}) => {
  const params = new URLSearchParams({
    boostPercent,
    boostBook,
    live,
    books,
    expectedValueCalcBooks,
    sports,
    leagues,
    middlesOnly,
    hidePlayerProps,
    onlyPlayerProps,
    includeAllOutcomes,
    marketTypes,
    oddsRanges: oddsRanges
      ? base64Object(normalizeArray(oddsRanges, "book"))
      : undefined,
    middleFilters: middleFilters ? middleFilters : undefined,
    mainLineOnlyBooks,
    playBook,
    type,
  });
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v === "undefined" || v === "0") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });

  let url = `${ODDS_API_BASE_DOMAIN}/plays`;
  url += params.toString() ? `?${params.toString()}` : "";
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    timeout: GET_PLAYS_TIMEOUT,
  });
  return response.data;
};

const getLivePlays = async ({
  token,
  boostPercent,
  boostBook,
  live,
  books,
  expectedValueCalcBooks,
  sports,
  leagues,
  middlesOnly,
  hidePlayerProps,
  onlyPlayerProps,
  includeAllOutcomes,
  marketTypes,
  playBook,
  oddsRanges,
  mainLineOnlyBooks,
  middleFilters,
  type,
}) => {
  const params = new URLSearchParams({
    boostPercent,
    boostBook,
    live,
    books,
    expectedValueCalcBooks,
    sports,
    leagues,
    middlesOnly,
    hidePlayerProps,
    onlyPlayerProps,
    includeAllOutcomes,
    marketTypes,
    oddsRanges: oddsRanges
      ? base64Object(normalizeArray(oddsRanges, "book"))
      : undefined,
    middleFilters: middleFilters ? middleFilters : undefined,
    mainLineOnlyBooks,
    playBook,
    type,
    apiVersion: "2",
  });
  let keysForDel = [];
  params.forEach((v, k) => {
    if (v === "undefined" || v === "0") keysForDel.push(k);
  });
  keysForDel.forEach((k) => {
    params.delete(k);
  });

  let url = `https://6nw3ebgx27.execute-api.us-east-1.amazonaws.com/production/plays`;
  url += params.toString() ? `?${params.toString()}` : "";
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    timeout: GET_PLAYS_TIMEOUT,
  });
  return response.data;
};

const getLiveNotificationPlays = async () => {
  return await axios.get(
    `https://api.bookiebeats.com/public/live-notification-plays`
  );
};

const putUnabatedConfig = async ({
  token,
  startTime,
  endTime,
  cookies,
  amzCfVId,
}) => {
  return await axios.put(
    ODDS_API_BASE_DOMAIN + "/unabated-config",
    {
      startTime,
      endTime,
      cookies,
      amzCfVId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

const getUnabatedConfig = async ({ token }) => {
  return await axios.get(ODDS_API_BASE_DOMAIN + "/unabated-config", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const putBookScrapeConfig = async ({ token, bookScrapeConfig }) => {
  return await axios.put(
    ODDS_API_BASE_DOMAIN + "/book-scrape-config",
    { bookScrapeConfig },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

const getBookScrapeConfig = async ({ token }) => {
  const response = await axios.get(
    ODDS_API_BASE_DOMAIN + "/book-scrape-config",
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export {
  getPlays,
  getLivePlays,
  getFreeValuePlays,
  getFreeValuePlaysV2,
  getArbitragePlays,
  getLowHoldPlays,
  createCheckoutSession,
  createCustomerPortalSession,
  getUser,
  updateUserEmailSubscription,
  updateUserBooks,
  updateUserExperienceData,
  getPromos,
  createTipsCheckoutSession,
  getEvents,
  getAuction,
  getWaitlistDate,
  updateBid,
  updateUserSportsbooksAccounts,
  postUserDiscordNotificationSettings,
  putUserDiscordAlertChannel,
  deleteUserDiscordAlertChannel,
  getUpgradeInvoice,
  upgradeSubscription,
  downgradeSubscription,
  cancelDowngradeSubscription,
  addFreePremiumDayPass,
  deleteFreePremiumDayPass,
  getCZRBaseballScam,
  purchaseDayPass,
  getDayPassInvoice,
  createLiveAlert,
  createFilterSetting,
  updateFilterSetting,
  listFilterSettings,
  deleteFilterSetting,
  sendWaitlistEmail,
  signUpWithReferralCode,
  getHighHoldPlays,
  updateUserState,
  deletePushNotificationSetting,
  createPushNotificationSetting,
  listPushNotificationSettings,
  putPushNotificationSetting,
  getAlertPlays,
  ntfyTestNotification,
  getLiveNotificationPlays,
  putUnabatedConfig,
  getUnabatedConfig,
  updateUserGlobalToolSettings,
  putBookScrapeConfig,
  getBookScrapeConfig,
};
