import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initializationState: "initial",
};

const oneSignalSlice = createSlice({
  name: "oneSignal",
  initialState,
  reducers: {
    setInitializationState(state, action) {
      state.initializationState = action.payload;
    },
  },
});

const { actions, reducer } = oneSignalSlice;
export const { setInitializationState } = actions;
export default reducer;
