import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Alert } from "antd";
import getToken from "../../../../utils/getToken";
import { listPushNotificationSettings } from "../../../../api";
import PushNotificationSettingList from "./componenets/PushNotificationSettingList.component";

const PushNotificationSettingsPage = () => {
  const auth0 = useAuth0();
  const [pushNotificationsState, setPushNotificationsState] =
    useState("loading");
  const [pushNotificationSettings, setPushNotificationSettings] = useState([]);

  useEffect(() => {
    const fetchPushNotificationSettings = async () => {
      setPushNotificationsState("loading");
      try {
        const token = await getToken({ auth0 });
        const response = await listPushNotificationSettings({ token });
        setPushNotificationSettings(response.data);
        setPushNotificationsState("success");
      } catch (err) {
        console.error(err);
        setPushNotificationsState("failure");
      }
    };
    fetchPushNotificationSettings();
  }, [auth0]);

  const livePushNotificationSettings = useMemo(
    () => pushNotificationSettings.filter((pns) => pns.type === "live"),
    [pushNotificationSettings]
  );

  if (pushNotificationsState === "failure") {
    return (
      <div style={{ padding: "1rem 0" }}>
        <Alert
          message="Something is Wrong"
          description="We couldn't get your push notification settings, either try again later or reach out to us if the problem continues"
          type="error"
        />
      </div>
    );
  }

  return (
    <div style={{ padding: "0.5rem 0" }}>
      <Typography.Title level={4}>Live Notifications</Typography.Title>
      <Alert
        message="Changes can take up to 5 minutes to take effect"
        type="warning"
      />
      <div style={{ padding: "1rem 0 0 0" }}>
        <PushNotificationSettingList
          onUpdate={async () => {
            const token = await getToken({ auth0 });
            const response = await listPushNotificationSettings({ token });
            setPushNotificationSettings(response.data);
          }}
          type="live"
          state={pushNotificationsState}
          pushNotificationSettings={livePushNotificationSettings}
        />
      </div>
    </div>
  );
};

export default PushNotificationSettingsPage;
