import React from "react";
import { Typography } from "antd";

const { Paragraph } = Typography;

const MemberCap = () => {
  return (
    <div style={{ padding: "1rem" }}>
      <Paragraph>
        We’ve had a lot of great momentum with referrals lately (welcome all!)
        but that has also led us to reflect on the future of BB. In doing so, we
        have an important announcement to share: we have decided to cap
        membership at 69 users.
      </Paragraph>
      <Paragraph>Our reasoning for this is three fold:</Paragraph>
      <Paragraph>
        <ol>
          <li style={{ marginBottom: "0.5rem" }}>
            We love the community and the friendships we’ve formed through BB -
            that’s what makes this so special. Keeping the community small
            preserves the personal element that we all have grown to love.
          </li>
          <li style={{ marginBottom: "0.5rem" }}>
            We also feel maintaining a smaller community is important for the
            longevity of arb opportunities.
          </li>
          <li style={{ marginBottom: "0.5rem" }}>
            Scientifically, 69 is the optimal number in almost any situation
          </li>
        </ol>
      </Paragraph>
      <Paragraph>
        In all seriousness, preserving what makes BB special is our #1 priority.
        In doing so we recognize we are handicapping the potential $ value
        profit of the enterprise, but we love the community and want to preserve
        that first and foremost. To offset some of the lost potential, we’ve
        decided to try out a “personal worth” model by allowing for tips. Here’s
        how it works:
      </Paragraph>
      <Paragraph>
        As previously announced, the cost will still increase to $50/month
        effective Dec 1 for existing users. We appreciate that $50/month is
        already a decent chunk of change and some of you might find that is all
        it is worth. But if you feel it is worth more to you, we’ve added
        functionality on the website to accept tips. This will go a long way in
        helping us keep the community small and to help keep our wives motivated
        when we're ignoring our kids to work on BB.
      </Paragraph>
      <Paragraph>
        We will continue to evaluate on an ongoing basis what is the best path
        forward for BB, focusing on keeping the community small while still
        allowing for long-term profitability on our side.
      </Paragraph>
      <Paragraph>
        Thank you all for continuing on this journey with us. As you know we
        will continue to strive to make BB the best community we can, and will
        keep innovating and building awesome tools to beat the books. We are
        always open and appreciative to any feedback - please reach out to us or
        post on the #feedback channel. And thanks in advance to those of you who
        will contribute your hard earned arb money to the cause - it means so
        much to us that we’ve created something this impactful and we look
        forward to continuing on this journey together.
      </Paragraph>
    </div>
  );
};

export default MemberCap;
