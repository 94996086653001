import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <div style={{ paddingTop: "1rem" }}>
      <Title level={4}>Bookie Beats Privacy Policy</Title>
      <Paragraph>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit or make a purchase from
        https://www.bookiebeats.com (the “Site”).
      </Paragraph>
      <Title level={5}>PERSONAL INFORMATION WE COLLECT</Title>
      <Paragraph>
        When you visit the Site, we automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies that are installed on your
        device. Additionally, as you browse the Site, we collect information
        about the individual web pages that you view, what websites or search
        terms referred you to the Site, and information about how you interact
        with the Site. We refer to this automatically-collected information as
        “Device Information.”
      </Paragraph>
      <Paragraph>
        We collect Device Information using the following technologies:
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            “Cookies” are data files that are placed on your device or computer
            and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit
            http://www.allaboutcookies.org.
          </li>
          <li>
            “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps.
          </li>
          <li>
            “Web beacons,” “tags,” and “pixels” are electronic files used to
            record information about how you browse the Site.
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        Additionally when you make a purchase or attempt to make a purchase
        through the Site, we collect certain information from you, including
        your name, billing address, shipping address, payment information
        (including credit card numbers), email address, and phone number. We
        refer to this information as “Order Information.”
      </Paragraph>
      <Paragraph>
        When we talk about “Personal Information” in this Privacy Policy, we are
        talking both about Device Information and Order Information.
      </Paragraph>
      <Title level={5}>HOW DO WE USE YOUR PERSONAL INFORMATION?</Title>
      <Paragraph>
        We use the Order Information that we collect generally to fulfill any
        orders placed through the Site (including processing your payment
        information and providing you with invoices and/or order confirmations).
        Additionally, we use this Order Information to communicate with you and
        screen our orders for potential risk or fraud. We DO NOT provide you
        with information or advertising relating to our products or services.
      </Paragraph>
      <Paragraph>
        We use the Device Information that we collect to help us screen for
        potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our Site (for example, by generating
        analytics about how our customers browse and interact with the Site, and
        to assess the success of our product).
      </Paragraph>
      <Title level={5}>SHARING YOUR PERSONAL INFORMATION</Title>
      <Paragraph>
        We share your Personal Information with third parties to help us use
        your Personal Information, as described above. For example, we use
        Stripe to power our payments, you can read more about how Stripe uses
        your Personal Information here:{" "}
        <a
          href="https://stripe.com/gb/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://stripe.com/gb/privacy
        </a>
        .
      </Paragraph>
      <Title level={5}>DATA RETENTION</Title>
      <Paragraph>
        When you place an order through the Site, we will maintain your Order
        Information for our records unless and until you ask us to delete this
        information.
      </Paragraph>
      <Title level={5}>MINORS</Title>
      <Paragraph>
        The Site is not intended for individuals under the age of 21.
      </Paragraph>
      <Title level={5}>CHANGES</Title>
      <Paragraph>
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal or
        regulatory reasons.
      </Paragraph>
      <Title level={5}>CONTACT US</Title>
      <Paragraph>
        For more information about our privacy practices, if you have questions,
        or if you would like to make a complaint, please contact us by e-mail at
        support@bookiebeats.com.
      </Paragraph>
    </div>
  );
};

export default PrivacyPolicy;
