import React from "react";
import { Typography } from "antd";
import freeValueExample from "./freeValueExample.png";

const { Title, Paragraph } = Typography;
const FreeValueHelp = () => {
  return (
    <div>
      <Title level={3}>Free Value Help</Title>
      <Paragraph>
        This page will help you maximize the use of free bets or risk-free bets.
        Books award free bets as part of promotions which work differently than
        regular bets. In a normal bet on an even line (+100), you bet $100 to
        win $100, so your payout is $200. With a free bet, you do not risk
        anything, so there is no stake; in the above example, a $100 free bet on
        a +100 market pays out $100. Therefore, it’s ideal to use free bets on
        underdogs (larger payout per stake). If you hedge your free bet with
        another book, you can guarantee a certain amount of profit; this tool
        helps you find the best markets to maximize profit.
      </Paragraph>
      <Title level={4}>Inputs</Title>
      <Title level={5}>Free Value / Sportsbook</Title>
      <Paragraph>
        Enter the amount of your free bet / risk-free bet, and filter by book.
        <br />
        <i>Note that BR/BS are equivalent to DK in 99% of situations.</i>
      </Paragraph>
      <Title level={4}>Markets</Title>
      <Paragraph>Each market will look like this</Paragraph>
      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        <img
          src={freeValueExample}
          alt="Free Value Market Example"
          width="100%"
        />
      </div>
      <Title level={5}>Title</Title>
      <Paragraph>
        On the top you will see the name of the market and game information if
        the market is a total. Sometimes there will be a "!" on the right side
        of the title. This indicates there is something we think you should know
        about the market, click on it to learn more.
      </Paragraph>
      <Title level={5}>Conversion rate / profit</Title>
      <Paragraph>
        On the left side, you see two amounts. The first is your conversion
        rate. This is the amount value you can extract from the bet based on the
        given play. Typically, a good conversion rate is {">"}75%, but certain
        books are better than others (e.g., PB will often have rates close to
        90% on NBA player props). The second amount is the net profit
        (conversion rate * free value amount).
      </Paragraph>
      <Title level={5}>Outcomes</Title>
      <Paragraph>
        There are two outcomes for any given arbitrage market (yes, it is
        possible to have arbitrage on a market with any number of outcomes but
        those are rare and difficult to find so we stick to 2). If it is a total
        market you will see "Over" and "Under", and if not you will see the name
        of the participant. Beneath the name you will see dollar amounts. In the
        example above Over has $100.00 (the amount of your free value) and Under
        has $522.08, these are the amounts you should bet on that given outcome
        given the free value input.
      </Paragraph>
      <Paragraph>
        To the right of the outcome names you will see a box with the logo of
        the book that the outcome is being offered on. In the box with the logo
        there will either be one or two numbers. On a moneyline market there
        will be only one number, the American style odds of the outcome. For
        spread and total bets there will be a smaller number. For total markets
        it will be the total, for spread markets it will be the spread for that
        outcome. Sometimes you will see an asterisk next to this number, this
        indicates that it is an alternate line/total being offered on that book.
        You will get used to where the books offer these lines as you become
        more familiar with them.
      </Paragraph>
      <Paragraph>
        <i>
          Note we assume the lines for DK/BR/BS are the same, which is true in
          99% of situations, but exceptions are possible!
        </i>
      </Paragraph>
      <Title level={4}>Placing the Bet</Title>
      <Paragraph>
        In the main section, the amount displayed will be your hedge bet. In the
        above example, I would place my $100 PB free bet on the over, and bet
        $522 on FD on the under. This will guarantee me a profit of $77.92 no
        matter the outcome.
      </Paragraph>
      <Title level={4}>Best Practices</Title>
      <Paragraph>
        At DK/BR, sometimes they will reject your bet and offer a lower amount
        (which will result in you forfeiting the free bet). You should never do
        this. Submit for approval if you can; if not, back out and find a
        different bet where they will accept it. This usually only happens with
        very large bets.
      </Paragraph>
      <Paragraph>
        At PB, they tend to thoroughly review large underdogs in the alt player
        props section. We recommend only betting 1 free bet per market, and
        trying to split your bets into smaller increments if they are over $100.
        On PB, if you go into the “free bets” section, it allows you to split
        any free bet up to 4 ways.
      </Paragraph>
    </div>
  );
};

export default FreeValueHelp;
