import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentlyVisible } from "../../../state/ducks/modals";

function ScrollToTop({ history }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(setCurrentlyVisible(undefined));
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history, dispatch]);

  return null;
}

export default withRouter(ScrollToTop);
