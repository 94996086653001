import React from "react";
import { Menu as AntMenu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CopyOutlined,
  ToolOutlined,
  QuestionCircleOutlined,
  MessageOutlined,
  CalculatorOutlined,
  // EllipsisOutlined,
} from "@ant-design/icons";
import styles from "./Menu.module.less";
const { SubMenu, Item } = AntMenu;

const Menu = ({ mode = "horizontal", className }) => {
  let location = useLocation();
  const user = useSelector((state) => state.user.user);
  return (
    <AntMenu
      mode={mode}
      className={className}
      style={{
        height: "100%",
        borderRight: 0,
        width: mode === "inline" ? undefined : "60%",
        background: "transparent",
      }}
      defaultOpenKeys={mode === "inline" ? ["/tools"] : []}
      selectedKeys={[location.pathname]}
    >
      {user && user.subscriptions && user.subscriptions.length > 0 && (
        <>
          <Item
            key="/learn"
            className={mode === "inline" ? styles.topLevelItem : null}
            icon={mode === "inline" ? <CopyOutlined /> : null}
          >
            <Link to="/learn">Learn</Link>
          </Item>
          <SubMenu
            key="/tools"
            title="Tools"
            icon={mode === "inline" ? <ToolOutlined /> : null}
            className={mode === "inline" ? styles.topLevelItem : null}
          >
            <Item key="/tools/pregame-alerts">
              <Link to="/tools/pregame-alerts">Pregame Alerts</Link>
            </Item>
            <Item key="/tools/pregame">
              <Link to="/tools/pregame">Pregame Bet Finder</Link>
            </Item>
            {user &&
              (user.isPartner ||
                ["PREMIUM", "PREMIUM_DAY", "PREMIUM_TRIAL"].includes(
                  user.subscriptionLevel
                )) && (
                <Item key="/tools/live">
                  <Link to="/tools/live">Live Bet Finder</Link>
                </Item>
              )}
            <SubMenu
              key="/tools/calculators"
              title="Calculators"
              icon={mode === "inline" ? <CalculatorOutlined /> : null}
              className={mode === "inline" ? styles.topLevelItem : null}
            >
              <Item key="/tools/calculators/fair-value-ev">
                <Link to="/calculators/fair-value-ev">Expected Value</Link>
              </Item>
            </SubMenu>
            {/* <SubMenu
              key="/tools/other"
              title="Other"
              icon={mode === "inline" ? <EllipsisOutlined /> : null}
              className={mode === "inline" ? styles.topLevelItem : null}
            >
              <Item key="/tools/other/caesars-unders-plays">
                <Link to="/caesars-unders-plays">Caesar's MLB Under Plays</Link>
              </Item>
            </SubMenu> */}
          </SubMenu>
          <Item
            key="/banter"
            className={mode === "inline" ? styles.topLevelItem : null}
            icon={mode === "inline" ? <MessageOutlined /> : null}
          >
            <Link to="/banter">
              <span>Discord + Alerts</span>
            </Link>
          </Item>
          <Item
            key="/frequently-asked-questions"
            className={mode === "inline" ? styles.topLevelItem : null}
            icon={mode === "inline" ? <QuestionCircleOutlined /> : null}
          >
            <Link to="/frequently-asked-questions">FAQs</Link>
          </Item>
        </>
      )}
    </AntMenu>
  );
};

export default Menu;
