import React, { useState, useMemo, useEffect } from "react";
import { Col, Row, Typography, Card } from "antd";
import { useSelector } from "react-redux";
import sanity from "../../../services/sanity";
import styles from "./Home.module.less";

import FeaturedArticles from "./components/FeaturedArticles/FeaturedArticles.component";
import GettingStarted from "./components/GettingStarted/GettingStarted.component";
import Announcement from "./components/Announcement/Announcement.component";
import QuickLinks from "./components/QuickLinks/QuickLinks.component";
import Premium from "./components/Premium/Premium.component";

const { Title } = Typography;

const now = new Date();

const userAnniversary = (user) => {
  const today = new Date();
  const joiningDate = new Date(user.joinTimestamp * 1000);
  const years = today.getFullYear() - joiningDate.getFullYear();
  return {
    isToday:
      years !== 0 &&
      joiningDate.getDate() === today.getDate() &&
      joiningDate.getMonth() === today.getMonth(),
    years,
  };
};

const Home = () => {
  const [announcement, setAnnouncement] = useState({
    data: undefined,
    status: "loading",
  });
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    async function fetchAnnouncements() {
      const sanityAnnouncements = await sanity.fetch(
        `*[_type == "announcement" && show == true] | {show, title, body}`
      );
      setAnnouncement({
        data: sanityAnnouncements[0] || undefined,
        status: "success",
      });
    }
    fetchAnnouncements();
  }, []);

  const anniversary = userAnniversary(user);

  const showAnnouncement = useMemo(() => !!announcement.data, [announcement]);
  const shouldPrioritizePremium = useMemo(() => {
    if (user.subscriptionLevel === "PREMIUM_TRIAL") return true;
    if (user.freePremiumDays > 0) return true;
    const differenceInSeconds = now / 1000 - user.joinTimestamp;
    var differenceInDays = differenceInSeconds / (3600 * 24);
    return differenceInDays % 10 < 1.25;
  }, [user]);
  const isUserPremium = useMemo(() => {
    return (
      user.subscriptionLevel === "PREMIUM" ||
      user.subscriptionLevel === "PREMIUM_DAY"
    );
  }, [user]);

  return (
    <div className={styles.container}>
      <Title level={3}>Welcome to Bookie Beats</Title>
      {anniversary.isToday && (
        <Row>
          <Card
            style={{ width: "100%" }}
            bodyStyle={{ padding: "0.25rem", textAlign: "center" }}
          >
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Today is your{" "}
                <span className={styles.primaryColor}>{anniversary.years}</span>{" "}
                year Bookie Beats anniversary!
              </Title>
            </div>
          </Card>
        </Row>
      )}
      {user.subscriptionLevel === "PREMIUM_TRIAL" && (
        <Row>
          <Col span={24}>
            <Card>
              <GettingStarted />
            </Card>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        {showAnnouncement && !user.isTrialAccount && (
          <Col span={24} md={12}>
            <Card>
              <Announcement announcement={announcement.data} />
            </Card>
          </Col>
        )}
        {!isUserPremium && shouldPrioritizePremium && (
          <Col span={24} md={12}>
            <Card>
              <Premium />
            </Card>
          </Col>
        )}
        <Col span={24} md={12}>
          <Card>
            <FeaturedArticles />
          </Card>
        </Col>
        <Col span={24} md={isUserPremium ? 24 : showAnnouncement ? 12 : 24}>
          <Card>
            <QuickLinks
              showAnnouncement={showAnnouncement}
              isUserPremium={isUserPremium}
            />
          </Card>
        </Col>
        {!isUserPremium && !shouldPrioritizePremium && (
          <Col span={24} md={12}>
            <Card>
              <Premium />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Home;
