import { createSlice } from "@reduxjs/toolkit";

const IS_DARK_THEME_COOKIE_NAME = "isDarkTheme";

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const initialState = {
  isDark: getCookie(IS_DARK_THEME_COOKIE_NAME),
  isAfd: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setIsDark(state, action) {
      setCookie(IS_DARK_THEME_COOKIE_NAME, action.payload);
      state.isDark = action.payload;
    },
    setIsAfd(state, action) {
      state.isAfd = action.payload;
    },
  },
});

const { actions, reducer } = themeSlice;
export const { setIsDark, setIsAfd } = actions;
export default reducer;
