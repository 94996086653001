import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Spin, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import axios from "axios";
import styles from "./Leaderboard.module.less";

const getSuperBowl = async ({ id }) => {
  const response = await axios.get(
    "https://vokbvjr975.execute-api.us-east-1.amazonaws.com/prod/superBowls/" +
      id
  );
  return response.data;
};

const Leaderboard = () => {
  const [superBowl, setSuperbowl] = useState();
  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const initialize = async () => {
      const superBowl = await getSuperBowl({
        id,
      });
      console.log(superBowl);
      superBowl.entries.forEach((entry) => {
        let points = 0;
        for (const [question, answer] of Object.entries(superBowl.answers)) {
          if (question.includes("Taylor")) {
            console.log(question);
          }
          if (answer.answer && entry.answers[question] === answer.answer)
            points += answer.value;
        }
        entry.points = points;
        entry.tiebreakerDifference = Math.abs(
          entry.tiebreaker - superBowl.answers["Tiebreaker"]
            ? superBowl.answers["Tiebreaker"].answer
            : 0
        );
      });
      superBowl.entries.sort((a, b) => {
        return (
          b.points - a.points || a.tiebreakerDifference - b.tiebreakerDifference
        );
      });

      let currentPoints = 100000;
      let currentPlace = 1;
      let displayPlace = 1;
      superBowl.entries.forEach((entry) => {
        if (entry.points < currentPoints) {
          displayPlace = currentPlace;
          currentPoints = entry.points;
        }
        entry.place = displayPlace;
        currentPlace++;
      });

      const leader = superBowl.entries[0];
      superBowl.entries.forEach((entry) => {
        let possibleRemainingPoints = 0;
        for (const [question, answer] of Object.entries(entry.answers)) {
          if (
            !superBowl.answers[question] ||
            !superBowl.answers[question].answer
          ) {
            possibleRemainingPoints += 0;
            if (!answer.includes("(")) {
              possibleRemainingPoints += 1;
            } else {
              possibleRemainingPoints += parseInt(
                answer.split("(")[1].split(" ")[0]
              );
            }
          }
        }
        entry.isEliminated =
          leader.points - entry.points > possibleRemainingPoints;
      });

      setSuperbowl(superBowl);
    };

    initialize();
  }, [id]);

  const handleEntryClick = (entry) => {
    if (!superBowl.hasStarted) return;
    history.push({
      pathname: "/super-bowl-prop-bet-entry",
      state: { entry, answers: superBowl.answers },
    });
  };

  if (!superBowl) {
    return (
      <div className={styles.loadingContainer}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.leaderboardPage}>
      <div className={styles.container}>
        {superBowl.entries.map((entry) => {
          const { name, email, points, place, tiebreaker, isEliminated } =
            entry;
          let style = {
            cursor: superBowl.hasStarted ? "pointer" : "",
          };
          return (
            <div key={`${email}-${name}`} className={styles.cardContainer}>
              <Card
                style={style}
                bodyStyle={{ padding: "0.5rem" }}
                onClick={() => {
                  handleEntryClick(entry);
                }}
              >
                <div
                  className={`${styles.item}${
                    isEliminated ? ` ${styles.eliminated}` : ""
                  }`}
                >
                  <div style={{ width: "2.5rem", textAlign: "center" }}>
                    {place}
                  </div>
                  <div className={styles.itemName}>
                    <div>{name}</div>
                    {superBowl.hasStarted && (
                      <div className={styles.tiebreaker}>
                        Tiebreaker: {tiebreaker}
                      </div>
                    )}
                  </div>
                  <div className={styles.itemPoints}>{points}</div>
                  {superBowl.hasStarted && (
                    <div className={styles.openIcon}>
                      <RightOutlined
                        style={{
                          fontSize: 12,
                          color: "gray",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Leaderboard;
