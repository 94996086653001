import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useTheme } from "../../../hooks";

const Helmet = () => {
  const { pathname } = useLocation();
  const themeTokens = useTheme();

  let title = "Bookie Beats: Sports Gambling Arbitrage & Tools";
  if (pathname === "/tools/live") {
    title = "Bookie Beats: Live Bet Finder";
  } else if (pathname === "/tools/pregame") {
    title = "Bookie Beats: Pregame Bet Finder";
  }

  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Bookie Beats provides the fastest alerts on sports gambling odds. We have all of the tools you need to beat the books including arbitrage, free bet conversion, low holds and more."
      />
      <meta name="theme-color" content={themeTokens.colorBgContainer} />
      <meta
        name="msapplication-navbutton-color"
        content={themeTokens.colorBgContainer}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={themeTokens.colorBgContainer}
      />
      <style type="text/css">
        {`
        body {
            background-color: ${themeTokens.colorBgContainer};
        }
        `}
      </style>
    </ReactHelmet>
  );
};

export default Helmet;
