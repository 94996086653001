import React, { useMemo } from "react";
import { Anchor } from "antd";

const textToSlug = (text) => {
  return encodeURIComponent(text.replace(/\s+/g, "-").toLowerCase());
};

const getChildrenText = (props) =>
  props.children
    .map((node) => (typeof node === "string" ? node : node.text || ""))
    .join("");

const generateItems = (outline) => {
  return outline.map((heading) => {
    const text = getChildrenText(heading);
    const item = {
      key: heading._key,
      href: "#" + textToSlug(text),
      title: text,
    };
    if (heading.subheadings.length > 0) {
      item.children = generateItems(heading.subheadings);
    }
    return item;
  });
};

const TableOfContents = ({ outline, affix }) => {
  const items = useMemo(() => generateItems(outline), [outline]);
  return <Anchor offsetTop={10} affix={affix} items={items} />;
};

export default TableOfContents;
