import { Table } from "antd";
import React from "react";

const TableComponent = ({ value }) => {
  const columns = value.rows[0].cells.map((header) => ({
    title: header,
    dataIndex: header,
    key: header,
    width: 150,
  }));
  columns[0].fixed = "left";
  const data = [];
  var obj;
  let count = 1;
  value.rows.slice(1).forEach((value) => {
    obj = { key: count };
    for (var i = 0; i < columns.length; i++) {
      const col = columns[i].title;

      obj[col] = value.cells[i];
    }
    data[count] = obj;
    count++;
  });

  return (
    <Table
      dataSource={data}
      columns={columns}
      scroll={{
        x: 500,
      }}
    />
  );
};
export default TableComponent;
