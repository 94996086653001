import React from "react";
import { useSelector } from "react-redux";
import { Drawer as AntDrawer, Button } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import Menu from "../Menu/Menu.component";
import { useTheme } from "../../../hooks";
import { Link } from "react-router-dom";

const Drawer = ({ sports, visible, onClose }) => {
  const user = useSelector((state) => state.user.user);
  const themeTokens = useTheme();

  return (
    <AntDrawer
      placement="right"
      closable={true}
      open={visible}
      onClose={onClose}
      closeIcon={
        <MenuUnfoldOutlined
          style={{ fontSize: "28px", color: themeTokens.colorText }}
        />
      }
      bodyStyle={{ padding: "18px 0" }}
    >
      <Menu sports={sports} mode="inline" />
      <div style={{ textAlign: "center", padding: "1rem" }}>
        <Link to="/account">
          <Button>Account</Button>
        </Link>
      </div>
      {user && user.subscriptionLevel === "BASIC" && (
        <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
          <Link to="/upgrade-to-premium">
            <Button
              type="ghost"
              style={{ borderColor: "#f8a62c", color: "#f8a62c" }}
            >
              Premium
            </Button>
          </Link>
        </div>
      )}
      {user && (
        <div style={{ textAlign: "center" }}>
          <Link to="/tips">
            <Button type="primary">Tips</Button>
          </Link>
        </div>
      )}
    </AntDrawer>
  );
};

export default Drawer;
