import React from "react";
import { Typography } from "antd";
import { HashLink as Link } from "react-router-hash-link";
import ImageComponent from "./Image.component";
import collapseComponent from "./Collapse.component";
import table from "./Table.component";

import getYouTubeId from "get-youtube-id";

const { Title, Text } = Typography;

const textToSlug = (text) => {
  return encodeURIComponent(text.replace(/\s+/g, "-").toLowerCase());
};

// function called when rendering the article body via PortableText
// add on new types as needed such as tables, collapsable, etc.
const serializers = {
  types: {
    image: ImageComponent,
    youtube: ({ value }) => {
      const { url } = value;
      const id = getYouTubeId(url);

      return (
        <div
          className="video"
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0,
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={`https://www.youtube.com/embed/${id}`}
            frameBorder="0"
            title={value.title}
          />
        </div>
      );
    },
    table: table,
    collapse: collapseComponent,
  },

  marks: {
    //renders internal links as  urls in the webpage
    internalLink: ({ value, children }) => {
      const { handle = {} } = value;
      if (handle && handle.current) {
        let href = `/learn/${handle.current}`;
        if (value.anchor) href += `#${value.anchor}`;
        return (
          <Link
            to={href}
            style={{
              color: "inherit",
              textDecoration: "underline",
              textDecorationColor: "#1da57a",
            }}
          >
            {children}
          </Link>
        );
      } else {
        return <span>{children}</span>;
      }
    },
    anchor: ({ value, children }) => {
      return <span id={value.anchor}>{children}</span>;
    },
    em: ({ children }) => <em>{children}</em>,
    color: ({ value, children }) => (
      <Text style={{ color: value.hex }}>{children} </Text>
    ),
    strong: ({ children }) => <strong>{children}</strong>,
  },
  block: {
    h1: ({ value }) => {
      const { text } = value.children[0];
      return (
        <Title level={3} id={textToSlug(text)} style={{ marginTop: "1em" }}>
          {text}
        </Title>
      );
    },
    h2: ({ value }) => {
      const { text } = value.children[0];
      return (
        <Title level={4} id={textToSlug(text)} style={{ marginTop: "0.5em" }}>
          {text}
        </Title>
      );
    },
    h3: ({ value }) => {
      const { text } = value.children[0];
      return (
        <Title level={5} id={textToSlug(text)} style={{ marginTop: "0.25em" }}>
          {text}
        </Title>
      );
    },
    h4: ({ value }) => {
      const { text } = value.children[0];
      return (
        <Text strong id={textToSlug(text)}>
          {text}
        </Text>
      );
    },
    h5: ({ value }) => {
      const { text } = value.children[0];
      return (
        <Text strong id={textToSlug(text)}>
          {text}
        </Text>
      );
    },
    blockquote: ({ value }) => {
      return <Text code>{value.children[0].text} </Text>;
    },
  },
};

export default serializers;
