import React, {
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useCallback,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Radio,
  Button,
  Switch,
  InputNumber,
  Select,
  Checkbox,
  Modal,
  notification,
  message,
  Form,
  Input,
  List,
  Typography,
  Tooltip,
  TreeSelect,
} from "antd";
import {
  DollarCircleOutlined,
  FilterOutlined,
  ClockCircleOutlined,
  SaveOutlined,
  FolderOpenOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useIdleTimer } from "react-idle-timer";
import useSound from "use-sound";
import MiddleFiltersModal from "./MiddleFiltersModal.component";
import {
  createFilterSetting,
  updateFilterSetting,
  listFilterSettings,
  deleteFilterSetting,
} from "../../../api";
import { useTheme } from "../../../hooks";
import getToken from "../../../utils/getToken";
import { fetchMarketTypes } from "../../../state/ducks/marketTypes";
import { setIsAfd } from "../../../state/ducks/theme";
import bookSelectOptions from "../BookSelectOptions/BookSelectOptions.component";
import TimerButtonControls from "../TimerButtonControls/TimerButtonControls.component";
import styles from "./ToolOptions.module.less";
import config from "../../../config";

const { Option } = Select;

const SPORTS = config.sports;

// an object with the league key as the key and the league as the value but including the sport key
const LEAGUES_WITH_SPORT = {};
Object.keys(SPORTS).forEach((sport) => {
  SPORTS[sport].leagues.forEach((league) => {
    LEAGUES_WITH_SPORT[league.value] = {
      ...league,
      sport,
    };
  });
});

const SPORTS_SELECT_OPTIONS = (live) => {
  const options = Object.keys(SPORTS).map((sport) => {
    if (!live && SPORTS[sport].liveOnly) return;
    const children = SPORTS[sport].leagues.map((league) => {
      if (!live && SPORTS[sport].liveOnly) return;
      return {
        value: league.value,
        title: league.display,
      };
    });
    return {
      value: sport,
      title: SPORTS[sport].display,
      children: children.filter((child) => child),
    };
  });

  //remove undefined values
  return options.filter((option) => option);
};

const defaultOptions = {
  autoRefreshEnabled: false,
  alertEnabled: false,
  betType: "arbitrage",
  lowHoldBook: "any",
  stakeType: "underdog",
  autoRefreshInterval: 10,
  sport: "all",
  league: "all",
  stake: 100,
  oddsRanges: [{ book: undefined, min: undefined, max: undefined }],
  expectedFreeBetConversion: 75,
  sortOrder: "default",
  middlesOnly: false,
  stayingPowerFloor: 3,
  showOtherOdds: "toggle",
};

const ACTIVE = "ACTIVE";
const IDLE = "IDLE";
const PROMPTED = "PROMPTED";
const ACTIVE_EVENTS = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
];

const ENABLE_RISK_FREE_AS_FREE_BET = true;

const MoreButton = ({ isMoreShown, onClick }) => {
  return (
    <div className={styles.moreButton}>
      <Button onClick={onClick} size="small">
        {isMoreShown ? "Less Options" : "More Options"}
      </Button>
    </div>
  );
};

const deepCopy = (o) => JSON.parse(JSON.stringify(o));

const TIME_UNTIL_PROMPT = 1000 * 60 * 30; // 30 minutes
const TIME_TO_PROVE_STILL_HERE = 1000 * 60; // 60 seconds

const FILTER_SETTINGS_VERSION = 1;

const ToolOptions = forwardRef(
  (
    {
      onChange = () => {},
      onRefresh = () => {},
      showAutoRefreshOptions = false,
      initialOptions = defaultOptions,
      live = true,
    },
    ref
  ) => {
    const defaultLeagueSelection = useMemo(() => {
      const flattenedLeagues = Object.keys(SPORTS)
        .map((sport) => {
          if (!live && SPORTS[sport].liveOnly) return [];
          return SPORTS[sport].leagues;
        })
        .flat();
      return flattenedLeagues.map((league) => league.value);
    }, [live]);

    initialOptions = {
      ...initialOptions,
      ...{ ...defaultOptions, leagues: defaultLeagueSelection },
    };

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state) => state.user.user);
    const marketTypes = useSelector((state) => state.marketTypes);

    const [messageApi, contextHolder] = message.useMessage();

    const auth0 = useAuth0();

    const leaguesSelectOptions = useMemo(
      () => SPORTS_SELECT_OPTIONS(live),
      [live]
    );

    // eslint-disable-next-line
    const [options, setOptions] = useState(initialOptions);
    const [isMoreShown, setIsMoreShown] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [timerResetKey, setTimerResetKey] = useState(85897287);
    const [activeSection, setActiveSection] = useState("Stake");
    const [idleState, setIdleState] = useState(ACTIVE);
    const [loadedSettings, setLoadedSettings] = useState();
    const [showSaveSettingsModal, setShowSaveSettingsModal] = useState(false);
    const [showMiddleFiltersModal, setShowMiddleFiltersModal] = useState(false);
    const [showSaveNewSettingsModal, setShowSaveNewSettingsModal] =
      useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [showOpenSettingsModal, setShowOpenSettingsModal] = useState(false);
    const [openSettingsLoadingStatus, setOpenSettingsLoadingStatus] =
      useState("idle");
    const [savedSettings, setSavedSettings] = useState([]);
    const [marketTypesValue, setMarketTypes] = useState([]);
    // eslint-disable-next-line
    const [_, setCounter] = useState(0);
    const [play] = useSound(
      "https://mcwebby.s3.amazonaws.com/areyoustillthere.mp3"
    );
    const themeTokens = useTheme();

    const onIdle = useCallback(() => {
      setIdleState(IDLE);
    }, []);

    const onPrompt = useCallback(() => {
      try {
        play();
      } catch (err) {
        console.error(err);
      }
      setIdleState(PROMPTED);
    }, [play]);

    const idleTimer = useIdleTimer({
      onIdle,
      onPrompt,
      promptTimeout: TIME_TO_PROVE_STILL_HERE,
      timeout: TIME_UNTIL_PROMPT,
      events: ACTIVE_EVENTS,
      startManually: true,
      stopOnIdle: true,
    });

    useEffect(() => {
      let interval;
      if (idleState === PROMPTED)
        interval = setInterval(() => setCounter((old) => old + 1), 1000);
      else clearInterval(interval);

      if (idleState === IDLE) {
        setOptions((oldOptions) => ({
          ...oldOptions,
          autoRefreshEnabled: false,
        }));
      }

      return () => clearInterval(interval);
    }, [idleState]);

    useEffect(() => {
      onChange(options);
    }, [onChange, options]);

    useEffect(() => {
      if (!user.isPartner && options.autoRefreshEnabled) {
        idleTimer.start();
      } else {
        idleTimer.pause();
      }

      // eslint-disable-next-line
    }, [options.autoRefreshEnabled]);

    useEffect(() => {
      dispatch(fetchMarketTypes());
    }, [dispatch]);

    useEffect(() => {
      setOptions((previousOptions) => {
        return {
          ...previousOptions,
          marketTypes: marketTypesValue,
        };
      });
    }, [marketTypesValue]);

    useEffect(() => {
      async function fetchSettings() {
        if (showOpenSettingsModal) {
          setOpenSettingsLoadingStatus("loading");
          const token = await getToken({ auth0 });
          const data = await listFilterSettings({
            token,
          });
          setSavedSettings(data.filterSettings);
          setOpenSettingsLoadingStatus("success");
        }
      }

      fetchSettings();
    }, [showOpenSettingsModal, auth0]);

    const availableBooksSelect = useMemo(() => user.books, [user]);

    const sections = useMemo(() => {
      let s = [
        { label: <DollarCircleOutlined />, value: "Stake" },
        { label: <FilterOutlined />, value: "Filters" },
      ];
      if (showAutoRefreshOptions) {
        s.push({ label: <ClockCircleOutlined />, value: "Auto Refresh" });
      }
      return s;
    }, [showAutoRefreshOptions]);

    const handlePlayChange = useCallback(
      () => setIsPlaying((p) => !p),
      [setIsPlaying]
    );

    const marketTypeSelectOptions = useMemo(() => {
      const leagues = options.leagues || defaultLeagueSelection;
      const sportsOfLeagues = leagues.map((league) => {
        return LEAGUES_WITH_SPORT[league].sport;
      });
      let marketTypeSelectOptions = [];
      if (sportsOfLeagues && marketTypes.status === "succeeded") {
        let marketOptions = [];
        Object.values(marketTypes.data).forEach((marketType) => {
          if (
            marketType.sports &&
            marketType.sports.some((item) => sportsOfLeagues.includes(item))
          ) {
            let display = marketType.friendlyName;
            if (display.includes("%PLAYER%"))
              display = display.replace("%PLAYER%", "Player");
            marketOptions.push({
              value: marketType.id,
              display,
            });
          }
        });
        marketOptions = marketOptions.sort((a, z) =>
          a.display.localeCompare(z.display)
        );
        marketTypeSelectOptions = [
          ...marketTypeSelectOptions,
          ...marketOptions,
        ];
      }

      return marketTypeSelectOptions;
    }, [options.leagues, marketTypes]);

    const handleRefresh = useCallback(async () => {
      const initialIsPlaying = isPlaying;
      setIsPlaying(false);
      setIsRefreshing(true);
      await onRefresh();
      setIsRefreshing(false);
      setIsPlaying(initialIsPlaying);
      setTimerResetKey((p) => p + 1);
    }, [onRefresh, isPlaying]);

    return (
      <div
        className={styles.container}
        style={{ background: themeTokens.colorBgContainer }}
      >
        {contextHolder}
        <Modal
          title="Are you still there?"
          open={idleState === PROMPTED}
          okText="Yes"
          cancelText="No"
          maskClosable={false}
          closable={false}
          keyboard={false}
          onOk={() => {
            idleTimer.reset();
            idleTimer.start();
            setIdleState(ACTIVE);
          }}
          onCancel={() => {
            idleTimer.reset();
            idleTimer.start();
            setIdleState(ACTIVE);
            notification.open({
              message: "LIAR!",
            });
          }}
        >
          <p>
            Auto Refresh will be disabled in{" "}
            <b>{Math.ceil(idleTimer.getRemainingTime() / 1000)}</b> seconds
          </p>
        </Modal>
        <Modal
          title="Save Settings"
          open={showSaveSettingsModal}
          footer={null}
          onCancel={() => {
            setShowSaveSettingsModal(false);
            setShowSaveNewSettingsModal(false);
          }}
        >
          {!showSaveNewSettingsModal ? (
            <div className={styles.saveModalContainer}>
              <div>Save the current filter settings for easy set up later.</div>
              <div>
                <Button
                  disabled={!loadedSettings}
                  loading={updateInProgress}
                  type="primary"
                  onClick={async () => {
                    setUpdateInProgress(true);
                    try {
                      const token = await getToken({ auth0 });
                      const data = await updateFilterSetting({
                        token,
                        id: loadedSettings.id,
                        data: {
                          filterSettings: options,
                        },
                      });
                      setLoadedSettings(data);
                      messageApi.open({
                        type: "success",
                        content: `Successfully updated ${loadedSettings.name}`,
                      });
                      setShowSaveSettingsModal(false);
                    } catch (err) {
                      messageApi.open({
                        type: "error",
                        content: `Failed to update ${loadedSettings.name}`,
                      });
                    } finally {
                      setUpdateInProgress(false);
                    }
                  }}
                >
                  Save
                </Button>
                {loadedSettings && (
                  <div>
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      Overwrite "{loadedSettings.name}"
                    </Typography.Text>
                  </div>
                )}
              </div>
              <div>
                <Button onClick={() => setShowSaveNewSettingsModal(true)}>
                  Save New
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Form
                name="saveSettings"
                onFinish={async ({ name }) => {
                  try {
                    setSaveInProgress(true);
                    const token = await getToken({ auth0 });
                    const data = await createFilterSetting({
                      token,
                      data: {
                        name,
                        version: FILTER_SETTINGS_VERSION,
                        filterSettings: options,
                      },
                    });
                    setLoadedSettings(data);
                    messageApi.open({
                      type: "success",
                      content: `Successfully created ${name}`,
                    });
                    setShowSaveSettingsModal(false);
                    setShowSaveNewSettingsModal(false);
                  } catch (err) {
                    messageApi.open({
                      type: "error",
                      content: `Failed to create ${name}`,
                    });
                  } finally {
                    setSaveInProgress(false);
                  }
                }}
                autoComplete="off"
              >
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Please input a name!" }]}
                >
                  <Input placeholder="Name" maxLength={64} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={saveInProgress}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </Modal>
        {showMiddleFiltersModal && (
          <MiddleFiltersModal
            open={showMiddleFiltersModal}
            middleFilters={options.middleFilters}
            onCancel={() => {
              setShowMiddleFiltersModal(false);
            }}
            onSave={(middleFilters) => {
              setOptions((previousOptions) => {
                return {
                  ...previousOptions,
                  middleFilters,
                };
              });
              setShowMiddleFiltersModal(false);
            }}
          />
        )}
        <Modal
          title="Open Settings"
          open={showOpenSettingsModal}
          footer={null}
          onCancel={() => setShowOpenSettingsModal((p) => !p)}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              Select the settings that you would like to use
            </div>
            {(openSettingsLoadingStatus === "loading" ||
              savedSettings.length > 0) && (
              <List
                bordered
                size="small"
                loading={openSettingsLoadingStatus === "loading"}
                dataSource={savedSettings}
                renderItem={(savedSetting) => {
                  const { name, id, filterSettings } = savedSetting;
                  return (
                    <List.Item key={id}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            flexGrow: 1,
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // maintain backwards compatibility
                            console.log(filterSettings);
                            if (!filterSettings.leagues) {
                              if (filterSettings.sport === "all") {
                                setOptions({
                                  ...filterSettings,
                                  leagues: defaultLeagueSelection,
                                });
                              } else {
                                if (filterSettings.league === "all") {
                                  const leagues = SPORTS[
                                    filterSettings.sport
                                  ].leagues.map((league) => {
                                    return league.value;
                                  });
                                  setOptions({
                                    ...filterSettings,
                                    leagues,
                                  });
                                } else {
                                  setOptions({
                                    ...filterSettings,
                                    leagues: [filterSettings.league],
                                  });
                                }
                              }
                            } else {
                              setOptions(filterSettings);
                            }
                            setLoadedSettings(savedSetting);
                            setShowOpenSettingsModal(false);
                          }}
                        >
                          {name}
                        </div>
                        <div style={{ float: "right" }}>
                          <Button
                            type="text"
                            danger
                            shape="circle"
                            onClick={async () => {
                              setSavedSettings((previousSavedSettings) => {
                                return previousSavedSettings.filter(
                                  (setting) => {
                                    return setting.id !== id;
                                  }
                                );
                              });
                              const token = await getToken({ auth0 });
                              await deleteFilterSetting({ token, id });
                            }}
                            icon={<DeleteOutlined />}
                          />
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            )}
            {openSettingsLoadingStatus === "success" &&
              savedSettings.length <= 0 && (
                <Alert message="No Saved Settings" type="warning" />
              )}
          </div>
        </Modal>
        <div
          ref={ref}
          className={styles.optionsContainer}
          style={{ borderBottomColor: themeTokens.colorBorder }}
        >
          <div className={styles.floatingOptions}>
            {live && (
              <Button
                shape="circle"
                icon={<BellOutlined />}
                style={{ marginRight: "0.5rem" }}
                onClick={() => history.push("/account#push-notifications")}
              />
            )}
            <Button
              shape="circle"
              icon={<FolderOpenOutlined />}
              style={{ marginRight: "0.5rem" }}
              onClick={() => setShowOpenSettingsModal((p) => !p)}
            />
            <Button
              shape="circle"
              icon={<SaveOutlined />}
              onClick={() => setShowSaveSettingsModal((p) => !p)}
            />
          </div>
          <div>
            <div className={styles.row}>
              {activeSection === "Stake" && (
                <>
                  <div style={{ marginRight: "0.5rem" }}>
                    <div className={styles.formFieldTitle}>Bet Type</div>
                    <Select
                      value={options.betType}
                      defaultValue={initialOptions.betType}
                      style={{ width: 110 }}
                      onChange={(betType) => {
                        setOptions((previousOptions) => {
                          return {
                            ...previousOptions,
                            betType,
                          };
                        });
                        if (betType === "highhold") dispatch(setIsAfd(true));
                        else dispatch(setIsAfd(false));
                      }}
                    >
                      <Option value="arbitrage">Arbitrage</Option>
                      <Option value="lowhold">Low Hold</Option>
                      <Option value="freevalue">Free Value</Option>
                      <Option value="highhold">High Hold</Option>
                    </Select>
                  </div>
                  {options.betType === "lowhold" && (
                    <div style={{ marginRight: "0.5rem" }}>
                      <div className={styles.formFieldTitle}>Book</div>
                      <Select
                        className={styles.bookeSelect}
                        value={options.lowHoldBook}
                        defaultValue={initialOptions.lowHoldBook}
                        onChange={(lowHoldBook) =>
                          setOptions((previousOptions) => {
                            return {
                              ...previousOptions,
                              lowHoldBook,
                            };
                          })
                        }
                      >
                        <Option value="any">Any</Option>
                        {bookSelectOptions(availableBooksSelect)}
                      </Select>
                    </div>
                  )}
                </>
              )}
              {activeSection === "Filters" && (
                <>
                  <div style={{ marginRight: "0.5rem" }}>
                    <div className={styles.formFieldTitle}>Books</div>
                    <Select
                      mode="multiple"
                      value={options.books}
                      defaultValue={initialOptions.books}
                      style={{ width: 175 }}
                      maxTagCount="responsive"
                      onChange={(books) =>
                        setOptions((previousOptions) => {
                          return {
                            ...previousOptions,
                            books,
                          };
                        })
                      }
                    >
                      {bookSelectOptions(availableBooksSelect)}
                    </Select>
                  </div>
                </>
              )}
              {activeSection === "Auto Refresh" && (
                <>
                  <div style={{ marginRight: "1rem" }}>
                    <div className={styles.formFieldTitle}>Auto Refresh</div>
                    <div>
                      <Switch
                        checked={options.autoRefreshEnabled}
                        style={{ marginTop: 5 }}
                        onChange={(autoRefreshEnabled) => {
                          setOptions((previousOptions) => ({
                            ...previousOptions,
                            autoRefreshEnabled,
                          }));
                          setIsPlaying(false);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.formFieldTitle}>Interval</div>
                    <div>
                      <Select
                        value={options.autoRefreshInterval}
                        defaultValue={initialOptions.autoRefreshInterval}
                        disabled={!options.autoRefreshEnabled}
                        style={{ width: 65 }}
                        onChange={(autoRefreshInterval) => {
                          setOptions((previousOptions) => ({
                            ...previousOptions,
                            autoRefreshInterval,
                          }));
                          setTimerResetKey((p) => p + 1);
                        }}
                      >
                        {(user.isPartner || user.isAdmin) && (
                          <Option value="0.5">0.5</Option>
                        )}
                        <Option value="2">2</Option>
                        <Option value="5">5</Option>
                        <Option value="10">10</Option>
                        <Option value="15">15</Option>
                      </Select>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.row} style={{ flexWrap: "wrap" }}>
              {activeSection === "Stake" && (
                <>
                  <div style={{ marginRight: "0.5rem" }}>
                    <div className={styles.formFieldTitle}>Stake</div>
                    <InputNumber
                      className={styles.stakeInput}
                      min={0.5}
                      addonBefore="$"
                      placeholder={100}
                      value={options.stake}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      onChange={(stake) => {
                        setOptions((previousOptions) => {
                          return {
                            ...previousOptions,
                            stake,
                          };
                        });
                      }}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      controls={false}
                      style={{ width: 80 }}
                    />
                  </div>
                  {options.betType !== "freevalue" && (
                    <div style={{ marginRight: "0.5rem" }}>
                      <div className={styles.formFieldTitle}>Stake Type</div>
                      <Select
                        value={options.stakeType}
                        defaultValue={initialOptions.stakeType}
                        style={{ width: 110 }}
                        onChange={(stakeType) =>
                          setOptions((previousOptions) => {
                            return {
                              ...previousOptions,
                              stakeType,
                            };
                          })
                        }
                      >
                        <Option value="underdog">Underdog</Option>
                        <Option value="favorite">Favorite</Option>
                        <Option value="book">Book</Option>
                      </Select>
                    </div>
                  )}
                  {options.betType === "freevalue" && (
                    <div style={{ marginRight: "0.5rem" }}>
                      <div className={styles.formFieldTitle}>
                        Free Value Book
                      </div>
                      <Select
                        value={options.freeValueBook}
                        defaultValue={initialOptions.freeValueBook}
                        style={{ width: 110 }}
                        onChange={(freeValueBook) =>
                          setOptions((previousOptions) => {
                            return {
                              ...previousOptions,
                              freeValueBook,
                            };
                          })
                        }
                      >
                        <Option value="any" default>
                          Any
                        </Option>
                        {bookSelectOptions(availableBooksSelect)}
                      </Select>
                    </div>
                  )}
                  {["arbitrage", "lowhold"].includes(options.betType) &&
                    options.stakeType === "book" && (
                      <div>
                        <div className={styles.formFieldTitle}>Stake Book</div>
                        <Select
                          value={options.stakeBook}
                          defaultValue={initialOptions.stakeBook}
                          style={{ width: 110 }}
                          onChange={(stakeBook) =>
                            setOptions((previousOptions) => {
                              return {
                                ...previousOptions,
                                stakeBook,
                              };
                            })
                          }
                        >
                          {bookSelectOptions(availableBooksSelect)}
                        </Select>
                      </div>
                    )}
                </>
              )}
              {activeSection === "Filters" && (
                <>
                  <div style={{ marginRight: "0.5rem" }}>
                    <div className={styles.formFieldTitle}>Leagues</div>
                    <div>
                      <TreeSelect
                        value={options.leagues}
                        multiple
                        treeLine
                        treeData={leaguesSelectOptions}
                        style={{ width: 200 }}
                        onChange={(leagues) => {
                          setOptions((previousOptions) => ({
                            ...previousOptions,
                            leagues,
                          }));
                        }}
                        treeCheckable
                        maxTagCount="responsive"
                      ></TreeSelect>
                    </div>
                  </div>
                </>
              )}
              {activeSection === "Auto Refresh" && (
                <>
                  <div style={{ marginRight: "1rem", width: 63 }}>
                    <div className={styles.formFieldTitle}>Alert</div>
                    <div>
                      <Switch
                        checked={options.alertEnabled}
                        style={{ marginTop: 5 }}
                        onChange={(alertEnabled) => {
                          setOptions((previousOptions) => ({
                            ...previousOptions,
                            alertEnabled,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.formFieldTitle}>Threshold</div>
                    <InputNumber
                      min={-100}
                      disabled={!options.alertEnabled}
                      value={options.alertThreshold}
                      addonAfter="%"
                      onChange={(alertThreshold) =>
                        setOptions((previousOptions) => ({
                          ...previousOptions,
                          alertThreshold,
                        }))
                      }
                      inputMode="numeric"
                      pattern="[0-9]*"
                      controls={false}
                      style={{ width: 90 }}
                    />
                  </div>
                </>
              )}
            </div>
            {isMoreShown && activeSection === "Filters" && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ margin: "0.5rem 0.5rem 0 0" }}>
                  <div className={styles.formFieldTitle} style={{ width: 134 }}>
                    Sort Order
                    <span
                      style={{ display: "inline-block", marginLeft: "0.5rem" }}
                    >
                      <Tooltip
                        title={
                          <div>
                            The order markets displayed
                            <br />
                            <br />
                            WARNING: Non default sort can result in degraded
                            performance if there are a lot of markets (ie Low
                            Hold)
                          </div>
                        }
                        trigger="click"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  </div>
                  <Select
                    value={options.sortOrder || "default"}
                    placeholder="Default"
                    style={{ width: 150 }}
                    onChange={(sortOrder) => {
                      setOptions((previousOptions) => {
                        return {
                          ...previousOptions,
                          sortOrder,
                        };
                      });
                    }}
                  >
                    <Option value="default">Default</Option>
                    <Option value="percent">Percent</Option>
                    <Option value="dollars">Dollars</Option>
                    <Option value="stayingPower">
                      Staying Power{" "}
                      <span
                        style={{
                          position: "relative",
                          top: -11,
                          left: -5,
                          fontSize: "50%",
                          color: "red",
                        }}
                      >
                        BETA
                      </span>
                    </Option>
                    {/* <Option value="expectedValue">Expected Value</Option> */}
                  </Select>
                </div>
                {options.sortOrder === "stayingPower" && (
                  <>
                    <div style={{ margin: "0.5rem 0.5rem 0 0" }}>
                      <div className={styles.formFieldTitle}>
                        Floor
                        <span
                          style={{
                            display: "inline-block",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <Tooltip
                            title={
                              <div>
                                Markets below this % will be deprioritized from
                                the staying power sort
                              </div>
                            }
                            trigger="click"
                            placement="right"
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                        </span>
                      </div>
                      <InputNumber
                        min={0}
                        value={options.stayingPowerFloor}
                        addonAfter="%"
                        onChange={(stayingPowerFloor) =>
                          setOptions((previousOptions) => ({
                            ...previousOptions,
                            stayingPowerFloor,
                          }))
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                        controls={false}
                        style={{ width: 90 }}
                      />
                    </div>
                    <div style={{ margin: "0.5rem 0.5rem 0 0" }}>
                      <div className={styles.formFieldTitle}>
                        Ceiling
                        <span
                          style={{
                            display: "inline-block",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <Tooltip
                            title={
                              <div>
                                Markets above this % will be prioritized higher
                                than the staying power sort
                              </div>
                            }
                            trigger="click"
                            placement="right"
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                        </span>
                      </div>
                      <InputNumber
                        min={0}
                        value={options.stayingPowerCeiling}
                        addonAfter="%"
                        onChange={(stayingPowerCeiling) =>
                          setOptions((previousOptions) => ({
                            ...previousOptions,
                            stayingPowerCeiling,
                          }))
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                        controls={false}
                        style={{ width: 90 }}
                      />
                    </div>
                  </>
                )}
                <div style={{ margin: "0.5rem 0.5rem 0 0" }}>
                  <div className={styles.formFieldTitle} style={{ width: 134 }}>
                    Other Odds
                  </div>
                  <Select
                    value={options.showOtherOdds || "toggle"}
                    placeholder="Toggle"
                    style={{ width: 100 }}
                    onChange={(showOtherOdds) => {
                      setOptions((previousOptions) => {
                        return {
                          ...previousOptions,
                          showOtherOdds,
                        };
                      });
                    }}
                  >
                    <Option value="toggle">Toggle</Option>
                    <Option value="show">Show</Option>
                    <Option value="none">None</Option>
                  </Select>
                </div>
              </div>
            )}
            {isMoreShown && (
              <>
                <div
                  className={styles.row}
                  style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                >
                  {activeSection === "Stake" && (
                    <>
                      {options.betType !== "freevalue" && (
                        <>
                          <div style={{ marginRight: "1rem" }}>
                            <div className={styles.formFieldTitle}>
                              Boost Book
                            </div>
                            <Select
                              style={{ width: 115 }}
                              value={options.boostBook}
                              onChange={(boostBook) => {
                                setOptions((previousOptions) => {
                                  return {
                                    ...previousOptions,
                                    boostBook,
                                  };
                                });
                              }}
                            >
                              {bookSelectOptions(availableBooksSelect)}
                            </Select>
                          </div>
                          <div>
                            <div className={styles.formFieldTitle}>
                              Boost Percent
                            </div>
                            <InputNumber
                              value={options.boostPercent}
                              min={0}
                              addonAfter="%"
                              onChange={(boostPercent) => {
                                setOptions((previousOptions) => {
                                  return {
                                    ...previousOptions,
                                    boostPercent,
                                  };
                                });
                              }}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              style={{ width: 90 }}
                            />
                          </div>
                        </>
                      )}
                      {options.betType === "freevalue" &&
                        ENABLE_RISK_FREE_AS_FREE_BET && (
                          <>
                            <div style={{ marginRight: "0.5rem" }}>
                              <div className={styles.formFieldTitle}>
                                Returned as Free Bet
                              </div>
                              <Switch
                                checked={options.freeValueReturnedAsFreeBet}
                                onChange={(freeValueReturnedAsFreeBet) =>
                                  setOptions((previousOptions) => {
                                    return {
                                      ...previousOptions,
                                      freeValueReturnedAsFreeBet,
                                    };
                                  })
                                }
                              />
                            </div>
                            {options.freeValueReturnedAsFreeBet && (
                              <div style={{ marginRight: "0.5rem" }}>
                                <div className={styles.formFieldTitle}>
                                  Expected Conversion
                                </div>
                                <InputNumber
                                  style={{ width: 100 }}
                                  min={0}
                                  size="small"
                                  addonAfter="%"
                                  value={options.expectedFreeBetConversion}
                                  onChange={(expectedFreeBetConversion) => {
                                    setOptions((previousOptions) => {
                                      return {
                                        ...previousOptions,
                                        expectedFreeBetConversion,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </>
                        )}
                    </>
                  )}
                  {activeSection === "Filters" && (
                    <>
                      <div>
                        <div className={styles.formFieldTitle}>Markets</div>
                        <Select
                          mode="multiple"
                          allowClear
                          showSearch
                          value={marketTypesValue}
                          style={{ width: 200 }}
                          onChange={(v) => setMarketTypes(v)}
                          placeholder="All"
                          maxTagCount="responsive"
                        >
                          {marketTypeSelectOptions.map(
                            (marketTypeSelectOption) => (
                              <Option
                                value={marketTypeSelectOption.value}
                                key={marketTypeSelectOption.value}
                              >
                                {marketTypeSelectOption.display}
                              </Option>
                            )
                          )}
                        </Select>
                      </div>
                    </>
                  )}
                  {activeSection === "Auto Refresh" && (
                    <>
                      <div style={{ marginRight: "1rem", width: 63 }}>
                        <div className={styles.formFieldTitle}>Alert 2</div>
                        <div>
                          <Switch
                            checked={options.alert2Enabled}
                            style={{ marginTop: 5 }}
                            onChange={(alert2Enabled) => {
                              setOptions((previousOptions) => ({
                                ...previousOptions,
                                alert2Enabled,
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className={styles.formFieldTitle}>Threshold 2</div>
                        <InputNumber
                          min={-100}
                          disabled={!options.alert2Enabled}
                          value={options.alert2Threshold}
                          addonAfter="%"
                          onChange={(alert2Threshold) =>
                            setOptions((previousOptions) => ({
                              ...previousOptions,
                              alert2Threshold,
                            }))
                          }
                          inputMode="numeric"
                          pattern="[0-9]*"
                          controls={false}
                          style={{ width: 90 }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {isMoreShown && (
              <>
                <div
                  className={styles.row}
                  style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                >
                  {activeSection === "Filters" && (
                    <>
                      <div style={{ marginTop: "0.5rem", marginRight: "1rem" }}>
                        <span style={{ marginRight: "0.25rem" }}>
                          Just Middles
                        </span>
                        <span>
                          <Checkbox
                            checked={options.middlesOnly}
                            onChange={({ target: { checked } }) => {
                              setOptions((previousOptions) => {
                                return {
                                  ...previousOptions,
                                  middlesOnly: checked,
                                };
                              });
                            }}
                          />
                        </span>
                      </div>
                      {options.middlesOnly && (
                        <div
                          style={{ marginTop: "0.5rem", marginRight: "1rem" }}
                        >
                          <Button
                            size="small"
                            type="dashed"
                            onClick={() => setShowMiddleFiltersModal(true)}
                          >
                            Middle Filters
                          </Button>
                        </div>
                      )}
                      <div style={{ marginTop: "0.5rem", marginRight: "1rem" }}>
                        <span style={{ marginRight: "0.5rem" }}>
                          Hide Player Props
                        </span>
                        <span>
                          <Checkbox
                            checked={options.hidePlayerProps}
                            onChange={({ target: { checked } }) => {
                              setOptions((previousOptions) => {
                                return {
                                  ...previousOptions,
                                  hidePlayerProps: checked,
                                };
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div style={{ marginTop: "0.5rem" }}>
                        <span style={{ marginRight: "0.5rem" }}>
                          Only Player Props
                        </span>
                        <span>
                          <Checkbox
                            checked={options.onlyPlayerProps}
                            onChange={({ target: { checked } }) => {
                              setOptions((previousOptions) => {
                                return {
                                  ...previousOptions,
                                  onlyPlayerProps: checked,
                                };
                              });
                            }}
                          />
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {isMoreShown && (
              <>
                <div
                  className={styles.row}
                  style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                >
                  {activeSection === "Filters" && (
                    <div>
                      <div className={styles.formFieldTitle}>
                        Main Lines Only
                      </div>
                      <Select
                        mode="multiple"
                        size="small"
                        style={{ width: 110 }}
                        value={options.mainLineOnlyBooks}
                        maxTagCount="responsive"
                        onChange={(mainLineOnlyBooks) =>
                          setOptions((previousOptions) => {
                            return {
                              ...previousOptions,
                              mainLineOnlyBooks,
                            };
                          })
                        }
                      >
                        {bookSelectOptions(availableBooksSelect, "small")}
                      </Select>
                    </div>
                  )}
                </div>
              </>
            )}
            {isMoreShown &&
              ["arbitrage", "lowhold"].includes(options.betType) && (
                <>
                  {activeSection === "Filters" && (
                    <div
                      className={styles.formFieldTitle}
                      style={{ fontSize: "0.9rem", marginTop: "0.75rem" }}
                    >
                      Expected Value
                    </div>
                  )}
                  <div className={styles.row} style={{ flexWrap: "wrap" }}>
                    {activeSection === "Filters" && (
                      <div>
                        <div
                          className={styles.formFieldTitle}
                          style={{ marginBottom: 0 }}
                        >
                          Calculation Books
                        </div>
                        <div>
                          <Select
                            mode="multiple"
                            size="small"
                            style={{ width: 175 }}
                            maxTagCount="responsive"
                            defaultValue={initialOptions.books}
                            value={options.expectedValueCalcBooks}
                            onChange={(expectedValueCalcBooks) => {
                              setOptions((previousOptions) => ({
                                ...previousOptions,
                                expectedValueCalcBooks,
                              }));
                            }}
                          >
                            {bookSelectOptions(availableBooksSelect, "small")}
                          </Select>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={styles.row} style={{ flexWrap: "wrap" }}>
                    {activeSection === "Filters" && (
                      <>
                        <div>
                          <div
                            className={styles.formFieldTitle}
                            style={{ marginBottom: 0 }}
                          >
                            Show
                          </div>
                          <Switch
                            size="small"
                            checked={options.showExpectedValue}
                            style={{ marginTop: 5 }}
                            onChange={(showExpectedValue) => {
                              setOptions((previousOptions) => ({
                                ...previousOptions,
                                showExpectedValue,
                              }));
                            }}
                          />
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                          <div
                            className={styles.formFieldTitle}
                            style={{ marginBottom: 0 }}
                          >
                            Sort
                          </div>
                          <Switch
                            size="small"
                            disabled={!options.showExpectedValue}
                            checked={options.sortByExpectedValue}
                            style={{ marginTop: 5 }}
                            onChange={(sortByExpectedValue) => {
                              setOptions((previousOptions) => ({
                                ...previousOptions,
                                sortByExpectedValue,
                              }));
                            }}
                          />
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                          <div
                            className={styles.formFieldTitle}
                            style={{ marginBottom: 0 }}
                          >
                            Book
                          </div>
                          <Select
                            size="small"
                            style={{ width: 100 }}
                            disabled={
                              !options.showExpectedValue ||
                              !options.sortByExpectedValue
                            }
                            defaultValue="any"
                            value={options.expectedValueSortBook}
                            onChange={(expectedValueSortBook) => {
                              setOptions((previousOptions) => ({
                                ...previousOptions,
                                expectedValueSortBook,
                              }));
                            }}
                          >
                            <Option value="any">Any</Option>
                            {bookSelectOptions(availableBooksSelect, "small")}
                          </Select>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            {isMoreShown && options.betType === "lowhold" && (
              <>
                <div
                  className={styles.row}
                  style={{ flexWrap: "wrap", justifyContent: "space-between" }}
                >
                  {activeSection === "Filters" && (
                    <div>
                      <div
                        className={styles.formFieldTitle}
                        style={{ fontSize: "0.75rem" }}
                      >
                        Odds Limits
                      </div>
                      {options.oddsRanges.map((oddsRange, i) => {
                        return (
                          <div
                            className={styles.row}
                            style={{
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                            }}
                            key={`${oddsRange.book}-${i}`}
                          >
                            <div style={{ marginRight: "0.5rem" }}>
                              <div className={styles.formFieldTitle}>Book</div>
                              <Select
                                size="small"
                                style={{ width: 100 }}
                                value={oddsRange.book}
                                onChange={(book) => {
                                  setOptions((po) => {
                                    const poc = deepCopy(po);
                                    poc.oddsRanges[i].book = book;
                                    return poc;
                                  });
                                }}
                              >
                                {bookSelectOptions(
                                  availableBooksSelect,
                                  "small"
                                )}
                              </Select>
                            </div>
                            <div style={{ marginRight: "0.5rem" }}>
                              <div className={styles.formFieldTitle}>Min</div>
                              <div>
                                <InputNumber
                                  placeholder="None"
                                  controls={false}
                                  size="small"
                                  value={oddsRange.min}
                                  onChange={(min) => {
                                    setOptions((po) => {
                                      const poc = deepCopy(po);
                                      poc.oddsRanges[i].min = min;
                                      return poc;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div>
                              <div className={styles.formFieldTitle}>Max</div>
                              <div>
                                <InputNumber
                                  placeholder="None"
                                  controls={false}
                                  size="small"
                                  value={oddsRange.max}
                                  onChange={(max) => {
                                    setOptions((po) => {
                                      const poc = deepCopy(po);
                                      poc.oddsRanges[i].max = max;
                                      return poc;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={styles.row}>
              <div className={styles.optionsRadioGroup}>
                {sections.length > 1 && (
                  <Radio.Group
                    options={sections}
                    onChange={(e) => setActiveSection(e.target.value)}
                    value={activeSection}
                    optionType="button"
                    style={{ whiteSpace: "nowrap" }}
                  />
                )}
              </div>
            </div>
            <div className={styles.row}>
              {"Stake" === activeSection &&
                (options.betType !== "freevalue" ||
                  ENABLE_RISK_FREE_AS_FREE_BET) && (
                  <MoreButton
                    isMoreShown={isMoreShown}
                    onClick={() => {
                      setIsMoreShown((p) => !p);
                    }}
                  />
                )}
              {("Filters" === activeSection ||
                "Auto Refresh" === activeSection) && (
                <MoreButton
                  isMoreShown={isMoreShown}
                  onClick={() => {
                    setIsMoreShown((p) => !p);
                  }}
                />
              )}
            </div>
            <div
              className={styles.row}
              style={{
                margin: "0.35rem 0 auto 0",
                justifyContent: "right",
              }}
            >
              {live ? (
                <div style={{ marginLeft: "auto" }}>
                  <TimerButtonControls
                    key={timerResetKey}
                    isPlaying={isPlaying}
                    onComplete={handleRefresh}
                    onRefreshClick={handleRefresh}
                    onPlayChange={handlePlayChange}
                    isRefreshing={isRefreshing}
                    duration={parseFloat(options.autoRefreshInterval)}
                    autoRefreshEnabled={options.autoRefreshEnabled}
                  />
                </div>
              ) : (
                <Button
                  type="primary"
                  onClick={handleRefresh}
                  loading={isRefreshing}
                >
                  Search
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ToolOptions;
