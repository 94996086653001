import React from "react";
import { Typography } from "antd";

const { Paragraph, Title } = Typography;
const EVCalculatorHelp = () => {
  return (
    <div>
      <Title level={3}>Calulator Help</Title>
      <Paragraph>
        This calculator is to evaluate EV% of boosts offered by the books.
      </Paragraph>
      <Title level={4}>Sharp odds</Title>
      <Paragraph>
        This section is where you will input the lines for each of the events
        involved in the boost. It is ideal to use Pinnacle odds as those are
        considered the sharpest; however, if Pinnacle does not offer a market,
        you may need to use US sportsbooks that offer the market. You need to
        enter all potential outcomes of a market in order to calculate the vig -
        so if it is a two way market, you will enter the outcome you are betting
        in the first input (furthest left), and the other outcomes to the right.
        If it is a 3 way (or more) market, click the "+ Outcome" button to add
        more legs.
      </Paragraph>
      <Paragraph>
        If the boost is a parlay, click the "+ Leg" button to add a second
        market to evaluate. This section can be tricky depending on the boost -
        see below where we've laid out specific examples.
      </Paragraph>
      <Title level={4}>Your odds</Title>
      <Paragraph>
        Enter the boosted odds you are getting. Once you have completed the top
        section, "Fair odds" will calculate the devigged odds per above, and
        based off of this the EV% will update. Typically, you are looking for
        boosts that are {">"}5% EV to be conservative.
      </Paragraph>
      <Title level={4}>Examples</Title>
      <Title level={5}>Single Bet</Title>
      <Paragraph>
        This is easy - just enter the odds for the outcome you want on the left
        and the odds for the other outcome on the right. Be cautious to ensure
        you know how many outcomes a market has - for example, a first half
        market has 3 outcomes (win, lose, tie).
      </Paragraph>
      <Title level={5}>Uncorrelated parlay (i.e. non-SGP)</Title>
      <Paragraph>
        Enter the odds for leg 1, then click "+ leg" and add all of the legs
        included in the parlay.
      </Paragraph>
      <Title level={5}>Correlated parlay (i.e. SGP)</Title>
      <Paragraph>
        These are the most challenging because there is correlation between the
        legs. If you take the same approach as #2, you will end up with the
        "uncorrelated" fair odds, which may be a useful data point if you know
        how to factor in the correlation (or you can use as a conservative
        benchmark for a clearly positivley correlated parlay).
      </Paragraph>
      <Paragraph>
        The other approach we like to use in this situation is to use a couple
        of books' SGP tools and evaluate all possible outcomes. For example, if
        the boost is Lebron and Giannis to each score 30+ points (in the same
        game), you would have a 4 way market to evaluate:
      </Paragraph>
      <Paragraph>
        <ul>
          <li>Lebron O29.5 + Giannis O29.5</li>
          <li>Lebron O29.5 + Giannis U29.5</li>
          <li>Lebron U29.5 + Giannis O29.5</li>
          <li>Lebron U29.5 + Giannis U29.5</li>
        </ul>
      </Paragraph>
      <Paragraph>
        If you punch in those 4 outcomes in the first line, that is a good way
        to evaluate this while accounting for correlation. Best practice is to
        check with a couple of books, since the SGP tools at these books are far
        from "sharp."
      </Paragraph>
      <Title level={4}>Other situations</Title>
      <Title level={5}>What if both legs of my outcome aren't offered?</Title>
      <Paragraph>
        If the outcome isn't offered at all, it's really challenging to
        evaluate. If only one leg is offered, (for example, if they offer 30+
        points but no corresponding under), one thing you can do it guestimate a
        ~10% margin (to be conservative) and see what that tells you. You can
        put in the opposite line until the margin shows as 10% and use the fair
        odds shown. Be wanred, a 10% margin is consevative for most single bet
        markets, but for parlays and especially SGPs it is likely too little.
      </Paragraph>
      <Title level={5}>What if the boost is an OR market?</Title>
      <Paragraph>
        If you ever took a stats class in school you probably don't remember
        this because no one does. Here is an example and the steps we take.
      </Paragraph>
      <Paragraph>The boost is "Bulls or Lakers to win".</Paragraph>
      <Paragraph>
        <ol>
          <li>
            Calculate the fair odds of the Bulls AND Lakers losing as outlined
            in uncorrelated parlay above
          </li>
          <li>
            Take the % probability output by the calculator in the fair odds
            section and subtract it from 100%. So if the fair probability of the
            Bulls AND Lakers losing is 60% then the probability of the Bulls OR
            Lakers winning is 40%
          </li>
          <li>
            Use{" "}
            <a href="https://www.aceodds.com/bet-calculator/odds-converter.html">
              this calculator
            </a>{" "}
            to convert the implied probability to the moneyline odds. In this
            example 40% is +150
          </li>
          <li>
            Come back to this calculator and put your odds in the first outcome
            field and the negative odds in the second outcome field. +150 and
            -150 in this example. This will force a fair odds of +150. Put your
            boosted odds in like normal and get the EV%.
          </li>
        </ol>
      </Paragraph>
      <Title level={5}>
        What about markets like "players combine to score"?
      </Title>
      <Paragraph>
        Honestly we don't have an easy way to evaluate these, if anyone knows
        let us know.
      </Paragraph>
    </div>
  );
};

export default EVCalculatorHelp;
