import React, { useEffect, useState } from "react";
import { Typography, Card, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { useTheme } from "../../../../../hooks";
import sanity from "../../../../../services/sanity";
import styles from "./FeaturedArticles.module.less";

const { Title } = Typography;

const NEW_AGE_IN_DAYS = 8.25;
const NEW_AGE = NEW_AGE_IN_DAYS * 24 * 60 * 60 * 1000;

const FeaturedArticles = () => {
  const [posts, setPosts] = useState({ data: [], status: "loading" });
  const theme = useTheme();

  useEffect(() => {
    async function fetchPosts() {
      const sanityPosts = await sanity.fetch(
        `*[_type == "post"] | 
        order(publishedAt desc){title, publishedAt, featured, slug, description, mainImage{asset->{_id,url},},categories[] -> {title, slug, color, shortName},}`
      );

      sanityPosts.sort((a, b) => {
        if (a.featured && !b.featured) return -1;
        else if (!a.featured && b.featured) return 1;
        return 0;
      });
      setPosts({ data: sanityPosts, status: "success" });
    }
    fetchPosts();
  }, []);

  return (
    <div className={styles.container}>
      <Title level={5}>FEATURED POSTS</Title>
      {posts.status === "loading" && (
        <>
          <Card className={styles.placeholderCard}>
            <Skeleton />
          </Card>
          <Card className={styles.placeholderCard}>
            <Skeleton />
          </Card>
          <Card className={styles.placeholderCard}>
            <Skeleton />
          </Card>
        </>
      )}
      {posts.status === "success" && (
        <>
          {posts.data.slice(0, 3).map((post) => {
            if (post.hide) return null;

            const now = new Date().getTime();
            const publishDateTime = new Date(post.publishedAt).getTime();

            const isNew = now - publishDateTime < NEW_AGE;

            return (
              <Link
                to={"/learn/" + post.slug.current}
                key={post.slug.current}
                className={styles.articleLink}
                style={{ color: theme.colorText }}
              >
                <Card
                  className={styles.postCard}
                  style={{ borderColor: isNew ? "#1da57a" : undefined }}
                >
                  <div className={styles.title}>
                    <span>{post.title}</span>
                    {isNew && <span className={styles.new}>NEW</span>}
                  </div>
                  <div className={styles.description}>{post.description}</div>
                  <div className={styles.description}>
                    {post.categories.map((c, i) => {
                      return (
                        <span
                          key={c.slug.current}
                          style={{
                            color: c.color,
                            padding: i > 0 ? "0 0.25rem" : "0 0.25rem 0 0",
                          }}
                        >
                          {c.shortName}
                        </span>
                      );
                    })}
                  </div>
                </Card>
              </Link>
            );
          })}
        </>
      )}
    </div>
  );
};

export default FeaturedArticles;
