import React, { useState, useEffect } from "react";
import { Steps, Typography, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ReadOutlined,
  MessageOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import getToken from "../../../../../utils/getToken";
import { putUserExperienceData } from "../../../../../state/ducks/user";
import styles from "./GettingStarted.module.less";

const { Title } = Typography;

const defaultExperienceData = {
  gettingStarted: { completedStepIds: [] },
};

const steps = [
  {
    title: "Learn",
    id: "learn",
    icon: <ReadOutlined />,
    Content: ({ user, dispatch, auth0 }) => {
      return (
        <>
          <div style={{ marginBottom: 20 }}>
            We highly encourage reading some of our articles before placing a
            single bet to maximize profit. You really should read all of them,
            but at least read the "Getting Started" category.
          </div>
          <div>
            <Link
              to="/learn?categories=getting-started-and-sign-up-bonuses"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                size="large"
                type="primary"
                onClick={async () => {
                  const experienceData = user.experienceData
                    ? JSON.parse(JSON.stringify(user.experienceData))
                    : defaultExperienceData;
                  if (
                    experienceData.gettingStarted.completedStepIds.indexOf(
                      "learn"
                    ) < 0
                  ) {
                    experienceData.gettingStarted.completedStepIds.push(
                      "learn"
                    );
                    const token = await getToken({ auth0 });
                    dispatch(putUserExperienceData({ token, experienceData }));
                  }
                }}
              >
                Getting Started
              </Button>
            </Link>
          </div>
        </>
      );
    },
  },
  {
    title: "Join Discord",
    id: "join-discord",
    icon: <MessageOutlined />,
    Content: ({ user, dispatch, auth0 }) => {
      return (
        <>
          <div style={{ marginBottom: 20 }}>
            We have a private Discord server where you can get alerts for the
            biggest arbs and chat other members about all things Bookie Beats.
          </div>
          <div>
            <Link to="/banter" target="_blank" rel="noopener noreferrer">
              <Button
                size="large"
                type="primary"
                onClick={async () => {
                  const experienceData = user.experienceData
                    ? JSON.parse(JSON.stringify(user.experienceData))
                    : defaultExperienceData;
                  if (
                    experienceData.gettingStarted.completedStepIds.indexOf(
                      "join-discord"
                    ) < 0
                  ) {
                    experienceData.gettingStarted.completedStepIds.push(
                      "join-discord"
                    );
                    const token = await getToken({ auth0 });
                    dispatch(putUserExperienceData({ token, experienceData }));
                  }
                }}
              >
                Join Discord
              </Button>
            </Link>
          </div>
        </>
      );
    },
  },
  {
    title: "Profit",
    id: "place-a-bet",
    icon: <DollarOutlined />,
    Content: ({ user, dispatch, auth0 }) => {
      return (
        <>
          <div style={{ marginBottom: 20 }}>
            Once you have a strategy use our tools and alerts to start making
            money.
          </div>
          <div>
            <Link to="/tools/pregame">
              <Button
                size="large"
                type="primary"
                style={{ margin: "0.5rem" }}
                onClick={async () => {
                  const experienceData = user.experienceData
                    ? JSON.parse(JSON.stringify(user.experienceData))
                    : defaultExperienceData;
                  if (
                    experienceData.gettingStarted.completedStepIds.indexOf(
                      "place-a-bet"
                    ) < 0
                  ) {
                    experienceData.gettingStarted.completedStepIds.push(
                      "place-a-bet"
                    );
                    const token = await getToken({ auth0 });
                    dispatch(putUserExperienceData({ token, experienceData }));
                  }
                }}
              >
                Pregame Bet Finder
              </Button>
            </Link>
            <Link to="/tools/live">
              <Button
                size="large"
                type="primary"
                style={{ margin: "0.5rem" }}
                onClick={async () => {
                  const experienceData = user.experienceData
                    ? JSON.parse(JSON.stringify(user.experienceData))
                    : defaultExperienceData;
                  if (
                    experienceData.gettingStarted.completedStepIds.indexOf(
                      "place-a-bet"
                    ) < 0
                  ) {
                    experienceData.gettingStarted.completedStepIds.push(
                      "place-a-bet"
                    );
                    const token = await getToken({ auth0 });
                    dispatch(putUserExperienceData({ token, experienceData }));
                  }
                }}
              >
                Live Bet Finder
              </Button>
            </Link>
          </div>
        </>
      );
    },
  },
];

const antdSteps = [];
steps.forEach((step) => {
  antdSteps.push({ title: step.title, id: step.id, icon: step.icon });
});

const GettingStarted = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const user = useSelector((state) => state.user.user);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    let userCurrentStep = 0;
    if (user?.experienceData?.gettingStarted?.completedStepIds) {
      let completedStepIndexes = [];
      user.experienceData.gettingStarted.completedStepIds.forEach(
        (completedStepId) => {
          const i = steps.findIndex((step) => step.id === completedStepId);
          if (i >= 0) completedStepIndexes.push(i);
        }
      );
      completedStepIndexes.sort();
      if (completedStepIndexes.length === 0) userCurrentStep = 0;
      else if (completedStepIndexes.length === 1) {
        if (completedStepIndexes[0] === 0) userCurrentStep = 1;
        else userCurrentStep = 0;
      } else {
        if (completedStepIndexes[0] !== 0) userCurrentStep = 0;
        else {
          let foundGap = false;
          for (let i = 1; i < completedStepIndexes.length; i++) {
            if (completedStepIndexes[i] - completedStepIndexes[i - 1] !== 1) {
              userCurrentStep = completedStepIndexes[i - 1] + 1;
              foundGap = true;
              break;
            }
          }
          if (!foundGap) userCurrentStep = steps.length - 1;
        }
      }
    }
    setCurrentStep(userCurrentStep);
  }, [user]);

  const Content = steps[currentStep].Content;

  return (
    <div className={styles.container}>
      <Title level={5}>GETTING STARTED</Title>
      <div className={styles.stepsContainer}>
        <Steps
          labelPlacement="vertical"
          current={currentStep}
          onChange={setCurrentStep}
          items={antdSteps}
        />
      </div>
      <div className={styles.content}>
        <Content user={user} dispatch={dispatch} auth0={auth0} />
      </div>
    </div>
  );
};

export default GettingStarted;
