import React from "react";

const Moon = ({ fill, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_1610_23" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8211 90.4118C52.4354 90.4118 74.8211 68.026 74.8211 40.4118C74.8211 29.967 71.6185 20.2703 66.1417 12.2499C62.7026 7.2136 66.0163 -0.821838 71.7071 1.3708C94.0021 9.96105 109.821 31.589 109.821 56.9118C109.821 89.7727 83.1821 116.412 50.3211 116.412C34.0111 116.412 19.2339 109.849 8.4858 99.2208C4.14366 94.927 10.1176 88.6056 16.1326 89.6595C18.9544 90.154 21.8577 90.4118 24.8211 90.4118Z"
      />
    </mask>
    <path
      d="M16.1326 89.6595L14.4067 99.5095L16.1326 89.6595ZM64.8211 40.4118C64.8211 62.5032 46.9125 80.4118 24.8211 80.4118V100.412C57.9582 100.412 84.8211 73.5488 84.8211 40.4118H64.8211ZM57.8835 17.8892C62.2616 24.3006 64.8211 32.0422 64.8211 40.4118H84.8211C84.8211 27.8918 80.9754 16.2399 74.4 6.61068L57.8835 17.8892ZM68.1117 10.7021C86.6764 17.8551 99.8211 35.8607 99.8211 56.9118H119.821C119.821 27.3174 101.328 2.06705 75.3024 -7.96052L68.1117 10.7021ZM99.8211 56.9118C99.8211 84.2499 77.6592 106.412 50.3211 106.412V126.412C88.7049 126.412 119.821 95.2956 119.821 56.9118H99.8211ZM50.3211 106.412C36.7492 106.412 24.4694 100.963 15.5172 92.1103L1.4544 106.331C13.9983 118.736 31.2731 126.412 50.3211 126.412V106.412ZM24.8211 80.4118C22.4387 80.4118 20.1129 80.2046 17.8584 79.8096L14.4067 99.5095C17.7959 100.103 21.2766 100.412 24.8211 100.412V80.4118ZM15.5172 92.1103C16.2656 92.8504 17.0635 94.3049 16.9961 96.135C16.9383 97.7058 16.2821 98.6346 16.0162 98.9446C15.6331 99.3913 15.1868 99.6462 14.4067 99.5095L17.8584 79.8096C11.0634 78.619 4.62257 81.5076 0.834345 85.9249C-1.13412 88.2203 -2.84566 91.4686 -2.99033 95.3994C-3.14453 99.5895 -1.4651 103.444 1.4544 106.331L15.5172 92.1103ZM74.4 6.61068C74.8577 7.28092 74.8057 7.81038 74.5529 8.34887C74.378 8.72157 73.7996 9.70097 72.3919 10.3983C70.752 11.2106 69.0975 11.0819 68.1117 10.7021L75.3024 -7.96052C71.4713 -9.43666 67.2685 -9.38312 63.5143 -7.52347C59.9922 -5.7788 57.7333 -2.88688 56.4485 -0.14987C53.9759 5.11727 53.9867 12.1826 57.8835 17.8892L74.4 6.61068Z"
      fill={fill}
      mask="url(#path-1-inside-1_1610_23)"
    />
  </svg>
);

const Sun = ({ fill, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1610_8)">
      <circle cx="59" cy="59.5" r="26.5" stroke={fill} strokeWidth="10" />
      <rect x="54.5" width="10" height="21" rx="5" fill={fill} />
      <rect x="54.5" y="98" width="10" height="21" rx="5" fill={fill} />
      <rect
        x="13.8916"
        y="20.9626"
        width="10"
        height="21"
        rx="5"
        transform="rotate(-45 13.8916 20.9626)"
        fill={fill}
      />
      <rect
        x="83.188"
        y="90.2592"
        width="10"
        height="21"
        rx="5"
        transform="rotate(-45 83.188 90.2592)"
        fill={fill}
      />
      <rect
        y="64.5"
        width="10"
        height="21"
        rx="5"
        transform="rotate(-90 0 64.5)"
        fill={fill}
      />
      <rect
        x="98"
        y="64.5001"
        width="10"
        height="21"
        rx="5"
        transform="rotate(-90 98 64.5001)"
        fill={fill}
      />
      <rect
        x="20.9626"
        y="105.108"
        width="10"
        height="21"
        rx="5"
        transform="rotate(-135 20.9626 105.108)"
        fill={fill}
      />
      <rect
        x="90.2593"
        y="35.812"
        width="10"
        height="21"
        rx="5"
        transform="rotate(-135 90.2593 35.812)"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1610_8">
        <rect width="119" height="119" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { Moon, Sun };
