import React, { useMemo } from "react";
import { Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Premium.module.less";

const { Title, Paragraph } = Typography;

const SECONDS_IN_DAY = 86400;
const PREMIUM_TRIAL_LENGTH_IN_SECONDS = 7 * SECONDS_IN_DAY;
const NOW = new Date().getTime() / 1000;

const Premium = () => {
  const user = useSelector((state) => state.user.user);

  const title = useMemo(() => {
    if (user.subscriptionLevel === "PREMIUM_TRIAL") return "PREMIUM TRIAL";
    else if (user.freePremiumDays) return "FREE PREMIUM DAYS";
    else return "UPGRADE TO PREMIUM";
  }, [user]);

  const premiumTrialDaysLeft = useMemo(() => {
    if (user.subscriptionLevel === "PREMIUM_TRIAL") {
      const trialEnd =
        user.subscriptions[0].current_period_start +
        PREMIUM_TRIAL_LENGTH_IN_SECONDS;
      return Math.round((trialEnd - NOW) / SECONDS_IN_DAY);
    } else {
      return 0;
    }
  }, [user]);

  return (
    <div>
      <Title level={5}>{title}</Title>
      <div>
        {user.subscriptionLevel === "PREMIUM_TRIAL" && (
          <>
            <Paragraph>
              You are currently on your free trial of our premium product that
              will end in{" "}
              <b>
                {premiumTrialDaysLeft} day
                {premiumTrialDaysLeft !== 1 ? "s" : ""}
              </b>
              . This gets you access to live odds and exlusive Discord channels.
            </Paragraph>
            <Paragraph>
              If you haven't yet check out our articles on live arbitrage and
              try out the live bet finder tool.
            </Paragraph>
            <div style={{ padding: "0.5rem 1rem" }}>
              <Link
                to="/learn?categories=live-arbitrage"
                style={{ width: "100%" }}
              >
                <Button block type="primary" className={styles.premiumButton}>
                  Live Articles
                </Button>
              </Link>
            </div>
            <div style={{ padding: "0.5rem 1rem" }}>
              <Link to="/tools/live">
                <Button block type="ghost" className={styles.premiumButton}>
                  Live Bet Finder
                </Button>
              </Link>
            </div>
          </>
        )}
        {user.subscriptionLevel !== "PREMIUM_TRIAL" &&
          user.freePremiumDays !== 0 && (
            <>
              <Paragraph>
                You have{" "}
                <b>
                  {user.freePremiumDays} premium day pass
                  {user.freePremiumDays !== 1 ? "es" : ""}
                </b>{" "}
                left. Premium day passes get you 24 hours of access to live odds
                and exclusive Discord channels.
              </Paragraph>
              <Paragraph>
                If you haven't yet check out our articles on live arbitrage and
                redeem your free passes.
              </Paragraph>
              <div style={{ padding: "0.5rem 1rem" }}>
                <Link to="/upgrade-to-premium" style={{ width: "100%" }}>
                  <Button block type="primary" className={styles.premiumButton}>
                    Redeem Passes
                  </Button>
                </Link>
              </div>
              <div style={{ padding: "0.5rem 1rem" }}>
                <Link to="/learn?categories=live-arbitrage">
                  <Button block type="ghost" className={styles.premiumButton}>
                    Live Articles
                  </Button>
                </Link>
              </div>
            </>
          )}
        {user.subscriptionLevel !== "PREMIUM_TRIAL" &&
          user.freePremiumDays === 0 && (
            <>
              <Paragraph>
                You’ve used all of your free premium days. Click below if you’d
                like to upgrade or purchase a day pass.
              </Paragraph>
              <div style={{ padding: "0.5rem 1rem" }}>
                <Link to="/upgrade-to-premium" style={{ width: "100%" }}>
                  <Button block type="primary" className={styles.premiumButton}>
                    Upgrade to Premium
                  </Button>
                </Link>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default Premium;
