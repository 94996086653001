import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { Typography, Card } from "antd";
import styles from "./Entry.module.less";

const { Title } = Typography;

const Entry = () => {
  const { state } = useLocation();
  if (!state) return <Redirect to="/super-bowl-prop-bet-leaderboard" />;
  const { name, answers, points } = state.entry;
  const correctAnswers = state.answers;
  return (
    <div className={styles.entryPage}>
      <Title level={3}>{name}</Title>
      <Title level={3}>Score: {points}</Title>
      {Object.keys(answers).map((question) => {
        const style = { margin: "0.25rem 0" };
        let isCorrect = false;
        let isSettled = false;
        if (correctAnswers[question] && correctAnswers[question].answer) {
          isSettled = true;
          console.log(correctAnswers[question].answer);
          if (correctAnswers[question].answer === "PUSH") {
            style.background = "rgb(205, 205, 205)";
          } else {
            if (correctAnswers[question].answer === answers[question]) {
              isCorrect = true;
              style.background = "#1da57a";
            } else {
              style.background = "#fe5454";
            }
          }
        }

        let score;
        if (isSettled) {
          score = !isCorrect ? 0 : correctAnswers[question].value;
        }

        return (
          <Card
            key={question}
            style={style}
            bodyStyle={{ padding: " 0.5rem 1rem" }}
          >
            <div className={styles.questionAndAnswer}>
              <div style={{ marginRight: "3rem" }}>
                <div className={styles.question}>{question}</div>
                <div className={styles.answer}>{answers[question]}</div>
              </div>
              <div style={{ marginLeft: "auto", fontSize: 22 }}>{score}</div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default Entry;
