import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputNumber, Button, Typography } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import {
  QuestionOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { setCurrentlyVisible } from "../../../state/ducks/modals";
import styles from "./Arbitrage.module.less";
import { getAlertPlays } from "../../../api";
import Market from "../../components/Market/Market";
import getToken from "../../../utils/getToken";

const { Text } = Typography;

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const formatTime = (date) => {
  const now = new Date().getTime();
  const update = date.getTime();
  const secondsAgo = ((now - update) / 1000).toFixed(2);
  return secondsAgo + "s ago";
};

const Arbitrage = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const user = useSelector((state) => state.user.user);
  const [arbitragePlaysStatus, setArbitragePlaysStatus] = useState("idle");
  const [arbitragePlays, setArbitragePlays] = useState([]);
  const [lastUpdated, setLastUpdated] = useState();
  const [dogStake, setDogStake] = useState(100);

  const initialFetchArbitragePlays = useCallback(async () => {
    setArbitragePlaysStatus("loading");
    try {
      const token = await getToken({ auth0 });
      const response = await getAlertPlays({
        token,
      });
      setArbitragePlays(response.arbitragePlays);
      setLastUpdated(response.lastUpdate);
      setArbitragePlaysStatus("succeeded");
    } catch (err) {
      setArbitragePlaysStatus("failed");
    }
  }, [auth0]);

  const fetchArbitragePlays = useCallback(async () => {
    setLastUpdated(undefined);
    setArbitragePlaysStatus("loading");
    try {
      const token = await getToken({ auth0 });
      const response = await getAlertPlays({
        token,
      });
      setArbitragePlays(response.arbitragePlays);
      setLastUpdated(response.lastUpdate);
      setArbitragePlaysStatus("succeeded");
    } catch (err) {
      setArbitragePlaysStatus("failed");
    }
  }, [auth0]);

  useEffect(() => {
    initialFetchArbitragePlays();
  }, [initialFetchArbitragePlays]);

  let filteredArbitragePlays = useMemo(() => {
    console.log(arbitragePlays);
    return arbitragePlays.filter((arbitragePlay) => {
      const { outcomes } = arbitragePlay;
      return (
        user.books.includes(outcomes[0].book) &&
        user.books.includes(outcomes[1].book)
      );
    });
  }, [arbitragePlays, user.books]);

  let list;
  if (arbitragePlaysStatus === "succeeded") {
    if (filteredArbitragePlays.length === 0) {
      list = <div className={styles.nothingMessage}>NOTHING RIGHT NOW</div>;
    } else {
      filteredArbitragePlays.forEach((arbitragePlay, i) => {
        const { outcomes } = arbitragePlay;
        const outcome0 = outcomes.find(
          (outcome) =>
            outcome.side === "Away" ||
            outcome.side === "Over" ||
            outcome.side === "Yes"
        );
        const outcome1 = outcomes.find(
          (outcome) =>
            outcome.side === "Home" ||
            outcome.side === "Under" ||
            outcome.side === "No"
        );

        let bet0, bet1, isBet1Underdog, profitDollars;
        if (dogStake) {
          const dogOutcome =
            outcome0.odds <= outcome1.odds ? outcome1 : outcome0;
          const favOutcome =
            outcome0.odds < outcome1.odds ? outcome0 : outcome1;
          const dogOdds = dogOutcome.odds;
          const dogArbPercent = (1 / dogOdds) * 100;
          const favArbPercent = (1 / favOutcome.odds) * 100;
          const totalArbPercent = dogArbPercent + favArbPercent;
          const dogPercentStake =
            arbitragePlay.profit !== "0.00"
              ? (100 * dogArbPercent) / arbitragePlay.profit
              : 100 * dogArbPercent;
          const favPercentStake =
            arbitragePlay.profit !== "0.00"
              ? (100 * favArbPercent) / arbitragePlay.profit
              : 100 * favArbPercent;
          const favStake = dogStake * (favPercentStake / dogPercentStake);
          isBet1Underdog =
            dogOutcome.side === "Home" ||
            dogOutcome.side === "Under" ||
            dogOutcome.side === "No";
          bet0 = isBet1Underdog ? dogStake : favStake;
          bet1 = isBet1Underdog ? favStake : dogStake;
          const totalStake = dogStake + favStake;
          profitDollars = (
            totalStake / (totalArbPercent / 100) -
            totalStake
          ).toFixed(2);
        }
        filteredArbitragePlays[i].bet0 = bet0;
        filteredArbitragePlays[i].bet1 = bet1;
        filteredArbitragePlays[i].profitDollars = profitDollars;
        filteredArbitragePlays[i].isBet1Underdog = isBet1Underdog;
        filteredArbitragePlays[i].dogStake = dogStake;
      });

      list = filteredArbitragePlays.map((arbitragePlay, i) => {
        return (
          <Market
            {...arbitragePlay}
            largeNumber={
              arbitragePlay.profitDollars
                ? usdFormatter.format(arbitragePlay.profitDollars)
                : "$-.--"
            }
            smallNumber={`${arbitragePlay.profit}%`}
            key={i}
          />
        );
      });
    }
  } else if (arbitragePlaysStatus === "loading") {
    list = (
      <div className={styles.nothingMessage}>
        <LoadingOutlined style={{ fontSize: 40 }} />
      </div>
    );
  }
  return (
    <div className={styles.container} style={{ paddingBottom: "1rem" }}>
      <div className={styles.inputContainer}>
        <div style={{ marginRight: "1rem" }}>
          <div style={{ fontSize: "0.6rem", fontWeight: 600, marginBottom: 2 }}>
            <Text>Dog Stake</Text>
          </div>
          <InputNumber
            min={0}
            placeholder="Stake"
            formatter={(value) =>
              `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            value={dogStake}
            onChange={(value) => setDogStake(value)}
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type="dashed"
            icon={<SyncOutlined />}
            shape="circle"
            onClick={() => fetchArbitragePlays()}
            className={styles.refreshButton}
          />
          <Button
            type="primary"
            icon={<QuestionOutlined />}
            shape="circle"
            onClick={() => dispatch(setCurrentlyVisible("ArbitrageHelp"))}
          />
        </div>
      </div>
      {lastUpdated && (
        <div style={{ textAlign: "right", fontSize: "0.5rem" }}>
          Last Update: {formatTime(new Date(lastUpdated))}
        </div>
      )}
      <div>{list}</div>
    </div>
  );
};

export default Arbitrage;
