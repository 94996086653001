import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircleOutlined } from "@ant-design/icons";
import styles from "./SignUp.module.less";
import { Typography, Button, Modal, Input } from "antd";
import config from "../../../config";
import { createCheckoutSession } from "../../../api";
import getToken from "../../../utils/getToken";

const stripe = window.Stripe(config.stripeKey);

const { Title } = Typography;

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const SignUpModal = ({
  isMoreModalOpen,
  setIsMoreModalOpen,
  inputEl,
  signUpMessage,
  signUpError,
  setSignUpError,
  setIsSignUpLoading,
  setSignUpMessage,
  isSignUpLoading,
}) => (
  <Modal
    open={isMoreModalOpen}
    onCancel={() => setIsMoreModalOpen(false)}
    footer={null}
  >
    <div className={styles.subscriptionContainer}>
      <div style={{ paddingTop: "1rem" }}>
        <div>Enter email to get started</div>
        <div style={{ padding: "0.5rem" }}>
          <Input
            size="large"
            placeholder="Email"
            style={{ maxWidth: 400 }}
            ref={inputEl}
            type="email"
            disabled={!!signUpMessage}
          />
        </div>
        {signUpError && (
          <div style={{ color: "red", paddingBottom: "0.5rem" }}>
            {signUpError}
          </div>
        )}
        {signUpMessage && (
          <div style={{ color: "#1da57a", paddingBottom: "0.5rem" }}>
            {signUpMessage}
          </div>
        )}
        {!signUpMessage && (
          <Button
            type="primary"
            onClick={async () => {
              setSignUpError(null);
              const email = inputEl.current.state.value;
              if (!email) {
                setSignUpError("Please enter your email");
              } else {
                if (validateEmail(email)) {
                  try {
                    setIsSignUpLoading(true);
                    await axios.post(
                      `${config.apiBaseDomain}/customer-invites`,
                      { email },
                      { headers: { Authorization: `Bearer DTPROMO` } }
                    );
                    setSignUpMessage(
                      "Check your inbox to continue account setup. Check your spam folder if you don't see it."
                    );
                  } catch (err) {
                    setSignUpError(
                      "An issue occured. Try again or come back later."
                    );
                  }
                  setIsSignUpLoading(false);
                } else {
                  setSignUpError("Please enter a valid email");
                }
              }
            }}
            loading={isSignUpLoading}
          >
            Start Free Trial
          </Button>
        )}
      </div>
    </div>
  </Modal>
);

const SignUp = () => {
  const auth0 = useAuth0();
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [signUpMessage, setSignUpMessage] = useState(null);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const inputEl = useRef(null);

  const signUpProps = {
    isMoreModalOpen,
    setIsMoreModalOpen,
    inputEl,
    signUpMessage,
    signUpError,
    setSignUpError,
    setIsSignUpLoading,
    setSignUpMessage,
    isSignUpLoading,
  };

  useEffect(() => {
    const signUpForSubscription = async () => {
      if (auth0.isAuthenticated && user.subscriptions.length === 0) {
        const token = getToken({ auth0 });
        const checkoutSession = await createCheckoutSession({ token });
        stripe.redirectToCheckout({ sessionId: checkoutSession.id });
      }
    };

    signUpForSubscription();
  }, [user, auth0]);

  return (
    <div className={styles.page}>
      <div className={styles.subscriptionContainer}>
        <Title level={2}>
          <span style={{ color: "#1da57a" }}>7 Day Free Trial</span> then
        </Title>
        <div className={styles.priceContainer}>
          <span className={styles.price}>$50</span>
          <span className={styles.pricePeriod}>/month</span>
        </div>
        <div className={styles.featuresContainer}>
          <div className={styles.feature}>
            <CheckCircleOutlined /> Arbitrage, low hold, and free value
            conversions for all of our supported books
          </div>
          <div className={styles.feature}>
            <CheckCircleOutlined /> Exclusive content to help you make the most
            money from promotions
          </div>
          <div className={styles.feature}>
            <CheckCircleOutlined /> Access to our private Discord server with
            alerts for the best arbs
          </div>
        </div>
        <div style={{ padding: "0.5rem" }}>
          <Button type="primary" onClick={() => setIsMoreModalOpen(true)}>
            Start Free Trial
          </Button>
        </div>
        <div>
          <div style={{ padding: "0.5rem" }}>Already a member?</div>
          <Button onClick={() => auth0.loginWithRedirect()}>Login</Button>
        </div>
      </div>
      <SignUpModal {...signUpProps} />
    </div>
  );
};

export default SignUp;
