import React from "react";
import { Typography, Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const { Title } = Typography;

const QuickLinks = ({ showAnnouncement, isUserPremium }) => {
  const user = useSelector((state) => state.user.user);

  const mdSpan = isUserPremium ? 6 : showAnnouncement ? 12 : 6;

  return (
    <div>
      <Title level={5}>QUICK LINKS</Title>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={24} md={mdSpan} style={{ textAlign: "center" }}>
          <Link to="/tools/pregame">
            <Button>Pregame Bet Finder</Button>
          </Link>
        </Col>
        {user.subscriptionLevel.includes("PREMIUM") && (
          <Col span={24} md={mdSpan} style={{ textAlign: "center" }}>
            <Link to="/tools/live">
              <Button>Live Bet Finder</Button>
            </Link>
          </Col>
        )}
        <Col span={24} md={mdSpan} style={{ textAlign: "center" }}>
          <Link to="/tools/pregame-alerts">
            <Button>Pregame Alerts</Button>
          </Link>
        </Col>
        <Col span={24} md={mdSpan} style={{ textAlign: "center" }}>
          <Link to="/calculators/Expected-Value">
            <Button>EV Calculator</Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default QuickLinks;
