import React, { useState, useEffect } from "react";

const Scoreboard = () => {
  const [gameState, setGameState] = useState();

  useEffect(() => {
    const ws = new WebSocket("wss://scoreboard.bookiebeats.com/ws");

    ws.onopen = () => {
      console.log("Connected to the WebSocket server");
      ws.send(
        JSON.stringify({
          action: "getActiveGames",
        })
      );
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.action === "activeGames") {
        console.log(data.games);
      }
      // setGameState(data.data); // Update the game state based on the received message
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("Disconnected from the WebSocket server");
    };

    return () => {
      ws.close();
    };
  }, []);

  if (!gameState) return <div style={{ padding: "1rem 0" }}>Loading...</div>;

  return (
    <div style={{ padding: "1rem 0" }}>
      <h2>Game Status</h2>
      {/* Displaying the game state */}
      <p>Home Team Score: {gameState.homeTeamScore || 0}</p>
      <p>Away Team Score: {gameState.awayTeamScore || 0}</p>
      {/* Add more fields as necessary */}
    </div>
  );
};

export default Scoreboard;
