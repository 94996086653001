/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  Divider,
  Row,
  Col,
  Modal,
  Form,
  Input,
  notification,
  Tooltip,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useTheme } from "../../../hooks";
import { signUpWithReferralCode } from "../../../api";
import styles from "./Landing.module.less";
import SignUpModal from "../SignUp/SignUpModal";
import Footer from "../../layout/Footer/Footer.component";
import moneyGear from "./images/money_gear.svg";
import moneyStack from "./images/money_stack.svg";
import moneyHand from "./images/money_hand.svg";
import moneyGearDark from "./images/money_gear_dark.svg";
import moneyStackDark from "./images/money_stack_dark.svg";
import moneyHandDark from "./images/money_hand_dark.svg";
import discordImage from "./images/discord_logo_wordmark_color.svg";
const { Title, Paragraph, Text } = Typography;
const LIMIT = true;

const featureSpan = { xs: 24, sm: 24, md: 24, lg: 8 };

const Landing = () => {
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false);
  const [isWaitlistEmailSending, setIsWaitlistEmailSending] = useState(false);
  const [urlReferralCode, setUrlReferralCode] = useState();
  const [signUpError, setSignUpError] = useState();
  const themeTokens = useTheme();
  const isDarkTheme = useSelector((state) => state.theme.isDark);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const referralCode = queryParameters.get("referralCode");
    if (referralCode) {
      setUrlReferralCode(referralCode);
    }
  }, []);

  console.log(urlReferralCode);

  return (
    <div
      className={styles.container}
      style={{ background: themeTokens.colorBgBase }}
    >
      {isMoreModalOpen && (
        <SignUpModal
          isMoreModalOpen={isMoreModalOpen}
          setIsMoreModalOpen={setIsMoreModalOpen}
        />
      )}
      <Modal
        open={isWaitlistModalOpen}
        onOk={() => setIsWaitlistModalOpen(false)}
        onCancel={() => setIsWaitlistModalOpen(false)}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ padding: "1.5rem 0" }}>
            {urlReferralCode ? (
              <div style={{ fontSize: "1rem" }}>
                Enter your email to receive an invite
              </div>
            ) : (
              <div style={{ fontSize: "1rem" }}>
                We will send you an email with a link with our waitlist
                application. You can bypass the waitlist with a valid referal
                code.
              </div>
            )}
          </div>
          <Form
            name="application-email"
            initialValues={{ referralCode: urlReferralCode }}
            onFinish={async ({ email, referralCode }) => {
              setIsWaitlistEmailSending(true);
              try {
                setSignUpError(null);
                const resp = await signUpWithReferralCode({
                  email,
                  referralCode,
                });
                console.log(resp);
                if (referralCode) {
                  notification.success({
                    message: "Check Your Inbox",
                    duration: 0,
                    description:
                      "An email has been sent to the email provided. Click the link in the email to continue your signup.",
                  });
                } else {
                  notification.success({
                    message: "Check Your Inbox",
                    duration: 0,
                    description:
                      "Your waitlist application has been sent to the email provided.",
                  });
                }
                setIsWaitlistModalOpen(false);
              } catch (err) {
                if (err?.response?.data?.message) {
                  setSignUpError(err.response.data.message);
                } else {
                  setSignUpError("An error occurred. Please try again.");
                }
              }
              setIsWaitlistEmailSending(false);
            }}
            validateTrigger="onSubmit"
            validateMessages={{
              required: "${label} is required",
              types: {
                email: "${label} is not a valid email",
              },
            }}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="referralCode"
              label="Referral Code"
              rules={[{ type: "text" }]}
              style={{ display: urlReferralCode ? "none" : "" }}
            >
              {urlReferralCode ? <Input disabled /> : <Input />}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isWaitlistEmailSending}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          {signUpError && (
            <div style={{ color: "red", paddingBottom: "1rem" }}>
              {signUpError}
            </div>
          )}
          <div style={{ fontSize: "0.75rem", opacity: 0.75 }}>
            (Be sure to check your spam folder, sometimes our emails get lost
            there. If it does go to spam please mark it as "not spam" so future
            emails will be unaffected.)
          </div>
        </div>
      </Modal>
      <div>
        <div
          className={styles.header}
          style={{ background: themeTokens.linearGradientBg }}
        >
          <div className={styles.headerHalf}>
            <Title className={styles.title}>Risk free winning</Title>
            <Paragraph className={styles.subtitle}>
              Wait! It sounds too good to be true, but with our tool, it's
              entirely possible! Discover the power of betting with the market's
              most advanced arbitrage tools. Specializing in real-time
              technology for live arbitrage, we offer unrivaled opportunities to
              maximize your profits.
            </Paragraph>
            <Paragraph className={styles.subtitle}>
              Our system analyzes lines for both pregame and live events in real
              time, ensuring you're equipped to seize every advantage. With our
              tools for arbitrage, risk-free bets, and low hold plays, you'll
              stay ahead of the game. Plus, gain exclusive access to our private
              Discord server for the fastest market alerts. Sign up now and
              elevate your betting strategy to professional levels!
            </Paragraph>
            <Paragraph className={styles.subtitle}>
              <b>Sign up now and start winning!</b>
            </Paragraph>
          </div>
          <div
            className={styles.headerHalf}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className={styles.signUpContainer}
              style={{ background: themeTokens.colorBgContainer }}
            >
              <Title level={2} style={{ marginBottom: 0 }}>
                <span style={{ color: "#1da57a" }}>7 Day Free Trial</span>
              </Title>
              <Text>
                <div className={styles.priceContainer}>
                  <span className={styles.pricePeriod}>then </span>
                  <span className={styles.price}>$50</span>
                  <span className={styles.pricePeriod}>
                    {" "}
                    first premium month
                  </span>
                </div>
                <div
                  className={styles.priceContainer}
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    opacity: 0.75,
                    fontSize: "0.75rem",
                  }}
                >
                  <span>then </span>
                  <Tooltip
                    title={
                      <div>
                        <div>$125/month for basic membership</div>
                        <div>$275/month additional for permium</div>
                      </div>
                    }
                  >
                    <span>
                      <span style={{ fontSize: "1rem" }}>$125</span>
                      <span>
                        /month<sup>ⓘ</sup>
                      </span>
                    </span>
                  </Tooltip>
                </div>
                <div className={styles.featuresContainer}>
                  <div className={styles.feature}>
                    <CheckCircleOutlined /> Arbitrage and more for all of our
                    supported books
                  </div>
                  <div className={styles.feature}>
                    <CheckCircleOutlined /> Exclusive content to help you make
                    the most money from promotions
                  </div>
                  <div className={styles.feature}>
                    <CheckCircleOutlined /> Access to our private Discord server
                    with alerts for the best arbs
                  </div>
                </div>
                <div style={{ padding: "1rem" }}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      if (LIMIT) {
                        setIsWaitlistModalOpen(true);
                      } else {
                        setIsMoreModalOpen(true);
                      }
                    }}
                  >
                    {LIMIT && !urlReferralCode
                      ? "Join Our Waitlist"
                      : "Start 7 Day Free Trial"}
                  </Button>
                </div>
              </Text>
            </div>
          </div>
        </div>
        <div>
          <Text>
            <div className={styles.featuresTitle}>What Do We Do?</div>
            <div className={styles.features}>
              <Row>
                <Col className={styles.smallFeature} {...featureSpan}>
                  <div className={styles.featureTitle}>ARBITRAGE</div>
                  <div>
                    <img
                      src={isDarkTheme ? moneyStackDark : moneyStack}
                      height={100}
                      alt=""
                    />
                  </div>
                  <div className={styles.featureDescription}>
                    Make bets on every outcome of a single market and make
                    guaranteed profit.
                  </div>
                  <Divider className={styles.smallFeatureDivider} />
                </Col>
                <Col className={styles.smallFeature} {...featureSpan}>
                  <div className={styles.featureTitle}>FREE VALUE</div>
                  <div>
                    <img
                      src={isDarkTheme ? moneyHandDark : moneyHand}
                      height={100}
                      alt=""
                    />
                  </div>
                  <div className={styles.featureDescription}>
                    When the books give you free money, make the most of it.
                  </div>
                  <Divider className={styles.smallFeatureDivider} />
                </Col>
                <Col className={styles.smallFeature} {...featureSpan}>
                  <div className={styles.featureTitle}>LOW HOLD</div>
                  <div>
                    <img
                      src={isDarkTheme ? moneyGearDark : moneyGear}
                      height={100}
                      alt=""
                    />
                  </div>
                  <div className={styles.featureDescription}>
                    Need to make a bet to qualify for a promo but don't really
                    want to gamble? We have you covered.
                  </div>
                  <Divider className={styles.smallFeatureDivider} />
                </Col>
                <Col className={styles.largeFeature} span={24}>
                  <img src={discordImage} alt="Discord" />
                  <div className={styles.featureDescription}>
                    <p>
                      In today's world, even emails feel slow. That's why we
                      created a private Discord server. Our alerts for big
                      opportunities are the fastest in the game, enabling you to
                      beat all of the other arbers to the punch.
                    </p>
                    <p>
                      On top of our best in class alerts, gain access to our
                      thriving community where you can ask questions, brag about
                      big wins, or even talk about REAL GAMBLING!
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Text>
        </div>
      </div>
      <Footer maxWidth={false} />
    </div>
  );
};

export default Landing;
