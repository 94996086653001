import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import styles from "./Footer.module.less";

const Footer = ({ maxWidth }) => {
  if (maxWidth === undefined) maxWidth = true;
  return (
    <Layout.Footer style={{ margin: "auto", color: "gray" }}>
      <div
        className={styles.content}
        style={{ maxWidth: maxWidth ? undefined : 100000000 }}
      >
        <div style={{ marginBottom: "0.5rem" }}>
          © {new Date().getFullYear()} Long Horizons LLC
        </div>
        <div>
          <Link to="/contact">Contact Us</Link>
          {" - "}
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          {" - "}
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default Footer;
