import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./SignUp.module.less";
import { Button, Modal, Input } from "antd";
import config from "../../../config";
import { createCheckoutSession } from "../../../api";
import getToken from "../../../utils/getToken";

const stripe = window.Stripe(config.stripeKey);

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const SignUpModal = ({ isMoreModalOpen, setIsMoreModalOpen }) => {
  const auth0 = useAuth0();
  const [signUpError, setSignUpError] = useState(null);
  const [signUpMessage, setSignUpMessage] = useState(null);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const inputEl = useRef(null);

  useEffect(() => {
    const signUpForSubscription = async () => {
      if (auth0.isAuthenticated && user && user.subscriptions.length === 0) {
        const token = await getToken({ auth0 });
        const checkoutSession = await createCheckoutSession({ token });
        stripe.redirectToCheckout({ sessionId: checkoutSession.id });
      }
    };

    signUpForSubscription();
  }, [user, auth0]);

  return (
    <Modal
      open={isMoreModalOpen}
      onCancel={() => setIsMoreModalOpen(false)}
      footer={null}
    >
      <div className={styles.subscriptionContainer}>
        <div style={{ paddingTop: "1rem" }}>
          <div>Enter email to get started</div>
          <div style={{ padding: "0.5rem" }}>
            <Input
              size="large"
              placeholder="Email"
              style={{ maxWidth: 400 }}
              ref={inputEl}
              type="email"
              disabled={!!signUpMessage}
            />
          </div>
          {signUpError && (
            <div style={{ color: "red", paddingBottom: "0.5rem" }}>
              {signUpError}
            </div>
          )}
          {signUpMessage && (
            <div style={{ color: "#1da57a", paddingBottom: "0.5rem" }}>
              {signUpMessage}
            </div>
          )}
          {!signUpMessage && (
            <Button
              type="primary"
              onClick={async () => {
                setSignUpError(null);
                const email = inputEl.current.state.value;
                if (!email) {
                  setSignUpError("Please enter your email");
                } else {
                  if (validateEmail(email)) {
                    try {
                      setIsSignUpLoading(true);
                      await axios.post(
                        `${config.apiBaseDomain}/customer-invites`,
                        { email },
                        { headers: { Authorization: `Bearer DTPROMO` } }
                      );
                      setSignUpMessage(
                        "Check your inbox to continue account setup. Check your spam folder if you don't see it."
                      );
                    } catch (err) {
                      setSignUpError(
                        "An issue occured. Try again or come back later."
                      );
                    }
                    setIsSignUpLoading(false);
                  } else {
                    setSignUpError("Please enter a valid email");
                  }
                }
              }}
              loading={isSignUpLoading}
            >
              Start Free Trial
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
