import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "../config";

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    cacheLocation: "localstorage",
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    useRefreshTokens: true,
    onRedirectCallback: onRedirectCallback,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export default Auth0ProviderWithHistory;
