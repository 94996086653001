import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "./../../../services/sanity";

import { PortableText } from "@portabletext/react";
import { Typography, Divider, Row, Col, Skeleton } from "antd";
import logo from "./../../../../src/logo.svg";
import TableOfContents from "./components/TableOfContents";

import serializers from "./components/serializers.component";
import styles from "./Articles.module.less";

const { Title, Text } = Typography;

const get = (object, path) => path.reduce((prev, curr) => prev[curr], object);
const getObjectPath = (path) =>
  path.length === 0
    ? path
    : ["subheadings"].concat(path.join(".subheadings.").split("."));

const parseOutline = (headings) => {
  const outline = { subheadings: [] };
  const path = [];
  let lastLevel = 0;

  headings.forEach((heading) => {
    const level = Number(heading.style.slice(1));
    heading.subheadings = [];

    if (level < lastLevel) for (let i = lastLevel; i >= level; i--) path.pop();
    else if (level === lastLevel) path.pop();

    const prop = get(outline, getObjectPath(path));
    prop.subheadings.push(heading);
    path.push(prop.subheadings.length - 1);
    lastLevel = level;
  });

  return outline.subheadings;
};

// builder is used to create the URL for a given block image from a post

// function called when rendering the article body via PortableText
// add on new types as needed such as tables, collapsable, etc.

export default function SingleArticle() {
  const [singleArticle, setSingleArticle] = useState();
  const { slug } = useParams();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  // GROQ to fetch the sanity blog data for this post
  useEffect(() => {
    async function fetchData() {
      const articles = await sanityClient.fetch(
        `*[slug.current == "${slug}"]{
        title,
        _id,
        slug,
        mainImage{
            asset->{
                _id,
                url
            }
        },
       
        body[]{
            ...,
            markDefs[]{
              ...,
              _type == "internalLink" => {
                "handle": @.reference->slug
              },
              _type == "color" => {
                  hex
              },
            }
          },
        showTableOfContents,
        "headings": body[length(style) == 2 && string::startsWith(style, "h")],
        "name": author->name,
        "authorImage": author->image,
        video,
        collapse,
        publishedAt
    }`,
        { slug }
      );

      setSingleArticle(articles[0]);
    }
    fetchData();
  }, [slug]);

  if (!singleArticle) return <Skeleton />;
  const publishedDate = new Date(singleArticle.publishedAt);

  console.log(singleArticle.body);

  return (
    <main style={{ padding: "0 1rem" }}>
      <article>
        <header>
          <Row justify="center" align="middle">
            <Col flex>
              <Title>{singleArticle.title}</Title>
              <div>
                <Text strong underline>
                  Published:{" "}
                  {publishedDate.toLocaleDateString("en-us", options)}
                </Text>
                <Divider>
                  <img src={logo} alt="logo" height="25px" width="25px" />
                </Divider>
              </div>
            </Col>
          </Row>
          {singleArticle.showTableOfContents && (
            <Row justify="center" className={styles.tableOfContents}>
              <Col span={20} flex="auto">
                <Title level={5}>Table of Contents</Title>
                <TableOfContents
                  outline={parseOutline(singleArticle.headings)}
                  affix={false}
                />
              </Col>
            </Row>
          )}
        </header>
        <Row justify="center" gutter={24}>
          {singleArticle.showTableOfContents && (
            <Col
              className={styles.largeTableOfContents}
              lg={{ span: 6, order: 1 }}
              flex="auto"
            >
              <TableOfContents outline={parseOutline(singleArticle.headings)} />
            </Col>
          )}
          <Col
            lg={{ span: singleArticle.showTableOfContents ? 18 : 20, order: 2 }}
            flex="auto"
          >
            <Typography.Text>
              <PortableText
                value={singleArticle.body}
                components={serializers}
              />
            </Typography.Text>
          </Col>
        </Row>
      </article>
    </main>
  );
}
