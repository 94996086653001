import React from "react";
import { Button, Typography } from "antd";
import {
  ThunderboltTwoTone,
  MessageTwoTone,
  HourglassTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentlyVisible } from "../../../state/ducks/modals";

const { Title, Text } = Typography;

const UpgradeToPremium = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const isAlreadyPremium =
    !user ||
    !user.subscriptionLevel ||
    user.subscriptionLevel.includes("PREMIUM");

  return (
    <div style={{ textAlign: "center", padding: "1rem" }}>
      <Title level={2}>
        {isAlreadyPremium ? "You have Premium Access" : "Premium Access"}
      </Title>
      <Text>
        <div
          style={{
            marginBottom: "2rem",
            fontSize: "1.25rem",
            textAlign: "center",
          }}
        >
          <div style={{ textAlign: "left", display: "inline-block" }}>
            <div style={{ lineHeight: "1rem" }}>
              <ThunderboltTwoTone
                twoToneColor="#f8a62c"
                style={{
                  fontSize: "1.5rem",
                  display: "inline-block",
                  marginRight: "1rem",
                }}
              />{" "}
              Live Odds
            </div>
            <div style={{ marginTop: "1.5rem", lineHeight: "1rem" }}>
              <MessageTwoTone
                twoToneColor="#f8a62c"
                style={{
                  fontSize: "1.5rem",
                  display: "inline-block",
                  marginRight: "1rem",
                }}
              />{" "}
              Live Alert Discord Channel
            </div>
            <div style={{ marginTop: "1.5rem", lineHeight: "1rem" }}>
              <HourglassTwoTone
                twoToneColor="#f8a62c"
                style={{
                  fontSize: "1.5rem",
                  display: "inline-block",
                  marginRight: "1rem",
                }}
              />{" "}
              More in the Works
            </div>
          </div>
        </div>
      </Text>
      {!isAlreadyPremium && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "2rem" }}>
            <Button
              style={{
                backgroundColor: isAlreadyPremium ? undefined : "#f8a62c",
                borderColor: isAlreadyPremium ? undefined : "#f8a62c",
              }}
              type="primary"
              disabled={isAlreadyPremium}
              onClick={() => {
                dispatch(setCurrentlyVisible("PremiumUpgradeCheckout"));
              }}
              size="large"
            >
              Upgrade Subscription
            </Button>
          </div>
          <div>
            <Button
              style={{
                color: isAlreadyPremium ? undefined : "#f8a62c",
                borderColor: isAlreadyPremium ? undefined : "#f8a62c",
              }}
              type="ghost"
              disabled={isAlreadyPremium}
              size="large"
              onClick={() => {
                dispatch(setCurrentlyVisible("DailyPassCheckout"));
              }}
            >
              Daily Pass
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpgradeToPremium;
