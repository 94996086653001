import React from "react";
import { Typography } from "antd";
import { PortableText } from "@portabletext/react";
import styles from "./Announcement.module.less";
import serializers from "../../../Articles/components/serializers.component";

const { Title } = Typography;

const Announcement = ({ announcement }) => {
  return (
    <div className={styles.container}>
      <Title level={5} style={{ textTransform: "uppercase" }}>
        {announcement.title}
      </Title>
      <div>
        <PortableText value={announcement.body} components={serializers} />
      </div>
    </div>
  );
};

export default Announcement;
