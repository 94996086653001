import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Divider, Button, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { getUpgradeInvoice, upgradeSubscription } from "../../../api";
import { fetchUser } from "../../../state/ducks/user";
import { setCurrentlyVisible } from "../../../state/ducks/modals";
import getToken from "../../../utils/getToken";

const { Paragraph, Title } = Typography;

const usd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const PremiumUpgradeCheckout = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();

  const [invoice, setInvoice] = useState({ state: "loading" });
  const [upgradeSubscriptionState, setUpgradeSubscriptionState] =
    useState("idle");

  useEffect(() => {
    const fetchUpgradeInvoice = async () => {
      const token = await getToken({ auth0 });
      const data = await getUpgradeInvoice({ token });
      setInvoice({ state: "success", data: data.invoice });
    };

    fetchUpgradeInvoice();
    return () => {
      setInvoice({}); // fixed memory leak for some reason
    };
  }, [auth0]);

  let unusedBasicTimeAmount = 0;
  let premiumPlanPrice;
  if (invoice.data) {
    invoice.data.lines.data.forEach((line) => {
      if (line.amount < 0) unusedBasicTimeAmount = line.amount;
      if (line.amount > 0) premiumPlanPrice = line.amount;
    });
  }

  if (upgradeSubscriptionState === "success") {
    return (
      <div style={{ textAlign: "center" }}>
        <Title level={3}>Welcome to Premium</Title>
      </div>
    );
  }

  return (
    <div>
      <Title level={3}>Checkout</Title>
      {invoice.state === "loading" ? (
        <div
          style={{
            height: 120,
            padding: "1rem 1.5rem",
            backgroundColor: "rgba(1,1,1,0.05)",
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <Skeleton active />
        </div>
      ) : (
        <div>
          <div
            style={{
              padding: "1rem 1.5rem",
              backgroundColor: "rgba(1,1,1,0.05)",
              borderRadius: 5,
            }}
          >
            <div style={{ display: "flex", fontWeight: 400, fontSize: "1rem" }}>
              <div>Description</div>
              <div style={{ marginLeft: "auto" }}>Price</div>
            </div>
            <Divider style={{ margin: "0.25rem 0" }} />
            <div
              style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
            >
              <div>Premium Plan Monthly</div>
              <div style={{ marginLeft: "auto" }}>
                {usd.format(premiumPlanPrice / 100)}
              </div>
            </div>
            <div
              style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
            >
              <div>Unused Subscription</div>
              <div style={{ marginLeft: "auto" }}>
                {usd.format(unusedBasicTimeAmount / 100)}
              </div>
            </div>
            {invoice.data.discount && (
              <div
                style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
              >
                <div>{invoice.data.discount.coupon.name}</div>
                <div style={{ marginLeft: "auto" }}>
                  {usd.format(-invoice.data.discount.coupon.amount_off / 100)}
                </div>
              </div>
            )}
            {invoice.data.starting_balance < 0 && (
              <div
                style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
              >
                <div>Current Invoice Balance</div>
                <div style={{ marginLeft: "auto" }}>
                  {usd.format(invoice.data.starting_balance / 100)}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                fontWeight: 600,
                fontSize: "1rem",
                marginTop: "1rem",
              }}
            >
              <div>Total</div>
              <div style={{ marginLeft: "auto" }}>
                {usd.format(invoice.data.amount_due / 100)}
              </div>
            </div>
            {invoice.data.ending_balance < 0 && (
              <div
                style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
              >
                <div>Remaining Invoice Balance</div>
                <div style={{ marginLeft: "auto" }}>
                  {usd.format(invoice.data.ending_balance / 100)}
                </div>
              </div>
            )}
          </div>
          <div style={{ padding: "1rem 0" }}>
            <Paragraph style={{ fontSize: "0.75rem" }}>
              Upon clicking "Confirm" the total amount shown will immediatley be
              charged to your card on file for the first month. Your billing
              cycle will be reset to begin today and your new monthly price
              after the first month will be the "Premium Plan Monthly" price
              show above.
            </Paragraph>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              size="large"
              loading={upgradeSubscriptionState === "loading"}
              disabled={upgradeSubscriptionState === "loading"}
              onClick={async () => {
                setUpgradeSubscriptionState("loading");
                try {
                  let token = await getToken({ auth0 });
                  await upgradeSubscription({
                    token,
                    prorationDate: invoice.data.subscription_proration_date,
                  });
                  token = await getToken({ auth0, ignoreCache: true });
                  dispatch(fetchUser({ token }));
                  dispatch(setCurrentlyVisible(undefined));
                } catch (err) {
                  console.error(err);
                  setUpgradeSubscriptionState("error");
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PremiumUpgradeCheckout;
