import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Button, Divider, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { purchaseDayPass, getDayPassInvoice } from "../../../api";
import { fetchUser } from "../../../state/ducks/user";
import { setCurrentlyVisible } from "../../../state/ducks/modals";
import getToken from "../../../utils/getToken";

const { Paragraph, Title } = Typography;

const usd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const DailyPassCheckout = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const auth0 = useAuth0();
  const [invoice, setInvoice] = useState({ state: "loading" });
  const [purchasingState, setPurchasingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const fetchUpgradeInvoice = async () => {
      const token = await getToken({ auth0 });
      const data = await getDayPassInvoice({ token });
      setInvoice({ state: "success", data: data.invoice });
    };

    fetchUpgradeInvoice();
    return () => {
      setInvoice({}); // fixed memory leak for some reason
    };
  }, [auth0]);

  let body;
  if (user.freePremiumDays && user.freePremiumDays > 0) {
    body = (
      <div>
        <Paragraph style={{ fontSize: 16 }}>
          You currently have{" "}
          <span style={{ fontSize: 26, color: "rgb(248, 166, 44)" }}>
            {user.freePremiumDays}
          </span>{" "}
          free days remaining
        </Paragraph>
        <Paragraph>
          By cliking "Redeem" you will use one free day and gain access to all
          premium features for 24 hours
        </Paragraph>
        <div style={{ textAlign: "center", padding: "1rem" }}>
          <Button
            type="primary"
            size="large"
            loading={purchasingState === "loading"}
            disabled={purchasingState === "loading"}
            onClick={async () => {
              setPurchasingState("loading");
              try {
                let token = await getToken({ auth0 });
                await purchaseDayPass({
                  token,
                  useFree: true,
                });
                setPurchasingState("success");
                token = await getToken({ auth0, ignoreCache: true });
                dispatch(fetchUser({ token }));
                dispatch(setCurrentlyVisible(undefined));
              } catch (err) {
                console.error(err);
                setPurchasingState("error");
              }
            }}
          >
            Redeem
          </Button>
        </div>
        {purchasingState === "error" && (
          <Paragraph style={{ color: "red", textAlign: "center" }}>
            There was an issue redeeming your free daily pass. Try again or
            contact us.
          </Paragraph>
        )}
      </div>
    );
  } else {
    if (invoice.state === "loading") {
      body = (
        <div
          style={{
            padding: "1rem 1.5rem 0",
            backgroundColor: "rgba(1,1,1,0.05)",
            borderRadius: 5,
          }}
        >
          <Skeleton active />
        </div>
      );
    } else {
      body = (
        <div>
          <div
            style={{
              padding: "1rem 1.5rem",
              backgroundColor: "rgba(1,1,1,0.05)",
              borderRadius: 5,
            }}
          >
            <div style={{ display: "flex", fontWeight: 400, fontSize: "1rem" }}>
              <div>Description</div>
              <div style={{ marginLeft: "auto" }}>Price</div>
            </div>
            <Divider style={{ margin: "0.25rem 0" }} />
            <div
              style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
            >
              <div>Day Pass</div>
              <div style={{ marginLeft: "auto" }}>
                {usd.format(invoice.data.total / 100)}
              </div>
            </div>
            {invoice.data.starting_balance < 0 && (
              <div
                style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
              >
                <div>Current Invoice Balance</div>
                <div style={{ marginLeft: "auto" }}>
                  {usd.format(invoice.data.starting_balance / 100)}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                fontWeight: 600,
                fontSize: "1rem",
                marginTop: "1rem",
              }}
            >
              <div>Total</div>
              <div style={{ marginLeft: "auto" }}>
                {usd.format(invoice.data.amount_due / 100)}
              </div>
            </div>
            {invoice.data.ending_balance < 0 && (
              <div
                style={{ display: "flex", fontWeight: 400, fontSize: "0.9rem" }}
              >
                <div>Remaining Invoice Balance</div>
                <div style={{ marginLeft: "auto" }}>
                  {usd.format(invoice.data.ending_balance / 100)}
                </div>
              </div>
            )}
          </div>
          <Paragraph style={{ padding: "1rem" }}>
            Upon clicking "Confirm" the total amount shown will immediatley be
            charged to your card on file and you will gain access to all premium
            features for 24 hours.
          </Paragraph>
          <div style={{ textAlign: "center", padding: "1rem" }}>
            <Button
              type="primary"
              size="large"
              loading={purchasingState === "loading"}
              disabled={purchasingState === "loading"}
              onClick={async () => {
                setPurchasingState("loading");
                try {
                  let token = await getToken({ auth0 });
                  await purchaseDayPass({
                    token,
                    useFree: false,
                  });
                  setPurchasingState("success");
                  token = await getToken({ auth0, ignoreCache: true });
                  dispatch(fetchUser({ token }));
                  dispatch(setCurrentlyVisible(undefined));
                } catch (err) {
                  console.error(err);
                  if (err.response) {
                    try {
                      const { message } = JSON.parse(err.response.data);
                      setErrorMessage(message);
                    } catch (err) {
                      console.error(err);
                    }
                  }
                  setPurchasingState("error");
                }
              }}
            >
              Confirm
            </Button>
          </div>
          {purchasingState === "error" && (
            <Paragraph style={{ color: "red", textAlign: "center" }}>
              {errorMessage
                ? errorMessage
                : "There was an issue purchasing your day pass. Try again or contact us."}
            </Paragraph>
          )}
        </div>
      );
    }
  }

  return (
    <div>
      <Title level={3}>Checkout</Title>
      {body}
    </div>
  );
};

export default DailyPassCheckout;
