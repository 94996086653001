import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const Contact = () => {
  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <Title level={3}>Need Our Help?</Title>
      <Paragraph>
        If you need to contact us for any reason email us at{" "}
        <a href="mailto: support@bookiebeats.com">support@bookiebeats.com</a>
      </Paragraph>
    </div>
  );
};

export default Contact;
