import React, { Suspense, useEffect } from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import OneSignal from "react-onesignal";
import axios from "axios";
import { setInitializationState } from "../state/ducks/oneSignal";
import {
  HomePage,
  ErrorPage,
  Arbitrage,
  Account,
  AccountPushNotificationSettings,
  AccountAlertChannels,
  Articles,
  FAQ,
  Discord,
  Tips,
  FairValueEVCalculator,
  UpgradeToPremium,
  CaesarsBaseballUnders,
  BetFinder,
  LiveAlerts,
} from "./pages";
import MemberCap from "./pages/Announcements/MemberCap";
import Modals from "./modals";
import withSubscriptionRequired from "./components/HigherOrderComponents/withSubscriptionRequired";
const AdminHome = React.lazy(() =>
  import("./admin/pages/AdminHome/AdminHome.page").catch((e) =>
    window.location.reload()
  )
);

const UnabatedConfig = React.lazy(() =>
  import("./partner/pages/UnabatedConfig/UnabatedConfig.page").catch((e) =>
    window.location.reload()
  )
);

function Private() {
  const { isLoading, error, user: auth0User } = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    async function initOneSignal() {
      const isLocal = process.env.REACT_APP_ENVIRONMNET === "local";
      try {
        dispatch(setInitializationState("in progress"));
        await axios.get("https://cdn.onesignal.com/sdks/OneSignalSDKWorker.js");
        await OneSignal.init({
          appId: isLocal
            ? "148329d3-4f80-4130-954d-2577beca6216"
            : "737191a9-6f4f-49c8-8dff-14a9be91f690",
          allowLocalhostAsSecureOrigin: isLocal,
        });
        const isSubscribed = await OneSignal.getSubscription();
        if (isSubscribed) await OneSignal.setExternalUserId(auth0User.sub);
        dispatch(setInitializationState("success"));
      } catch (err) {
        console.error(err);
        dispatch(setInitializationState("failure"));
      }
    }
    initOneSignal();
  }, [dispatch, auth0User.sub]);

  if (location.pathname !== window.location.pathname) {
    history.push(window.location.pathname);
  }

  if (isLoading) {
    return null;
  }

  if (error) {
    return <ErrorPage showLogo />;
  }

  return (
    <>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        {!user.isTrialAccount && (
          <Route path="/learn">
            <Articles />
          </Route>
        )}
        {user.isTrialAccount && (
          <Route path="/learn">Trial Account - No Access</Route>
        )}
        <Route exact path="/account">
          <Account />
        </Route>
        <Route exact path="/account/push-notification-settings">
          <AccountPushNotificationSettings />
        </Route>
        <Route exact path="/account/alert-channels">
          <AccountAlertChannels />
        </Route>
        <Route path="/tools/arbitrage">
          <Redirect to="/tools/pregame-alerts" />
        </Route>
        <Route path="/tools/pregame-alerts">
          <Arbitrage />
        </Route>
        <Route path="/tools/low-hold">
          <Redirect to="/tools/pregame" />
        </Route>
        <Route path="/frequently-asked-questions">
          <FAQ />
        </Route>
        {!user.isTrialAccount && (
          <Route path="/banter">
            <Discord />
          </Route>
        )}
        {user.isTrialAccount && (
          <Route path="/banter">Trial Account - No Access</Route>
        )}
        <Route path="/tips">
          <Tips />
        </Route>
        <Route path="/announcements/member-cap">
          <MemberCap />
        </Route>
        <Route path="/upgrade-to-premium">
          <UpgradeToPremium />
        </Route>
        <Route path="/caesars-unders-plays">
          <CaesarsBaseballUnders />
        </Route>
        <Route exact path="/calculators/Expected-Value">
          <FairValueEVCalculator />
        </Route>
        <Route path="/debug">
          <div>location.pathname: {location.pathname}</div>
          <div>window.location.pathname: {window.location.pathname}</div>
        </Route>
        <CompatRoute path="/live-alerts">
          <LiveAlerts />
        </CompatRoute>
        {(user.isAdmin || user.isPartner) && (
          <Route path="/admin">
            <Suspense fallback={<div>Loading...</div>}>
              <AdminHome />
            </Suspense>
          </Route>
        )}
        {(user.isAdmin || user.isPartner) && (
          <Route path="/unabated-config">
            <Suspense fallback={<div>Loading...</div>}>
              <UnabatedConfig />
            </Suspense>
          </Route>
        )}
        <Route path="/tools/pregame">
          <BetFinder live={false} key="pregame" />
        </Route>
        {(user.isPartner ||
          ["PREMIUM", "PREMIUM_DAY", "PREMIUM_TRIAL"].includes(
            user.subscriptionLevel
          )) && (
          <Route path="/tools/live">
            <BetFinder live={true} key="live" />
          </Route>
        )}
      </Switch>
      <Modals />
    </>
  );
}

export default withAuthenticationRequired(withSubscriptionRequired(Private));
