import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Switch, Route, Link } from "react-router-dom";
import { useTheme } from "../../../hooks";
import styles from "./Articles.module.less";

import { Button, Spin } from "antd";
import sanityClient from "./../../../services/sanity";
import SingleArticle from "./SingleArticle.page.js";

function isPostOfSelectedCategory(post, filters) {
  if (filters.length === 0) {
    return post;
  } else {
    if (post.categories) {
      for (let i = 0; i < post.categories.length; i++) {
        if (filters.includes(post.categories[i].slug.current)) {
          return post;
        }
      }
    }
  }
}

function Blog() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchCategoriesRaw = searchParams.get("categories");
  let searchCategories = [];
  if (searchCategoriesRaw) searchCategories = searchCategoriesRaw.split(",");

  // declare state variables
  const [activeFilter, setActiveFilter] = useState(searchCategories);
  const [allPostsData, setAllPosts] = useState(null);
  const [categories, setCategories] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    async function fetchCategories() {
      const responseCategories = await sanityClient.fetch(
        `*[_type == "category"] | order(order asc){title, slug, color, shortName}`
      );
      setCategories(responseCategories);
    }

    async function fetchPosts() {
      const responsePosts = await sanityClient.fetch(
        `*[_type == "post"] | 
        order(publishedAt desc){title, slug, description, mainImage{asset->{_id,url},},categories[] -> {title, slug, color, shortName},}`
      );
      setAllPosts(responsePosts);
    }
    fetchCategories();
    fetchPosts();
  }, []);

  let categoriesForFilter = [];
  if (!allPostsData)
    return (
      <div style={{ width: "100%", textAlign: "center", paddingTop: "5rem" }}>
        <Spin />
      </div>
    );
  if (categories) {
    categories.map((category) => categoriesForFilter.push(category));
  }

  return (
    <>
      <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
        <div style={{ paddingBottom: "1rem" }}>
          {location.pathname === "/learn/" || location.pathname === "/learn" ? (
            <>
              <div>
                {categoriesForFilter.map((c) => {
                  const slug = c.slug.current;
                  const isSelected =
                    activeFilter.length === 0 || activeFilter.includes(slug);
                  return (
                    <Button
                      key={slug}
                      type="primary"
                      shape="round"
                      size="small"
                      style={{
                        margin: "0.25rem",
                        borderColor: c.color,
                        background: isSelected ? c.color : undefined,
                        color: isSelected ? "white" : c.color,
                        textShadow: "unset",
                      }}
                      ghost={!isSelected}
                      onClick={() => {
                        if (activeFilter.includes(slug)) {
                          setActiveFilter((prevActiveFilter) => {
                            const clone = prevActiveFilter.slice();
                            const index = clone.indexOf(slug);
                            clone.splice(index, 1);
                            return clone;
                          });
                        } else {
                          setActiveFilter((prevActiveFilter) => {
                            const clone = prevActiveFilter.slice();
                            clone.push(slug);
                            return clone;
                          });
                        }
                      }}
                    >
                      {c.shortName}
                    </Button>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>

        <Switch>
          <Route exact path="/learn">
            {
              <div>
                <div>
                  {allPostsData &&
                    allPostsData
                      .filter((post) =>
                        isPostOfSelectedCategory(post, activeFilter)
                      )
                      .map((post) => {
                        if (post.hide) return null;

                        return (
                          <Link
                            to={"/learn/" + post.slug.current}
                            key={post.slug.current}
                            className={styles.articleLink}
                            style={{ color: theme.colorText }}
                          >
                            <div
                              className={styles.contentContainer}
                              key={post.title}
                            >
                              <div className={styles.title}>{post.title}</div>
                              <div className={styles.description}>
                                {post.description}
                              </div>
                              <div className={styles.description}>
                                {post.categories.map((c, i) => {
                                  return (
                                    <span
                                      key={c.slug.current}
                                      style={{
                                        color: c.color,
                                        padding:
                                          i > 0 ? "0 0.25rem" : "0 0.25rem 0 0",
                                      }}
                                    >
                                      {c.shortName}
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                </div>
              </div>
            }
          </Route>
          {allPostsData &&
            allPostsData.map((post) => {
              return (
                <Route exact path={`/learn/:slug`} key={post.slug.current}>
                  <SingleArticle />
                </Route>
              );
            })}
        </Switch>
      </div>
    </>
  );
}

export default Blog;
