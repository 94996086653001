import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../config";

const API_BASE_DOMAIN = config.apiBaseDomain;

const initialState = {
  data: {},
  status: "idle",
  error: null,
};

export const selectAllMarketTypes = (state) => state.marketTypes.data;

export const fetchMarketTypes = createAsyncThunk(
  "marketTypes/fetchMarketTypes",
  async () => {
    const response = await axios.get(`${API_BASE_DOMAIN}/market-types`);
    return response.data;
  }
);

export const marketTypesSlice = createSlice({
  name: "marketTypes",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMarketTypes.pending]: (state) => {
      state.status = "loading";
    },
    [fetchMarketTypes.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchMarketTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export default marketTypesSlice.reducer;
