import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { ntfyTestNotification } from "../../../../api";
import getToken from "../../../../utils/getToken";

const { Title, Text } = Typography;

const ChangeSettingsButton = () => {
  return (
    <span style={{ display: "inline-block", marginRight: "1rem" }}>
      <Link to="/account/push-notification-settings">
        <Button type="primary">Change Notification Filters</Button>
      </Link>
    </span>
  );
};

const PushNotifications = () => {
  const auth0 = useAuth0();

  return (
    <>
      <ChangeSettingsButton />
      <div style={{ margin: "0.5rem" }}>
        <Title level={5} type="secondary">
          Set Up
        </Title>
        <div>
          <Text type="secondary">
            1.{" "}
            <a
              href="https://docs.ntfy.sh/subscribe/phone/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download the ntfy app
            </a>
          </Text>
        </div>
        <div>
          <Text type="secondary">
            2. Add a topic subscription with the following settings
          </Text>
          <Text type="secondary">
            <ul style={{ marginBottom: 0 }}>
              <li>
                Topic name: <Text copyable>{auth0.user.sub.split("|")[1]}</Text>
              </li>
              <li>
                User another server with service URL:{" "}
                <Text copyable>https://ntfy.bookiebeats.com</Text>
              </li>
            </ul>
          </Text>
        </div>
        <div>
          <Text type="secondary">3. Send a test notification</Text>
        </div>
        <div style={{ padding: "0.5rem 0 0 1rem" }}>
          <Button
            size="small"
            onClick={async () => {
              const token = await getToken({ auth0 });
              console.log(token);
              await ntfyTestNotification({ token });
            }}
          >
            Test Notification
          </Button>
        </div>
      </div>
    </>
  );
};

export default PushNotifications;
