import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./utils/polifill";
import store from "./state/store";
import { Provider } from "react-redux";
import App from "./view/App";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import ScrollToTop from "./view/components/ScrollToTop/ScrollToTop";

if (
  !process.env.REACT_APP_ENVIRONMNET ||
  process.env.REACT_APP_ENVIRONMNET !== "local"
) {
  Sentry.init({
    dsn: "https://1bd6e426b08f41d88bdd11f09a84dada@o4504248737464320.ingest.sentry.io/4504248738709504",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

render(
  <Router>
    <CompatRouter>
      <Auth0ProviderWithHistory>
        <Provider store={store}>
          <ScrollToTop />
          <App />
        </Provider>
      </Auth0ProviderWithHistory>
    </CompatRouter>
  </Router>,
  document.getElementById("root")
);
