import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Button } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Icon from "@ant-design/icons";
import { Moon, Sun } from "./DarkToggleIcons";
import { setIsDark } from "../../../state/ducks/theme";
import styles from "./Header.module.less";
import logo from "../../../assets/logo_text.svg";
import logoDark from "../../../assets/logo_text_dark.svg";
import logoAfd from "../../../assets/logo_text_afd.svg";
import logoAfdDark from "../../../assets/logo_text_afd_dark.svg";
import { useTheme } from "../../../hooks";
import Menu from "../Menu/Menu.component";
const AntHeader = Layout.Header;

const DarkModeToggle = ({ className, style, onClick, type }) => {
  const isDarkTheme = useSelector((state) => state.theme.isDark);

  const iconCompontent = isDarkTheme ? Moon : Sun;

  return (
    <div className={className} style={style}>
      <Button
        type={type}
        shape="circle"
        icon={<Icon component={iconCompontent} height={20} width={20} />}
        onClick={onClick}
      />
    </div>
  );
};

const Header = ({ sports, onMenuOpen }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const themeTokens = useTheme();
  const isDarkTheme = useSelector((state) => state.theme.isDark);
  const isAfd = useSelector((state) => state.theme.isAfd);
  const auth0 = useAuth0();
  const isSubscribed = user && user.subscriptions.length > 0;

  let logoImage;
  if (isDarkTheme) logoImage = isAfd ? logoAfdDark : logoDark;
  else logoImage = isAfd ? logoAfd : logo;

  return (
    <>
      <AntHeader
        className={styles.header}
        style={{ background: themeTokens.colorBgContainer }}
      >
        <Link to="/">
          <div className={styles.logo}>
            <img src={logoImage} alt="logo" height="40" />
          </div>
        </Link>
        {isSubscribed ? (
          <div style={{ display: "flex" }}>
            <Menu className={styles.menu} sports={sports} />
            <div className={styles.rightContainer}>
              <DarkModeToggle
                type={!isDarkTheme ? "primary" : "default"}
                className={styles.darkIconButton}
                onClick={() => {
                  dispatch(setIsDark(!isDarkTheme));
                }}
              />
              <div
                className={styles.mobileMenuButton}
                role="button"
                onClick={onMenuOpen}
              >
                <MenuFoldOutlined
                  style={{
                    fontSize: "28px",
                    color: themeTokens.colorText,
                    lineHeight: "72px",
                  }}
                />
              </div>
              {user && user.subscriptionLevel === "BASIC" && (
                <div className={styles.tipsButton}>
                  <Link to="/upgrade-to-premium">
                    <Button type="ghost" className={styles.upgradeButton}>
                      Premium
                    </Button>
                  </Link>
                </div>
              )}
              {user && (
                <div className={styles.tipsButton} style={{ top: 0 }}>
                  <Link to="/tips">
                    <Button type="primary">Tips</Button>
                  </Link>
                </div>
              )}
              <div className={styles.accountButton} style={{ top: 0 }}>
                <Link to="/account">
                  <Button>Account</Button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.loginButton}>
            {!auth0.isLoading && !auth0.isAuthenticated ? (
              <Button onClick={auth0.loginWithRedirect}>Login</Button>
            ) : null}
          </div>
        )}
      </AntHeader>
    </>
  );
};

export default Header;
