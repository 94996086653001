import React from "react";
import { Typography } from "antd";
import arbitrageMarketExample from "./arbitrageMarketExample.png";

const { Paragraph, Title } = Typography;
const ArbitrageHelp = () => {
  return (
    <div>
      <Title level={3}>Pregame Alerts Help</Title>
      <Paragraph>
        This page will show a mirror of alerts from the Discord (and maybe more)
        filtered to the books you have set on the account page.
      </Paragraph>
      <Title level={4}>Inputs</Title>
      <Title level={5}>Dog Stake</Title>
      <Paragraph>
        This is the dollar amount that you will bet on the underdog that gets
        used in the calculations of your hedge bet and profit amount. We have
        set the default to $100 but you can change it depending on your bet
        size. <b>Best practice:</b> Bet between $100-$200 on the underdog to fly
        under the radar (especially on alt markets and player props).
      </Paragraph>
      <Title level={4}>Markets</Title>
      <Paragraph>Each arbitrage market will look something like this</Paragraph>
      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        <img
          src={arbitrageMarketExample}
          alt="Arbitrage Market Example"
          width="100%"
        />
      </div>
      <Title level={5}>Title</Title>
      <Paragraph>
        On the top you will see the name of the market and game information if
        the market is a total. Sometimes there will be a "!" on the right side
        of the title. This indicates there is something we think you should know
        about the market, click on it to learn more.
      </Paragraph>
      <Title level={5}>Profit</Title>
      <Paragraph>
        On the left side you will see two amounts. The larger number is the
        profit you will gain from the arbitrage given the underdog stake entered
        at the top. The smaller number is the arbitrage percent, which
        represents profit divided by total amount wagered (you don't need to
        worry about this usually).
      </Paragraph>
      <Title level={5}>Outcomes</Title>
      <Paragraph>
        There are two outcomes for any given arbitrage market (yes, it is
        possible to have arbitrage on a market with any number of outcomes but
        those are rare and difficult to find so we stick to 2). If it is a total
        market you will see "Over" and "Under", and if not you will see the name
        of the participant. Beneath the name you will see dollar amounts. In the
        example above Over has $100.00 and Under has $126.23, these are the
        amounts you should bet on that given outcome given the underdog stake
        entered.
      </Paragraph>
      <Paragraph>
        To the right of the outcome names you will see a box with the logo of
        the book that the outcome is being offered on. In the box with the logo
        there will either be one or two numbers. On a moneyline market there
        will be only one number, the American style odds of the outcome. For
        spread and total bets there will be a smaller number. For total markets
        it will be the total, for spread markets it will be the spread for that
        outcome. Sometimes you will see an asterisk next to this number, this
        indicates that it is an alternate line/total being offered on that book.
        You will get used to where the books offer these lines as you become
        more familiar with them.
      </Paragraph>
      <Paragraph>
        <i>
          Note we assume the lines for DK/BR/BS are the same, which is true in
          99% of situations, but exceptions are possible!
        </i>
      </Paragraph>
      <Title level={5}>Middles</Title>
      <Paragraph>
        Sometimes you will see a gold line under the title of the market. This
        indicates that the market is a risk free middle. This means that you can
        bet both sides of the market for no cost and it is possible that both of
        your bets win (or one wins and one pushes). For example, if DK was
        offering LaMelo Ball O5.5 rebounds at -110 and FD has U6.5 rebounds at
        +110, if he gets 6 roubounds both bets win. These are rare, and it is
        even more rare that they hit, but they are a lot of fun to root for.
      </Paragraph>
      <Title level={4}>Best Practices</Title>
      <Paragraph>
        Add both sides to your bet slip first and double check the odds and
        totals before placing the bet.
      </Paragraph>
      <Paragraph>
        We recommend placing the smaller bet (on the underdog) first. As you bet
        more, you will notice if a certain book starts to limit you, in which
        case you will want to place there first.
      </Paragraph>
      <Paragraph>
        If the odds move on you in the middle of placing a bet, use the{" "}
        <a
          href="https://www.sportsbookreview.com/betting-calculators/arbitrage-calculator/"
          target="_blank"
          rel="noopener noreferrer"
        >
          arb calculator
        </a>{" "}
        to determine the appropriate amounts to bet.
      </Paragraph>
    </div>
  );
};

export default ArbitrageHelp;
