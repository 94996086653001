import React, { useEffect, useState } from "react";

import sanityClient from "./../../../services/sanity";
import { PortableText } from "@portabletext/react";
import serializers from "../Articles/components/serializers.component";

import { Row, Col, Spin } from "antd";

// function called when rendering the article body via PortableText
// add on new types as needed such as tables, collapsable, etc.

function FAQ() {
  const [faqPage, setFAQPage] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "page"]{
            title,
            slug,
            body[]{
                ...,
                markDefs[]{
                  ...,
                  _type == "internalLink" => {
                    "slug": @.reference->slug
                  }
                }
              },
          
        }`
      )

      .then((data) => setFAQPage(data))
      .catch(console.error);
  }, []);
  if (!faqPage) return <Spin />;

  return (
    <div style={{ padding: "1rem 0" }}>
      <Row justify="center">
        <Col xl={{ span: 18 }} l={{ span: 18 }} sm={{ span: 20 }} flex="auto">
          <PortableText value={faqPage[0].body} components={serializers} />
        </Col>
      </Row>
    </div>
  );
}

export default FAQ;
