import React, { useState } from "react";
import { Button, InputNumber, Form, Typography } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { createTipsCheckoutSession } from "../../../api";
import config from "../../../config";
import getToken from "../../../utils/getToken";

const { Paragraph } = Typography;

const stripe = window.Stripe(config.stripeKey);

function validateInteger(number) {
  if (Number.isInteger(number)) {
    return {
      validateStatus: "success",
      errorMsg: null,
    };
  }
  return {
    validateStatus: "error",
    errorMsg: "Amount must be an integer",
  };
}

const Tips = () => {
  const auth0 = useAuth0();
  const [amount, setAmount] = useState({
    value: undefined,
  });
  const [isTipCheckoutSessionLoading, setIsTipCheckoutSessionLoading] =
    useState(false);

  const oneTimeCheckoutClick = async ({ amount }) => {
    setIsTipCheckoutSessionLoading(true);
    try {
      const token = await getToken({ auth0 });
      const checkoutSession = await createTipsCheckoutSession({
        token,
        amount,
        mode: "payment",
      });
      stripe.redirectToCheckout({ sessionId: checkoutSession.id });
    } catch (err) {
      console.error(err);
      setIsTipCheckoutSessionLoading(false);
    }
  };

  const onAmountChange = (value) => {
    setAmount({
      ...validateInteger(value),
      value,
    });
  };

  return (
    <div style={{ padding: "1.5rem 0 1rem", textAlign: "center" }}>
      <Paragraph>
        Thank you for helping us keep Bookie Beats exclusive by tipping
      </Paragraph>
      <div style={{ maxWidth: 325, margin: "auto" }}>
        <div>
          <Paragraph>
            Give a one time tip by entering the amount below
          </Paragraph>
          <Form name="basic" onFinish={oneTimeCheckoutClick} autoComplete="off">
            <div style={{ textAlign: "center", margin: "1rem" }}>
              <Form.Item
                style={{ margin: "auto" }}
                name="amount"
                validateStatus={amount.validateStatus}
                help={amount.errorMsg}
              >
                <InputNumber
                  style={{ width: 150 }}
                  size="large"
                  step={1}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={onAmountChange}
                  value={amount.value}
                />
              </Form.Item>
            </div>

            <div style={{ textAlign: "center" }}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    amount.validateStatus === "error" ||
                    !amount.value ||
                    isTipCheckoutSessionLoading
                  }
                  loading={isTipCheckoutSessionLoading}
                  size="large"
                >
                  Checkout
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Tips;
