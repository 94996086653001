import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Typography } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addUserAlertChannel,
  removeUserAlertChannel,
} from "../../../state/ducks/user";
import getToken from "../../../utils/getToken";

const ARB_ALERT_CHANNELS = [
  { title: "Caesar's - DraftKings", id: "dk-czr" },
  { title: "Caesar's - FanDuel", id: "fd-czr" },
  { title: "Caesar's - Kambi", id: "kambi-czr" },
  { title: "Caesar's - PointsBet", id: "czr-pb" },
  { title: "DraftKings - FanDuel", id: "fd-dk" },
  { title: "DraftKings - Kambi", id: "dk-kambi" },
  { title: "DraftKings - PointsBet", id: "dk-pb" },
  { title: "FanDuel - Kambi", id: "fd-kambi" },
  { title: "FanDuel - PointsBet", id: "fd-pb" },
  { title: "Kambi - PointsBet", id: "kambi-pb" },
  { title: "Barstool - Caesar's", id: "bs-czr" },
  { title: "Barstool - DraftKings", id: "bs-dk" },
  { title: "Barstool - FanDuel", id: "bs-fd" },
  { title: "Barstool - Kambi", id: "bs-kambi" },
  { title: "Barstool - PointsBet", id: "bs-pb" },
];

const EV_ALERT_CHANNELS = [
  { title: "Caesar's", id: "ev-czr" },
  { title: "DraftKings", id: "ev-dk" },
  { title: "FanDuel", id: "ev-fd" },
  { title: "Kambi", id: "ev-kambi" },
  { title: "PointsBet", id: "ev-pb" },
  { title: "Barstool", id: "ev-bs" },
];

const LeftDivider = ({ text }) => {
  return <Typography.Title level={4}>{text}</Typography.Title>;
};

const LabeledSwitch = ({ label, checked, loading, onChange }) => {
  return (
    <div>
      <div style={{ display: "inline-block", width: 200, fontSize: "0.9rem" }}>
        {label}
      </div>
      <Switch loading={loading} checked={checked} onChange={onChange} />
    </div>
  );
};

const AccountAlertChannels = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const user = useSelector((state) => state.user.user);
  const [discordAlertChannels, setDiscordAlertChannels] = useState(
    user.discordAlertChannels
  );
  const [loadingId, setLoadingId] = useState();

  useEffect(() => {
    setLoadingId(undefined);
    setDiscordAlertChannels(user.discordAlertChannels);
  }, [user]);

  return (
    <div style={{ paddingTop: "0.5rem" }}>
      <LeftDivider text="Alert Channels" />
      <Typography.Title level={5}>Arbitrage</Typography.Title>
      {ARB_ALERT_CHANNELS.map((channel) => (
        <div key={channel.id} style={{ marginBottom: "0.5rem" }}>
          <LabeledSwitch
            label={<Typography.Text>{channel.title}</Typography.Text>}
            checked={discordAlertChannels.includes(channel.id)}
            loading={channel.id === loadingId}
            onChange={async (checked) => {
              setLoadingId(channel.id);
              const token = await getToken({ auth0 });
              if (checked) {
                dispatch(addUserAlertChannel({ token, channelId: channel.id }));
                setDiscordAlertChannels([...discordAlertChannels, channel.id]);
              } else {
                let newDiscordAlertChannels = discordAlertChannels.filter(
                  (item) => item !== channel.id
                );
                dispatch(
                  removeUserAlertChannel({ token, channelId: channel.id })
                );
                setDiscordAlertChannels(newDiscordAlertChannels);
              }
            }}
          />
        </div>
      ))}
      <Typography.Title level={5}>EV+</Typography.Title>
      {EV_ALERT_CHANNELS.map((channel) => (
        <div key={channel.id} style={{ marginBottom: "0.5rem" }}>
          <LabeledSwitch
            label={<Typography.Text>{channel.title}</Typography.Text>}
            checked={discordAlertChannels.includes(channel.id)}
            loading={channel.id === loadingId}
            onChange={async (checked) => {
              setLoadingId(channel.id);
              const token = await getToken({ auth0 });
              if (checked) {
                dispatch(addUserAlertChannel({ token, channelId: channel.id }));
                setDiscordAlertChannels([...discordAlertChannels, channel.id]);
              } else {
                let newDiscordAlertChannels = discordAlertChannels.filter(
                  (item) => item !== channel.id
                );
                dispatch(
                  removeUserAlertChannel({ token, channelId: channel.id })
                );
                setDiscordAlertChannels(newDiscordAlertChannels);
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default AccountAlertChannels;
