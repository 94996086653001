const config = {
  default: {
    domain: "bookiebeats.us.auth0.com",
    clientId: "SQt50DkH3eOKKWxC27NoG4O8S7L90dN3",
    apiBaseDomain:
      "https://irt6a72e7b.execute-api.us-east-1.amazonaws.com/prod",
    stripeKey:
      "pk_live_51IcvULBOlGO7J9ufKcHQXSbQbwERixWCGoQnyHcmYGjn3iMNC486BvHdOl7UuPWVvePm7yL7vySFFUMOdOfMoFy900Zy2xvBBe",
    showTipsAnnouncement: false,
    sports: {
      basketball: {
        display: "Basketball",
        leagues: [
          { value: "nba", display: "NBA" },
          { value: "ncaa_basketball", display: "NCAA" },
          { value: "olympic_mens_basketball", display: "Olympics" },
          { value: "wnba", display: "WNBA", liveOnly: true },
        ],
      },
      baseball: {
        display: "Baseball",
        leagues: [{ value: "mlb", display: "MLB" }],
      },
      football: {
        display: "Football",
        leagues: [
          { value: "nfl", display: "NFL" },
          { value: "ncaa_football", display: "NCAA" },
        ],
      },
      hockey: {
        display: "Hockey",
        leagues: [{ value: "nhl", display: "NHL" }],
      },
      soccer: {
        display: "Soccer",
        leagues: [
          { value: "fifa_world_cup", display: "WCup" },
          { value: "epl", display: "EPL" },
          { value: "mls", display: "MLS" },
          { value: "ucl", display: "UCL" },
        ],
      },
      tennis: {
        display: "Tennis",
        liveOnly: true,
        leagues: [{ value: "tennis_all", display: "All Tennis" }],
      },
    },
    states: [
      {
        name: "None",
        abbreviation: "",
      },
      {
        name: "Alabama",
        abbreviation: "AL",
      },
      {
        name: "Alaska",
        abbreviation: "AK",
      },
      {
        name: "Arizona",
        abbreviation: "AZ",
      },
      {
        name: "Arkansas",
        abbreviation: "AR",
      },
      {
        name: "California",
        abbreviation: "CA",
      },
      {
        name: "Canada",
        abbreviation: "CAN",
      },
      {
        name: "Colorado",
        abbreviation: "CO",
      },
      {
        name: "Connecticut",
        abbreviation: "CT",
      },
      {
        name: "Delaware",
        abbreviation: "DE",
      },
      {
        name: "District Of Columbia",
        abbreviation: "DC",
      },
      {
        name: "Florida",
        abbreviation: "FL",
      },
      {
        name: "Georgia",
        abbreviation: "GA",
      },
      {
        name: "Hawaii",
        abbreviation: "HI",
      },
      {
        name: "Idaho",
        abbreviation: "ID",
      },
      {
        name: "Illinois",
        abbreviation: "IL",
      },
      {
        name: "Indiana",
        abbreviation: "IN",
      },
      {
        name: "Iowa",
        abbreviation: "IA",
      },
      {
        name: "Kansas",
        abbreviation: "KS",
      },
      {
        name: "Kentucky",
        abbreviation: "KY",
      },
      {
        name: "Louisiana",
        abbreviation: "LA",
      },
      {
        name: "Maine",
        abbreviation: "ME",
      },
      {
        name: "Maryland",
        abbreviation: "MD",
      },
      {
        name: "Massachusetts",
        abbreviation: "MA",
      },
      {
        name: "Michigan",
        abbreviation: "MI",
      },
      {
        name: "Minnesota",
        abbreviation: "MN",
      },
      {
        name: "Mississippi",
        abbreviation: "MS",
      },
      {
        name: "Missouri",
        abbreviation: "MO",
      },
      {
        name: "Montana",
        abbreviation: "MT",
      },
      {
        name: "Nebraska",
        abbreviation: "NE",
      },
      {
        name: "Nevada",
        abbreviation: "NV",
      },
      {
        name: "New Hampshire",
        abbreviation: "NH",
      },
      {
        name: "New Jersey",
        abbreviation: "NJ",
      },
      {
        name: "New Mexico",
        abbreviation: "NM",
      },
      {
        name: "New York",
        abbreviation: "NY",
      },
      {
        name: "North Carolina",
        abbreviation: "NC",
      },
      {
        name: "North Dakota",
        abbreviation: "ND",
      },
      {
        name: "Ohio",
        abbreviation: "OH",
        czrDomain: "caesars",
      },
      {
        name: "Oklahoma",
        abbreviation: "OK",
      },
      {
        name: "Oregon",
        abbreviation: "OR",
      },
      {
        name: "Pennsylvania",
        abbreviation: "PA",
        czrDomain: "caesars",
      },
      {
        name: "Puerto Rico",
        abbreviation: "PR",
      },
      {
        name: "Rhode Island",
        abbreviation: "RI",
      },
      {
        name: "South Carolina",
        abbreviation: "SC",
      },
      {
        name: "South Dakota",
        abbreviation: "SD",
      },
      {
        name: "Tennessee",
        abbreviation: "TN",
      },
      {
        name: "Texas",
        abbreviation: "TX",
      },
      {
        name: "Utah",
        abbreviation: "UT",
      },
      {
        name: "Vermont",
        abbreviation: "VT",
      },
      {
        name: "Virginia",
        abbreviation: "VA",
      },
      {
        name: "Washington",
        abbreviation: "WA",
      },
      {
        name: "West Virginia",
        abbreviation: "WV",
      },
      {
        name: "Wisconsin",
        abbreviation: "WI",
      },
      {
        name: "Wyoming",
        abbreviation: "WY",
      },
    ],
  },
  prod: {
    domain: "bookiebeats.us.auth0.com",
    clientId: "SQt50DkH3eOKKWxC27NoG4O8S7L90dN3",
    apiBaseDomain:
      "https://irt6a72e7b.execute-api.us-east-1.amazonaws.com/prod",
    stripeKey:
      "pk_live_51IcvULBOlGO7J9ufKcHQXSbQbwERixWCGoQnyHcmYGjn3iMNC486BvHdOl7UuPWVvePm7yL7vySFFUMOdOfMoFy900Zy2xvBBe",
  },
};

export default {
  ...config.default,
  ...config[process.env.REACT_APP_ENVIRONMNET],
};
